//
// Labels
// --------------------------------------------------

.label {
  padding: .2em .4em .2em;
  color: $label-color;
  border-radius: $border-radius-base;
  &.label-default {
    color: $label-default-color;
    border: 1px solid $label-default-border;
  }

  a & {
    &:hover,
    &:focus {
      color: $label-link-hover-color;
    }
  }
}


// ========== Media Queries ==========

//== print
@media print {

  .label {
    &-default,
    &-primary,
    &-success,
    &-info,
    &-warning,
    &-danger {
      border-color: $print-border-color !important;
    }
  }

}