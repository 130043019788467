//
// Tooltips
// --------------------------------------------------

// Wrapper for the tooltip content
.tooltip-inner {
  font-size: $font-size-base;
  padding: $size-xs-y $size-xs-x;
}

@include set-tooltip-color(tooltip, '', $tooltip-default); // default
@include set-tooltip-color(tooltip, default, $tooltip-default);
@include set-tooltip-color(tooltip, info, $tooltip-info);
@include set-tooltip-color(tooltip, success, $tooltip-success);
@include set-tooltip-color(tooltip, warning, $tooltip-warning);
@include set-tooltip-color(tooltip, danger, $tooltip-danger);


// ========== Media Queries ==========

//== print
@media print {

  .tooltip {
    &,
    &-default,
    &-primary,
    &-success,
    &-info,
    &-warning,
    &-danger {
      .tooltip-inner { border: 1px solid $print-border-color !important; }
    }
    &, &.top {
      .tooltip-arrow { border-top-color: $print-border-color !important; }
    }
    &.bottom {
      .tooltip-arrow { border-bottom-color: $print-border-color !important; }
    }
    &.left {
      .tooltip-arrow { border-left-color: $print-border-color !important; }
    }
    &.right {
      .tooltip-arrow { border-right-color: $print-border-color !important; }
    }
  }

}