//
// Breadcrumbs & nav-page
// --------------------------------------------------

.nav-page {
  a { color: $link-modest-color; }
}

ul.breadcrumb {
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: $breadcrumb-bg;
  > li {
    margin-bottom: 0;
    @include clear-pseudo-content;
    + li:before {
      position: relative;
      left: 0;
      line-height:  $line-height-computed;      
      padding: 0;
      margin: 0 $breadcrumb-padding-horizontal;
      @include genicon-styles;
      content: genicon-char(forward);
      @include icon-size(12);
      color: $breadcrumb-color;
    }
    a { color: $breadcrumb-color }
  }
}

.link-back {
  font-size: $font-size-middle;
  @include underline-not;
  &:before {
    @include genicon-styles;
    content: genicon-char(backward);
    @include icon-size(12);
    margin-right: $breadcrumb-padding-horizontal;
  }
  .title { @include underline; }
}