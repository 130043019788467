//
// Buttons
// --------------------------------------------------


//== Base styles

.btn {
  vertical-align: middle;
  @include button-size($size-xs-y, $size-xs-x, $font-size-middle, $line-height-base, $btn-border-radius-base, $btn-border-normal, $font-size-middle, $input-height-base);
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    @include box-shadow(none);
  }
  .genicon {
    display: inline-block;
    height: 100%;
    position: relative;
    top: -(floor($size-tn-y / 2));
    vertical-align: middle;
    margin-right: $size-xs-x;
  }
}

//== Alternate buttons

.btn,
.btn-default {
  @include button-variant($btn-default-filled, $btn-default-color, $btn-default-bg, $btn-default-active-color, $btn-default-active-bg, $btn-default-disabled-color, $btn-default-disabled-bg);
}
.btn-primary {
  @include button-variant($btn-primary-filled, $btn-primary-color, $btn-primary-bg, $btn-primary-active-color, $btn-primary-active-bg, $btn-primary-disabled-color, $btn-primary-disabled-bg);
}
.btn-info {    
  @include button-variant($btn-info-filled, $btn-info-color, $btn-info-bg, $btn-info-active-color, $btn-info-active-bg, $btn-info-disabled-color, $btn-info-disabled-bg);
  &.btn-lg { border-width: $btn-border-normal; }  
}
.btn-success {
  @include button-variant($btn-success-filled, $btn-success-color, $btn-success-bg, $btn-success-active-color, $btn-success-bg, $btn-success-disabled-color, $btn-success-disabled-bg);
}
.btn-warning {
  @include button-variant($btn-warning-filled, $btn-warning-color, $btn-warning-bg, $btn-warning-active-color, $btn-warning-active-bg, $btn-warning-disabled-color, $btn-warning-disabled-bg);
}
.btn-danger {
  @include button-variant($btn-danger-filled, $btn-danger-color, $btn-danger-bg, $btn-danger-active-color, $btn-danger-active-bg, $btn-danger-disabled-color, $btn-danger-disabled-bg);
}
.btn-modest {
  @include button-variant($btn-modest-filled, $btn-modest-color, $btn-modest-bg, $btn-modest-active-color, $btn-modest-active-bg, $btn-modest-disabled-color, $btn-modest-disabled-bg);
}
.btn-white {
  @include button-variant($btn-white-filled, $btn-white-color, $btn-white-bg, $btn-white-active-color, $btn-white-active-bg, $btn-white-disabled-color, $btn-white-disabled-bg);
}
.btn-link {
  @include button-variant($btn-link-filled, $btn-link-color, $btn-link-bg, $btn-link-active-color, $btn-link-active-bg, $btn-link-disabled-color, $btn-link-disabled-bg);
  &.btn-lg { border-width: $btn-border-normal; }
}
.btn-secondary {
  @include button-variant($btn-secondary-filled, $btn-secondary-color, $btn-secondary-bg, $btn-secondary-active-color, $btn-secondary-active-bg, $btn-secondary-disabled-color, $btn-secondary-disabled-bg);
}


//== Button Sizes

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($size-sm-y, $size-sm-x, $font-size-large, $line-height-large, $btn-border-radius-large, $btn-border-large, $font-size-huge, $input-height-large);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($size-tn-y, $size-tn-x, $font-size-base, $line-height-small, $btn-border-radius-small, $btn-border-small, $font-size-base, $input-height-small);
}
.xs-btn-nm {
  @media screen and (max-width: ($screen-tablet - 1)) {
    @include button-size($size-xs-y, $size-xs-x, $font-size-middle, $line-height-base, $btn-border-radius-base, $btn-border-normal, $font-size-middle, $input-height-base);
  }
}

//== Link buttons

.btn {
  &, &:hover { text-decoration: $link-hover-decoration; }
  &.noicon {
    &:after { display: none !important; }
    & { padding-right: $size-xs-x; }
    &.btn-sm { padding-right: $size-tn-x; }
    &.btn-lg { padding-right: $size-sm-x; }
  }
}
.btn-link {
  border-color: transparent;
  text-decoration: $link-decoration;
  @include clear-pseudo-content(after);
  color: $btn-link-color;
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    border-color: transparent;  
    text-decoration: $link-hover-decoration;
    color: $btn-link-active-color;
  }
  &[disabled],
  fieldset[disabled] & {
    border-color: transparent;  
    color: $btn-link-disabled-color !important;
    @include opacity(1);
    &:hover,
    &:focus {
      border-color: transparent;  
      text-decoration: $link-decoration;
    }
  }
}

//== More lines buttons

.btn-lines {
  text-align: center;
  position: relative;
  line-height: $line-height-tiny;
  font-weight: normal;  
  &:after { position: absolute; }
}

//== Button block

.btn-block {
  position: relative;
  padding-right: $size-nm-x;
  &.btn-sm { padding-right: $size-sm-x; }
  &.btn-noarrow { padding-right: 10px; }
  &:after {
    position: absolute;
    top: $size-xs-y;
    right: floor($size-tn-x / 2);
  }
}
.sidebar .btn-block {
  text-align: left;
}

//== Button group

.btn-group {
  .btn { border-right-width: 0; }
  .btn:last-child {
    border-right-width: $btn-border-normal;
    &-lg { border-right-width: $btn-border-large; }
    &-sm { border-right-width: $btn-border-small; }
  }
  &-narrow {
    .btn {
      &.btn-sm {
        font-weight: normal;
        &, &:last-child { border-width: 1px; }
      }
    }
  }
}

//== Buttons

.buttons {
  margin: $size-lg-y auto $size-md-y auto;
  text-align: center;
  @media screen and (max-width: $screen-xs-max) {
    margin-top: $size-md-y;
    margin-bottom: $size-md-y;
  }
}

// ========== Media Queries ==========

//== print
@media print {

  .btn,
  .btn-default,
  .btn-info,
  .btn-success,
  .btn-warning,
  .btn-modest,
  .btn-white,
  .btn-secondary {
    &,
    &:focus,
    &.focus,
    &:hover,
    &:active,
    &.active,
    &.disabled,
    &[disabled] {
      border-color: $print-border-color !important;
      background: $print-bg-color !important;
      color: $print-border-color !important;
    }
  }

  .btn-primary {
    &,
    &:focus,
    &.focus,
    &:hover,
    &:active,
    &.active,
    &.disabled,
    &[disabled] {
      border-color: $print-border-color !important;
      background: $print-border-color !important;
      color: $print-bg-color !important;
    }
  }

  .btn-link {
    &,
    &:focus,
    &.focus,
    &:hover,
    &:active,
    &.active,
    &.disabled,
    &[disabled] {
      border-color: $print-bg-color !important;
      background: $print-bg-color !important;
      color: $print-border-color !important;
    }
  }

}