@font-face {
  font-family: "icons";
  src: url('../fonts/icons/icons.eot?rev=1600159742');
  src: url('../fonts/icons/icons.eot?rev=1600159742#iefix') format('eot'),
  url('../fonts/icons/icons.woff?rev=1600159742') format('woff'),
  url('../fonts/icons/icons.ttf?rev=1600159742') format('truetype'),
  url('../fonts/icons/icons.svg?rev=1600159742#icons') format('svg');
}

@mixin genicon-styles {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%genicon {
  @include genicon-styles;
}

@function genicon-char($filename) {
  $char: "";

  @if $filename == adviser {
  $char: "\E001";
}
  @if $filename == angle-bracket-down {
  $char: "\E002";
}
  @if $filename == angle-bracket-left {
  $char: "\E003";
}
  @if $filename == angle-bracket-right {
  $char: "\E004";
}
  @if $filename == angle-bracket-up {
  $char: "\E005";
}
  @if $filename == app-android {
  $char: "\E006";
}
  @if $filename == app-apple {
  $char: "\E007";
}
  @if $filename == arrow-down-circle-filled {
  $char: "\E008";
}
  @if $filename == arrow-down-circle {
  $char: "\E009";
}
  @if $filename == arrow-down {
  $char: "\E00A";
}
  @if $filename == arrow-left-circle-filled {
  $char: "\E00B";
}
  @if $filename == arrow-left-circle {
  $char: "\E00C";
}
  @if $filename == arrow-left {
  $char: "\E00D";
}
  @if $filename == arrow-right-circle-filled {
  $char: "\E00E";
}
  @if $filename == arrow-right-circle {
  $char: "\E00F";
}
  @if $filename == arrow-right {
  $char: "\E010";
}
  @if $filename == arrow-up-circle-filled {
  $char: "\E011";
}
  @if $filename == arrow-up-circle {
  $char: "\E012";
}
  @if $filename == arrow-up {
  $char: "\E013";
}
  @if $filename == backward {
  $char: "\E014";
}
  @if $filename == book {
  $char: "\E015";
}
  @if $filename == broadcast {
  $char: "\E016";
}
  @if $filename == bulp {
  $char: "\E017";
}
  @if $filename == calculate {
  $char: "\E018";
}
  @if $filename == calendar {
  $char: "\E019";
}
  @if $filename == cancel {
  $char: "\E01A";
}
  @if $filename == chat {
  $char: "\E01B";
}
  @if $filename == check {
  $char: "\E01C";
}
  @if $filename == checkbox-checked {
  $char: "\E01D";
}
  @if $filename == checkbox-invert-checked {
  $char: "\E01E";
}
  @if $filename == checkbox-rounded-checked {
  $char: "\E01F";
}
  @if $filename == checkbox-rounded-invert-checked {
  $char: "\E020";
}
  @if $filename == checkbox-rounded {
  $char: "\E021";
}
  @if $filename == checkbox {
  $char: "\E022";
}
  @if $filename == circle-solid {
  $char: "\E023";
}
  @if $filename == circle {
  $char: "\E024";
}
  @if $filename == clock {
  $char: "\E025";
}
  @if $filename == column {
  $char: "\E026";
}
  @if $filename == comments {
  $char: "\E027";
}
  @if $filename == contact {
  $char: "\E028";
}
  @if $filename == devices {
  $char: "\E029";
}
  @if $filename == document-comments {
  $char: "\E02A";
}
  @if $filename == document-info {
  $char: "\E02B";
}
  @if $filename == document-marketing {
  $char: "\E02C";
}
  @if $filename == document-rows {
  $char: "\E02D";
}
  @if $filename == document {
  $char: "\E02E";
}
  @if $filename == down {
  $char: "\E02F";
}
  @if $filename == download-table {
  $char: "\E030";
}
  @if $filename == download {
  $char: "\E031";
}
  @if $filename == downward {
  $char: "\E032";
}
  @if $filename == email {
  $char: "\E033";
}
  @if $filename == euro {
  $char: "\E034";
}
  @if $filename == faq {
  $char: "\E035";
}
  @if $filename == fb {
  $char: "\E036";
}
  @if $filename == file-doc {
  $char: "\E037";
}
  @if $filename == file-pdf {
  $char: "\E038";
}
  @if $filename == file-ppt {
  $char: "\E039";
}
  @if $filename == file-rtf {
  $char: "\E03A";
}
  @if $filename == file-xls {
  $char: "\E03B";
}
  @if $filename == file-zip {
  $char: "\E03C";
}
  @if $filename == file {
  $char: "\E03D";
}
  @if $filename == folder {
  $char: "\E03E";
}
  @if $filename == forward {
  $char: "\E03F";
}
  @if $filename == globe {
  $char: "\E040";
}
  @if $filename == globe2 {
  $char: "\E041";
}
  @if $filename == graph-bars {
  $char: "\E042";
}
  @if $filename == graph-curve {
  $char: "\E043";
}
  @if $filename == group {
  $char: "\E044";
}
  @if $filename == headphones {
  $char: "\E045";
}
  @if $filename == headquarters {
  $char: "\E046";
}
  @if $filename == help {
  $char: "\E047";
}
  @if $filename == idea {
  $char: "\E048";
}
  @if $filename == information {
  $char: "\E049";
}
  @if $filename == interject {
  $char: "\E04A";
}
  @if $filename == left {
  $char: "\E04B";
}
  @if $filename == list-bullet {
  $char: "\E04C";
}
  @if $filename == lock {
  $char: "\E04D";
}
  @if $filename == logout {
  $char: "\E04E";
}
  @if $filename == manager {
  $char: "\E04F";
}
  @if $filename == marker-shape {
  $char: "\E050";
}
  @if $filename == marker {
  $char: "\E051";
}
  @if $filename == maximize {
  $char: "\E052";
}
  @if $filename == menu {
  $char: "\E053";
}
  @if $filename == mobile {
  $char: "\E054";
}
  @if $filename == person {
  $char: "\E055";
}
  @if $filename == phone {
  $char: "\E056";
}
  @if $filename == play {
  $char: "\E057";
}
  @if $filename == plus-small {
  $char: "\E058";
}
  @if $filename == print {
  $char: "\E059";
}
  @if $filename == product-dfkd {
  $char: "\E05A";
}
  @if $filename == product-fcv {
  $char: "\E05B";
}
  @if $filename == product-fdb {
  $char: "\E05C";
}
  @if $filename == product-ffb {
  $char: "\E05D";
}
  @if $filename == product-ffd {
  $char: "\E05E";
}
  @if $filename == product-ffv {
  $char: "\E05F";
}
  @if $filename == product-fgz {
  $char: "\E060";
}
  @if $filename == product-fkb {
  $char: "\E061";
}
  @if $filename == product-fkd {
  $char: "\E062";
}
  @if $filename == product-fkm {
  $char: "\E063";
}
  @if $filename == product-fkn {
  $char: "\E064";
}
  @if $filename == product-fna {
  $char: "\E065";
}
  @if $filename == product-fne {
  $char: "\E066";
}
  @if $filename == product-fre {
  $char: "\E067";
}
  @if $filename == product-fsa {
  $char: "\E068";
}
  @if $filename == product-fsk {
  $char: "\E069";
}
  @if $filename == product-fvd {
  $char: "\E06A";
}
  @if $filename == product-fvea {
  $char: "\E06B";
}
  @if $filename == product-fved {
  $char: "\E06C";
}
  @if $filename == product-fzl {
  $char: "\E06D";
}
  @if $filename == product-fzp {
  $char: "\E06E";
}
  @if $filename == product-gfr {
  $char: "\E06F";
}
  @if $filename == product-iip {
  $char: "\E070";
}
  @if $filename == product-ikg {
  $char: "\E071";
}
  @if $filename == product-irg {
  $char: "\E072";
}
  @if $filename == product-mip {
  $char: "\E073";
}
  @if $filename == product-pdf {
  $char: "\E074";
}
  @if $filename == product-pip {
  $char: "\E075";
}
  @if $filename == product-pkf {
  $char: "\E076";
}
  @if $filename == product-pvf {
  $char: "\E077";
}
  @if $filename == product-tr3 {
  $char: "\E078";
}
  @if $filename == product-tr5 {
  $char: "\E079";
}
  @if $filename == product-tr8 {
  $char: "\E07A";
}
  @if $filename == radiobutton-checked {
  $char: "\E07B";
}
  @if $filename == radiobutton-invert-checked {
  $char: "\E07C";
}
  @if $filename == radiobutton {
  $char: "\E07D";
}
  @if $filename == remove {
  $char: "\E07E";
}
  @if $filename == right {
  $char: "\E07F";
}
  @if $filename == rise {
  $char: "\E080";
}
  @if $filename == rocket {
  $char: "\E081";
}
  @if $filename == rss {
  $char: "\E082";
}
  @if $filename == save {
  $char: "\E083";
}
  @if $filename == search {
  $char: "\E084";
}
  @if $filename == secure {
  $char: "\E085";
}
  @if $filename == smiley {
  $char: "\E086";
}
  @if $filename == star-filled {
  $char: "\E087";
}
  @if $filename == star {
  $char: "\E088";
}
  @if $filename == sync {
  $char: "\E089";
}
  @if $filename == transparency {
  $char: "\E08A";
}
  @if $filename == up {
  $char: "\E08B";
}
  @if $filename == upward {
  $char: "\E08C";
}
  @if $filename == user {
  $char: "\E08D";
}
  @if $filename == wallet {
  $char: "\E08E";
}
  @if $filename == youtube {
  $char: "\E08F";
}

@return $char;
}

@mixin genicon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
    @extend %genicon;
  } @else {
    @include genicon-styles;
  }
  content: genicon-char($filename);
}
}

.genicon-adviser {
  @include genicon(adviser);
}
.genicon-angle-bracket-down {
  @include genicon(angle-bracket-down);
}
.genicon-angle-bracket-left {
  @include genicon(angle-bracket-left);
}
.genicon-angle-bracket-right {
  @include genicon(angle-bracket-right);
}
.genicon-angle-bracket-up {
  @include genicon(angle-bracket-up);
}
.genicon-app-android {
  @include genicon(app-android);
}
.genicon-app-apple {
  @include genicon(app-apple);
}
.genicon-arrow-down-circle-filled {
  @include genicon(arrow-down-circle-filled);
}
.genicon-arrow-down-circle {
  @include genicon(arrow-down-circle);
}
.genicon-arrow-down {
  @include genicon(arrow-down);
}
.genicon-arrow-left-circle-filled {
  @include genicon(arrow-left-circle-filled);
}
.genicon-arrow-left-circle {
  @include genicon(arrow-left-circle);
}
.genicon-arrow-left {
  @include genicon(arrow-left);
}
.genicon-arrow-right-circle-filled {
  @include genicon(arrow-right-circle-filled);
}
.genicon-arrow-right-circle {
  @include genicon(arrow-right-circle);
}
.genicon-arrow-right {
  @include genicon(arrow-right);
}
.genicon-arrow-up-circle-filled {
  @include genicon(arrow-up-circle-filled);
}
.genicon-arrow-up-circle {
  @include genicon(arrow-up-circle);
}
.genicon-arrow-up {
  @include genicon(arrow-up);
}
.genicon-backward {
  @include genicon(backward);
}
.genicon-book {
  @include genicon(book);
}
.genicon-broadcast {
  @include genicon(broadcast);
}
.genicon-bulp {
  @include genicon(bulp);
}
.genicon-calculate {
  @include genicon(calculate);
}
.genicon-calendar {
  @include genicon(calendar);
}
.genicon-cancel {
  @include genicon(cancel);
}
.genicon-chat {
  @include genicon(chat);
}
.genicon-check {
  @include genicon(check);
}
.genicon-checkbox-checked {
  @include genicon(checkbox-checked);
}
.genicon-checkbox-invert-checked {
  @include genicon(checkbox-invert-checked);
}
.genicon-checkbox-rounded-checked {
  @include genicon(checkbox-rounded-checked);
}
.genicon-checkbox-rounded-invert-checked {
  @include genicon(checkbox-rounded-invert-checked);
}
.genicon-checkbox-rounded {
  @include genicon(checkbox-rounded);
}
.genicon-checkbox {
  @include genicon(checkbox);
}
.genicon-circle-solid {
  @include genicon(circle-solid);
}
.genicon-circle {
  @include genicon(circle);
}
.genicon-clock {
  @include genicon(clock);
}
.genicon-column {
  @include genicon(column);
}
.genicon-comments {
  @include genicon(comments);
}
.genicon-contact {
  @include genicon(contact);
}
.genicon-devices {
  @include genicon(devices);
}
.genicon-document-comments {
  @include genicon(document-comments);
}
.genicon-document-info {
  @include genicon(document-info);
}
.genicon-document-marketing {
  @include genicon(document-marketing);
}
.genicon-document-rows {
  @include genicon(document-rows);
}
.genicon-document {
  @include genicon(document);
}
.genicon-down {
  @include genicon(down);
}
.genicon-download-table {
  @include genicon(download-table);
}
.genicon-download {
  @include genicon(download);
}
.genicon-downward {
  @include genicon(downward);
}
.genicon-email {
  @include genicon(email);
}
.genicon-euro {
  @include genicon(euro);
}
.genicon-faq {
  @include genicon(faq);
}
.genicon-fb {
  @include genicon(fb);
}
.genicon-file-doc {
  @include genicon(file-doc);
}
.genicon-file-pdf {
  @include genicon(file-pdf);
}
.genicon-file-ppt {
  @include genicon(file-ppt);
}
.genicon-file-rtf {
  @include genicon(file-rtf);
}
.genicon-file-xls {
  @include genicon(file-xls);
}
.genicon-file-zip {
  @include genicon(file-zip);
}
.genicon-file {
  @include genicon(file);
}
.genicon-folder {
  @include genicon(folder);
}
.genicon-forward {
  @include genicon(forward);
}
.genicon-globe {
  @include genicon(globe);
}
.genicon-globe2 {
  @include genicon(globe2);
}
.genicon-graph-bars {
  @include genicon(graph-bars);
}
.genicon-graph-curve {
  @include genicon(graph-curve);
}
.genicon-group {
  @include genicon(group);
}
.genicon-headphones {
  @include genicon(headphones);
}
.genicon-headquarters {
  @include genicon(headquarters);
}
.genicon-help {
  @include genicon(help);
}
.genicon-idea {
  @include genicon(idea);
}
.genicon-information {
  @include genicon(information);
}
.genicon-interject {
  @include genicon(interject);
}
.genicon-left {
  @include genicon(left);
}
.genicon-list-bullet {
  @include genicon(list-bullet);
}
.genicon-lock {
  @include genicon(lock);
}
.genicon-logout {
  @include genicon(logout);
}
.genicon-manager {
  @include genicon(manager);
}
.genicon-marker-shape {
  @include genicon(marker-shape);
}
.genicon-marker {
  @include genicon(marker);
}
.genicon-maximize {
  @include genicon(maximize);
}
.genicon-menu {
  @include genicon(menu);
}
.genicon-mobile {
  @include genicon(mobile);
}
.genicon-person {
  @include genicon(person);
}
.genicon-phone {
  @include genicon(phone);
}
.genicon-play {
  @include genicon(play);
}
.genicon-plus-small {
  @include genicon(plus-small);
}
.genicon-print {
  @include genicon(print);
}
.genicon-product-dfkd {
  @include genicon(product-dfkd);
}
.genicon-product-fcv {
  @include genicon(product-fcv);
}
.genicon-product-fdb {
  @include genicon(product-fdb);
}
.genicon-product-ffb {
  @include genicon(product-ffb);
}
.genicon-product-ffd {
  @include genicon(product-ffd);
}
.genicon-product-ffv {
  @include genicon(product-ffv);
}
.genicon-product-fgz {
  @include genicon(product-fgz);
}
.genicon-product-fkb {
  @include genicon(product-fkb);
}
.genicon-product-fkd {
  @include genicon(product-fkd);
}
.genicon-product-fkm {
  @include genicon(product-fkm);
}
.genicon-product-fkn {
  @include genicon(product-fkn);
}
.genicon-product-fna {
  @include genicon(product-fna);
}
.genicon-product-fne {
  @include genicon(product-fne);
}
.genicon-product-fre {
  @include genicon(product-fre);
}
.genicon-product-fsa {
  @include genicon(product-fsa);
}
.genicon-product-fsk {
  @include genicon(product-fsk);
}
.genicon-product-fvd {
  @include genicon(product-fvd);
}
.genicon-product-fvea {
  @include genicon(product-fvea);
}
.genicon-product-fved {
  @include genicon(product-fved);
}
.genicon-product-fzl {
  @include genicon(product-fzl);
}
.genicon-product-fzp {
  @include genicon(product-fzp);
}
.genicon-product-gfr {
  @include genicon(product-gfr);
}
.genicon-product-iip {
  @include genicon(product-iip);
}
.genicon-product-ikg {
  @include genicon(product-ikg);
}
.genicon-product-irg {
  @include genicon(product-irg);
}
.genicon-product-mip {
  @include genicon(product-mip);
}
.genicon-product-pdf {
  @include genicon(product-pdf);
}
.genicon-product-pip {
  @include genicon(product-pip);
}
.genicon-product-pkf {
  @include genicon(product-pkf);
}
.genicon-product-pvf {
  @include genicon(product-pvf);
}
.genicon-product-tr3 {
  @include genicon(product-tr3);
}
.genicon-product-tr5 {
  @include genicon(product-tr5);
}
.genicon-product-tr8 {
  @include genicon(product-tr8);
}
.genicon-radiobutton-checked {
  @include genicon(radiobutton-checked);
}
.genicon-radiobutton-invert-checked {
  @include genicon(radiobutton-invert-checked);
}
.genicon-radiobutton {
  @include genicon(radiobutton);
}
.genicon-remove {
  @include genicon(remove);
}
.genicon-right {
  @include genicon(right);
}
.genicon-rise {
  @include genicon(rise);
}
.genicon-rocket {
  @include genicon(rocket);
}
.genicon-rss {
  @include genicon(rss);
}
.genicon-save {
  @include genicon(save);
}
.genicon-search {
  @include genicon(search);
}
.genicon-secure {
  @include genicon(secure);
}
.genicon-smiley {
  @include genicon(smiley);
}
.genicon-star-filled {
  @include genicon(star-filled);
}
.genicon-star {
  @include genicon(star);
}
.genicon-sync {
  @include genicon(sync);
}
.genicon-transparency {
  @include genicon(transparency);
}
.genicon-up {
  @include genicon(up);
}
.genicon-upward {
  @include genicon(upward);
}
.genicon-user {
  @include genicon(user);
}
.genicon-wallet {
  @include genicon(wallet);
}
.genicon-youtube {
  @include genicon(youtube);
}