html, body.genfw { height: 100%; }

body.genfw {

  .wrapper {
    overflow-x: hidden;
    > .row { max-width: 100%; }
  }

  &.not-logged {

    padding-top: 0;
    .wrapper {
      > .row {
        margin: 0;
        padding: 0;
      }
    }
    .user-lang { margin-left: $size-sm-x; }

    @media screen and (min-width: $screen-md-min) {

      .header {
        height: 100px;
        .brand-strips {
          &, img, svg {
            width: 11px !important;
            height: 100px !important;
          }
        }
      }
      .header-tools {
        display: flex;
        height: 100px;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
      }

    }

  }

  &.logged {

    .main {
      .page-header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .nav-page {
          width: 100%;
          flex-shrink: 0;
          order: 1;
        }
        h1 { order: 2; }
        .buttons {
          margin: 0;
          order: 3;
        }
      }
    }

    & {
      background-color: $white;
      @media screen and (max-width: $screen-xs-max) {
        padding-top: 50px;
      }
      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding-top: 50px;
        background-image: none;
      }
      @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        &.mode-light {
          background-image: linear-gradient(90deg, $gray-lighter 25%, $white 25%, $white 100%);
        }
        &.mode-dark {
          background-image: linear-gradient(90deg, $brand-primary 25%, $white 25%, $white 100%);
        }
      }
      @media screen and (min-width: $screen-lg-min) {
        &.mode-light {
          background-image: linear-gradient(90deg, $gray-lighter $navbar-sidebar-width, $white $navbar-sidebar-width, $white 100%);
        }
        &.mode-dark {
          background-image: linear-gradient(90deg, $brand-primary $navbar-sidebar-width, $white $navbar-sidebar-width, $white 100%);
        }
        .main {
          &.col-lg-10 { width: calc(100% - #{$navbar-sidebar-width}); }
        }
      }
    }

    @media screen and (max-width: $screen-xs-max) {
      .wrapper {
        & > .row {
          margin-left: 0;
          margin-right: 0;
        }
      }
      .main {
        .page-header {
          .buttons { margin-bottom: $size-sm-y; }
        }
      }
    }

    @media screen and (min-width: $screen-sm-min) {
      .wrapper {
        &, & > .row {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: stretch;
          align-items: stretch;
          min-height: 100%;
        }
        & > .row {
          margin-left: 0;
          margin-right: 0;
        }
      }
      .page-header {
        h1 { order: 3; }
        .buttons { order: 2; }
      }
    }

    @media screen and (max-width: $screen-sm-max) {
      .wrapper {
        &, & > .row {
          flex-direction: column;
        }
      }
      .main {
        .page-header {
          margin-top: $size-sm-y;
          margin-bottom: $size-nm-y;
          h1 { font-size: $font-size-h2 !important; }
        }
      }
    }

    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    }

    @media screen and (min-width: $screen-md-min) {
      .main {
        & {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          padding-left: 40px;
          padding-right: 40px;
        }
        .page-content {
          flex: 1 0 auto;
        }
        .sticky-footer {
          flex-shrink: none;
        }
      }
      .page-header {
        @include clearfix;
        margin-top: $size-nm-y;
        margin-bottom: $size-nm-y;
        h1 { font-size: $font-size-h2 !important; }
      }
    }

  }

}
