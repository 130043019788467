//
// Pagination (multiple pages)
// --------------------------------------------------

//== Pagination definition

.pagination {
  margin: 0;
  > li {
    @include clear-pseudo-content;
    > a, > span {
      font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */
      @include pagination-size($input-height-base, $font-size-large, $pagination-icon-size-nm);
    }
  }
  @include pagination-variation(primary);
  @include pagination-variation(success);
}

//== Sizing

// Large
.pagination-lg {
  > li {
    > a, > span {
      @include pagination-size($input-height-large, $font-size-huge, $pagination-icon-size-lg);
    }
  }
}

// Small
.pagination-sm {
  > li {
    > a, > span {
      @include pagination-size($input-height-small, $font-size-middle, $pagination-icon-size-sm);
    }
  }
}


// ========== Media Queries ==========

//== print
@media print {

  *,
  .panel-primary,
  .panel-success {
    .pagination li {
      a { border-color: $print-highlight-color !important; }
      &.active a { border-color: $print-text-color !important; }
    }
  }

}