//== Navbar

.navbar {
  border: 0 none !important;
  .navbar-nav {
    > li {
      @include clear-pseudo-content;
      margin-bottom: 0;
      &.dropdown {
        position: inherit;
        .caret { display: none; }
      }
    }
  }
  &-default {
    background-color: $navbar-default-bg;
    border-color: $navbar-default-border;
  }
  &-center {
    .navbar-collapse {
      text-align: center;
      .navbar-nav {
        display: inline-block;
        width: 100%;
        height: $navbar-height;
        float: left;
        text-align: left;
      }
    }
  }
}

//== Main menu

.menu-main {

  .container-fluid,
  .navbar-collapse {
    padding: 0;
  }

  .navbar {
    border-radius: $navbar-border-radius;
    .navbar-nav {
      > li {
        > a {
          line-height: 20px;
          text-transform: uppercase;
          @include underline-not;
          color: $navbar-default-link-color;
        }
        &.dropdown {
          &.active > a {
            color: $navbar-default-link-active-color;
          }
          &.open > a {
            padding-bottom: 9px;
            border-bottom: 6px solid $mainmenu-link-active-border;
            color: $navbar-default-link-active-color;
          }
        }
      }
    }
  }

  .dropdown-menu {
    width: 100%;
    padding: $size-md-y;
    box-shadow: none;
  }

  .col-left-wrapper {
    @include border-right-dotted;
  }

  .nav-tabs {
    width: 100%;
    margin: 0;
    padding: $size-sm-y $size-nm-x $size-sm-y 0;
    border-bottom: 0 none;
    > li {
      width: 100%;
      float: none;
      border: 0 none;
      > a {
        @include underline-not;
        position: relative;
        padding-right: 40px;
        padding-left: 0;
        font-size: 20px;
        &:after {
          @include genicon-styles;
          content: genicon-char(arrow-right-circle);
          @include icon-size(32);
          position: absolute;
          top: 5px;
          right: 0;
        }
      }
      &.active > a {
        @include underline-not;
      }
      &.active > a, > a:hover {
        &:after { content: genicon-char(arrow-right-circle-filled); }
      }
      > a, &.active > a {
        &, &:hover, &:focus {
          border-color: transparent;
          background-color: transparent;
          color: $mainmenu-link-active-color;
        }
      }
      &.no-descendants {
        > a {
          @include underline-reverse;
          &:after {
            @include clear-pseudo-content(after);
            display: none;
            position: relative;
            top: 0;
            content: '';
          }
        }
      }
    }
  }

  .tab-content {
    h4, ul { margin: 0 0 $size-nm-y 0; }
  }

  .main-category {
    position: relative;
    margin: 0 0 0 100px ;
    h3 { margin: 0 0 $size-sm-y 0; }
    .genicon {
      @include icon-size(80);
      position: absolute;
      top: 0px;
      left: -90px;
      color: $dropdown-icon-color;
    }
  }
  .col-left .main-category {
    margin-top: $size-md-y;
    margin-bottom: $size-md-y;
  }

  .panel-list {
    margin-bottom: 0;
    &.panel-primary {
      &, small { color: $white; }
    }
    .form-inline {
      .form-control {
        margin-right: $size-sm-x;
        border-color: $mainmenu-panel-form-control-border;
      }
      label {
        float: left;
        margin: 0 $size-sm-x 0 0;
        line-height: $input-height-base;
        color: $mainmenu-panel-label-color;
      }
    }
  }

  .divider-vertical {
    height: $size-tn-y;
    margin: 0;
    overflow: hidden;
  }

  .list-links {
    > li.highlighted {
      @include clear-pseudo-content;
      display: block;
      margin: 0;
      margin-left: -($size-sm-x + 5);
      padding: $size-tn-y $size-xs-x;
      line-height: $line-height-base;
      background-color: $menu-highlight-bg;
      a {
        @include underline-reverse;
        position: relative;
        padding-left: ($size-sm-x + 5);
        color: $menu-highlight-link-color !important;
        &:hover { color: $menu-highlight-link-hover-color; }
        &:before {
          @include genicon-styles;
          content: genicon-char(angle-bracket-right);
          @include icon-size(12);
          position: absolute;
          top: 1px;
          left: 5px;
          color: $menu-highlight-icon-color;
        }
      }
      &:first-child {
        @include border-radius($radius-top-left: $border-radius-base, $radius-top-right: $border-radius-base);
        padding-top: $size-sm-y;
      }
      &:last-child {
        @include border-radius($radius-bottom-left: $border-radius-base, $radius-bottom-right: $border-radius-base);
        padding-bottom: $size-sm-y;
      }
    }
  }

}

//== Menu top

.menu-top {
  background-color: $menutop-bg;
  border-radius: $border-radius-large;
  ul.nav-pills {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0;
    li {
      display: block;
      margin: 0;
      @include clear-pseudo-content();
      border-right: 1px solid lighten($sw1-color3,5%);
      &:last-child { border: 0 none; }
      a {
        display: block;
        height: 100%;
        padding: $size-nm-x $size-md-y;
        @include underline-not;
        text-align: center;
        background-color: transparent;
        color: $white;
        transition: $trans-base;
        .genicon {
          @include icon-size(36);
          margin-bottom: $size-sm-y;
          color: $menutop-icon-color;
          transition: $trans-base;
        }
        .title {  }
        &:hover {
          background-color: $menutop-pills-hover-bg;
          .genicon { color: $white; }
        }
      }
      &:first-child a {
        border-top-left-radius: $border-radius-large;
        border-bottom-left-radius: $border-radius-large;
      }
      &:last-child a {
        border-top-right-radius: $border-radius-large;
        border-bottom-right-radius: $border-radius-large;
      }
    }
  }
  &.cols-1 { ul.nav-pills li { width: 100%; } }
  &.cols-2 { ul.nav-pills li { width: 50%; } }
  &.cols-3 { ul.nav-pills li { width: 33.33%; } }
  &.cols-4 { ul.nav-pills li { width: 25%; } }
  &.cols-5 { ul.nav-pills li { width: 20%; } }
}


//== Footer

.navbar-footer {
  .navbar-nav {
    &.pull-left { margin-left: -($grid-gutter-width / 2) }
    &.pull-right { margin-right: -($grid-gutter-width / 2) }
    > li {
      color: $footer-color;
      > a,
      > .btn-link {
        display: inline-block;
        border: 0 none;
        font-size: $font-size-base;
        color: $footer-link-color !important;
      }
      > span {
        display: inline-block;
        padding: $nav-link-padding-x;
      }
    }
    &.navbar-delimiters > li {
      &:not(:last-child):after {
        content: '|';
      }
    }
  }
}

.menu-footer {
  padding: $size-nm-y;
  background-color: $menu-footer-bg;
  nav {
    padding: 0 $size-sm-x;
    h4, ul.list-links {
      margin: $size-nm-y 0;
      color: $menu-footer-text;
    }
    a { color: $menu-footer-link-color; }
  }
  .nav-collapse-link {
    @include underline-not;
    position: relative;
    top: 2px;
    margin-right: $size-xs-x;
    color: $menu-footer-link-color;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &.collapsed:hover {
      color: $menu-footer-link-hover-color;
    }
    &:not(.collapsed) .genicon:before { content: genicon-char(angle-bracket-up); }
    &.collapsed .genicon:before { content: genicon-char(angle-bracket-down); }
  }
}

.dropdown-menu {
  .list-languages {
    /* margin: 0; */
    padding-left: $size-nm-y;
    .dropdown-item {
      @include clear-pseudo-content();
    }
    a {
      &, &.dropdown-link {
        font-size: $font-size-base;
        font-weight: bold;
        color: $brand-primary;
      }
      &.disabled {
        @include underline-not;
        color: $gray;
        cursor: initial;
      }
    }
  }
}

// ========== Media Queries ==========

//== desktop +
@media screen and (min-width: $screen-desktop) {

  .menu-mobile {
    display: none;
  }

  .navbar-menu-mobile {
    &.dropdown-menu {
      position: relative;
      top: 0;
      left: 0;
      float: right;
      margin-top: 0;
      padding: 0;
      background: transparent;
      border: 0 none;
      box-shadow: none;
    }
  }

}

//== <= desktop
@media screen and (max-width: ($screen-lg-desktop - 1)) {

  .navbar-menu-mobile {
    &.dropdown-menu {
      .panel {
        &.panel-warning {
          .panel-heading { padding-top: $size-sm-y !important; }
          h3 { font-size: $font-size-middle !important; }
          big.h2 {
            font-size: $font-size-h3 !important;
            .genicon {
              position: relative;
              top: 3px;
            }
          }
        }
        &.panel-list {
          label {
            &.h3 {
              display: block;
              width: 100%;
              font-size: $font-size-large !important;
            }
          }
        }
      }
      .btn {
        white-space: normal;
        &.btn-lines {
          font-size: 14px;
          text-align: left;
          small { font-size: 10px; }
          &:after {
            top: 50%;
            right: $size-tn-x;
            margin-top: -10px;
          }
        }
      }
    }
  }
}

//== desktop
@media screen and (min-width: $screen-desktop) and (max-width: ($screen-lg-desktop - 1)) {
  .navbar-menu-mobile {
    &.dropdown-menu {
      .dropdown-menu {
        h2, .h2 {
          font-size: $font-size-h3 !important;
          margin-top: $size-md-y;
          margin-bottom: $size-md-y;
        }
        h3, .h3 {
          font-size: ($font-size-h3 - 4px) !important;
          margin-top: $size-nm-y;
          margin-bottom: $size-nm-y;
        }
      }
    }
  }
}

//== <= tablet
@media screen and (max-width: ($screen-desktop - 1)) {

  // Menu mobile

  ul, ol {
    &.menu-mobile {
      $icon-size: 32;
      $item-size: ($icon-size + ($size-xs-x * 2));
      & {
        width: 100%;
        @include reset-list();
        @include clearfix;
        display: block;
        height: 60px;
        border-top: 1px solid $menu-mobile-border;
        margin-top: $size-sm-x;
        padding-top: ($size-tn-y / 2);
      }
      li {
        float: left;
        margin-bottom: 0;
        text-align: center;
        a {
          display: inline-block;
          height: $item-size;
          line-height: $line-height-mobile;
          float: none;
          margin: 0 auto;
          padding: $size-xs-x;
          vertical-align: middle;
          border: 0 none;
          font-size: 14px;
          cursor: pointer;
          .genicon { @include icon-size($icon-size); }
          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active,
          &.collapsed:hover {
            color: $dropdown-link-hover-color;
          }
          &.collapsed {
            color: $dropdown-link-color;
          }
          &.link-text {
            //line-height: ($line-height-mobile - 10px);
            line-height: $line-height-base;
            @include underline-not;
            font-size: $font-size-large;
          }
        }
      }
      &.menu-items-1 li { width: $size-items-1; }
      &.menu-items-2 li { width: $size-items-2; }
      &.menu-items-3 li { width: $size-items-3; }
      &.menu-items-4 li { width: $size-items-4; }
      &.menu-items-5 li { width: $size-items-5; }
      &.menu-items-6 li { width: $size-items-6; }
      &.menu-items-7 li { width: $size-items-7; }
      &.menu-items-8 li { width: $size-items-8; }
      &.menu-items-9 li { width: $size-items-9; }
      &.menu-items-10 li { width: $size-items-10; }
    }
  }

  .navbar {
    min-height: 0px !important;
    &-nav { margin: 0; }
    &-collapse {
      &.collapse {
        display: none !important;
        overflow: hidden !important;
        border-top: 1px solid $menu-mobile-border;
        &.in {
          display: block !important;
          overflow: visible !important;
        }
      }
    }
  }

  .navbar-menu-mobile {

    &.dropdown-menu {
      left: 0;
      right: 0;
      border-left: 0;
      border-right: 0;
      margin-top: 0;
      padding: $size-sm-x;
      .navbar { min-height: 0; }
    }

    ol, ul {
      &.list-languages {
        margin: 0;
        li {
          a {
            font-size: $font-size-middle;
            color: $dropdown-link-color;
          }
          &.active a { color: $dropdown-link-hover-color; }
        }
      }
    }

  }

  .menu-main {
    .navbar-collapse {
      border-left: 0;
      border-right: 0;
      width: 100%;
    }
    .dropdown-menu {
      padding: 0;
      .dropdown-menu {
        padding: $size-md-y;
        /* border: 1px solid $menu-mobile-border; */
        border-top: 0 none;
      }
      > .navbar-nav {
        > li {
          > a {
            border-top: 1px solid $menu-mobile-border;
            line-height: ($line-height-mobile - 10px);
            font-size: $font-size-large;
          }
          &:first-child > a { border-top: 0 none; }
        }
      }
    }
    .navbar-menu-mobile {
      .navbar-nav {
        height: auto !important;
        li {
          > a { color: $mainmenu-link-active-color; }
          &.dropdown {
            &.open > a {
              color: $menu-highlight-bg;
              border-bottom-color: $menu-highlight-bg;
            }
          }
        }
      }
      .nav-tabs {
        padding: 0;
        > li {
          > a { font-size: $font-size-large; }
          > a:after { content: genicon-char(arrow-right-circle); }
          &.active a:after, > a:hover:after { content: genicon-char(arrow-right-circle-filled); }
        }
      }
      .col-left-wrapper {
        @include border-bottom-dotted;
        border-right: 0 none;
        height: auto !important;
        margin-bottom: $size-nm-y;
        padding-bottom: $size-nm-y;
      }
    }
  }
  .genfw {
    .menu-main {
      .navbar-collapse {
        width: calc(100% + 15px);
        margin-left: -($grid-gutter-width / 2);
      }
    }
  }

  .navbar-footer {
    text-align: center;
    .navbar-nav {
      &.pull-left { margin-left: 0; }
      &.pull-right { margin-right: 0; }
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
      > li {
        > a,
        > span {
          padding-top: $size-xs-y;
          padding-bottom: $size-xs-y;
        }
      }
      &.pull-left,
      &.pull-right {
        float: none !important;
      }
    }
  }

}

//== tablet
@media screen and (min-width: $screen-tablet) and (max-width: ($screen-desktop - 1)) {

  .menu-main {
    .navbar {
      > .dropdown-menu {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .dropdown-menu {
      border-right: 0 none;
      > .navbar-nav {
        border-bottom: 1px solid $menu-mobile-border;
        &.navbar-right {
          float: none !important;
          margin-right: 0 !important;
        }
        > li > a {
          padding-left: $size-nm-y;
          padding-right: $size-nm-y;
          border-top: 0 none;
          line-height: 1.1em;
          font-size: $font-size-middle;
          //float: none !important;
        }
      }
    }
    .panel-list .form-inline {
      label,
      .form-control,
      .form-group,
      .checkbox,
      .radio {
        float: none;
        @include clearfix;
      }
      .form-group,
      .form-control {
        width: 100%;
        margin-right: 0;
      }
      .form-group {
        margin-bottom: $size-nm-y;
      }
    }
  }

  .menu-top {
    &.sm-cols-1 { ul.nav-pills li { width: 100%; } }
    &.sm-cols-2 { ul.nav-pills li { width: 50%; } }
    &.sm-cols-3 { ul.nav-pills li { width: 33.33%; } }
    &.sm-cols-4 { ul.nav-pills li { width: 25%; } }
    &.sm-cols-5 { ul.nav-pills li { width: 20%; } }
  }

  .menu-footer {
    .col-sm-6 { min-height: 380px;}
  }

}

//== phone
@media screen and (max-width: ($screen-tablet - 1)) {

  .navbar-menu-mobile {
    &.dropdown-menu { margin-top: 2px; }
  }

  ul, ol {
    &.menu-mobile {
      $icon-size: 28;
      $item-size: ($icon-size + ($size-tn-x * 2));
      height: 50px;
      margin-top: ($size-sm-y + 4px);
      li {
        a {
          height: $item-size;
          padding: $size-tn-x;
          line-height: 28px;
          font-size: 14px;
          .genicon {
            @include icon-size($icon-size);
          }
        }
      }
    }
  }

  .menu-main {
    .dropdown-menu {
      .dropdown-menu {
        padding: $padding-middle-vertical;
      }
    }
  }

  .menu-top {
    ul.nav-pills {
      li {
        a {
          padding: $size-sm-x $size-sm-y;
          .genicon {
            position: relative;
            top: 2px;
            margin-bottom: 0;
            &, &:before { @include icon-size(32); }
          }
        }
      }
    }
    &.xs-cols-1 { ul.nav-pills li { width: 100%; } }
    &.xs-cols-2 { ul.nav-pills li { width: 50%; } }
    &.xs-cols-3 { ul.nav-pills li { width: 33.33%; } }
    &.xs-cols-4 { ul.nav-pills li { width: 25%; } }
    &.xs-cols-5 { ul.nav-pills li { width: 20%; } }
  }

  .navbar-footer {
    text-align: center;
    .navbar-nav {
      margin: 0 auto;
      > li {
        float: left;
        > a,
        > span { padding: $size-xs-x $size-xs-y; }
      }
    }
  }

  .page-bottom {
    text-align: center;
    .navbar-tools {
      display: inline-block;
      .navbar-nav {
        display: inline-block;
        margin: 0 auto;
        > li { float: left; }
      }
    }
    .pull-left,
    .pull-right {
      float: none !important;
    }
  }

  .menu-footer {
    .nav {
      padding: 0;
      margin: $size-nm-y;
      h4 { margin: 0; }
    }
  }

}

/* ---------- sidebar ---------- */

.sidebar {
  &.navbar {
    .navbar {
      &-user {
        &.navbar-text {
          position: relative;
          box-sizing: border-box;
          a.user-name {
            display: inline-block;
            @include underline;
          }
        }
      }
      &-nav-collapse {
        .navbar {
          &-nav { margin-top: 0; }
        }
        .nav {
          &-link { font-size: $font-size-tiny; }
        }
      }
    }
    .nav {
      &-item {
        &:last-child { border-bottom: 0 none; }
      }
      &-header {
        margin-top: $size-md-y;
        padding-top: $size-tn-y;
        padding-bottom: $size-tn-y;
        border-top-width: 1px;
        border-top-style: solid;
        border-bottom: 0 none !important;
        font-size: $font-size-h6;
        font-weight: bold;
        text-transform: uppercase;
      }
      &-link {
        display: block;
        padding-top: $size-tn-x;
        padding-bottom: $size-tn-x;
        line-height: $line-height-small;
        @include underline-not;
        cursor: pointer;
        .title { @include underline-reverse; }
        .badge {
          position: relative;
          top: -1px;
          margin-left: $size-sm-y;
        }
        &-collapse {
          .genicon {
            position: relative;
            top: 4px;
            @include icon-size(12);
            float: right;
          }
          &:not(.collapsed) {
            .genicon:before { content: genicon-char(angle-bracket-down); }
          }
          &.collapsed {
            .genicon:before { content: genicon-char(angle-bracket-right); }
          }
        }
      }
    }
    &.navbar-light {
      &.bg-light { background-color: $gray-lighter; }
      .nav {
        &-header {
          border-top-color: rgba(0,0,0,.1);
          color: $gray;
        }
        &-item { }
        &-link {
          color: $gray-darker;
          &:hover { color: $brand-primary; }
        }
      }
      .navbar {
        &-toggler { color: $brand-primary; }
        &-user {
          &.navbar-text {
            a.user-name { color: $brand-primary; }
            a.user-logout {
              color: $gray-dark;
              &:hover { color: $brand-primary; }
            }
          }
        }
      }
    }
    &.navbar-dark {
      &.bg-dark { background-color: $brand-primary; }
      .nav {
        &-header {
          border-top-color: rgba(255,255,255,.2);
          color: $gray;
        }
        &-item { }
        &-link {
          color: $white;
          &:hover { color: $gray-lighter; }
        }
      }
      .navbar {
        &-toggler { color: $white; }
        &-user {
          &.navbar-text {
            a.user-name { color: $white; }
            a.user-logout {
              color: $gray-light;
              &:hover { color: $gray-lighter; }
            }
          }
        }
      }
    }
    @media screen and (max-width: $screen-xs-max) {
      .navbar {
        &-user {
          a.user-name {
            position: relative;
            top: -($size-tn-y);
          }
          .user-lang { margin-left: $size-tn-y; }
          a.user-logout {}
        }
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      margin-top: 0;
      &.fixed-left { position: relative; }
      &.fixed {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        bottom: auto;
        right: 0;
      }
      .navbar {
        &-row {
          height: 50px;
          @include clearfix;
        }
        &-toggler {
          height: 30px;
          float: right;
          margin: $size-sm-y 0;
          background-color: transparent;
          border: 0 none;
          .genicon { float: left; }
        }
        &-brand {
          padding: $size-sm-y 0;
          img { height: 30px; }
        }
        &-collapse {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: $size-nm-y;
          border-top: 1px solid transparent;
          &.in { border-top-color: $sw2-color1; }
        }
        &-user {
          &.navbar-text {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            float: right;
            a.user-name { order: 1; }
            .user-lang {
              order: 3;
              position: relative;
              top: -($size-tn-x);
            }
            a.user-logout {
              order: 2;
              margin-left: $size-sm-y;
            }
          }
        }
      }
    }
    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      .navbar {
        &-nav {
          display: block;
          &.col-sm-6 {
            width: 50%;
            &:first-child { width: calc(50% - 5px); padding-left: 0; }
            &:last-child { width: calc(50% - 5px); padding-right: 0; }
          }
          &.col-sm-4 {
            width: 33.33%;
            &:first-child { width: calc(33.33% - 5px); padding-left: 0; }
            &:nth-child(2) { width: calc(33.33% + 10px); }
            &:last-child { width: calc(33.33% - 5px); padding-right: 0; }
          }
        }
        &-user {
          a.user-name {}
          .user-lang { margin-left: $size-sm-y; }
          a.user-logout {}
        }
      }
    }
    @media screen and (min-width: $screen-md-min) {
      & {
        box-sizing: border-box;
        padding: $size-sm-y;
        margin-bottom: 0;
      }
      .navbar {
        &-toggler { display: none; }
        &-brand {
          margin-bottom: $size-nm-y;
          img:hover { opacity: .85; }
        }
        &-collapse { }
        &-nav {
          padding-left: 0;
          padding-right: 0;
        }
        &-nav-collapse {
          padding-left: $size-sm-x;
        }
        &-user {
          &.navbar-text {
            width: 100%;
            position: relative;
            box-sizing: border-box;
            padding: 0 $size-lg-y 0 $size-sm-x;
            margin: $size-md-y 0 $size-tn-x;
            a.user-name {
              padding-bottom: $size-sm-y;
              font-size: $font-size-middle;
            }
            .user-lang {
              position: absolute;
              top: -($size-tn-y);
              right: $size-sm-x;
              .btn-link {}
            }
            a.user-logout {
              @include underline-reverse;
            }
          }
        }
      }
      &.fixed-left {
        //width: inherit;
        flex-flow: unset;
        align-items: unset;
        float: left;
        position: relative;
      }
    }
    @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      &.fixed-left {
        &.col-md-3 { width: 25%; }
      }
    }
  }
}

.sidebar {
  &.navbar {
    @media screen and (min-width: $screen-lg-min) {
      &.fixed-left {
        &.col-lg-2 { width: $navbar-sidebar-width; }
      }
    }
  }
}