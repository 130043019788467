//
// Popovers
// --------------------------------------------------

.popover {
  padding: 0;
  @include box-shadow(none);
  .popover-title {
    @include border-radius($popover-border-radius $popover-border-radius 0 0);
    border: 0 none;
    font-size: $font-size-middle;
    font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */
    color: $popover-title-color;
  }
}

.infograph .panel {
  h3.popover-title { font-size: $font-size-base; }
}

@include set-tooltip-color(popover, '', $popover-default, $popover-default-text); // default
@include set-tooltip-color(popover, default, $popover-default, $popover-default-text);
@include set-tooltip-color(popover, info, $popover-info);
@include set-tooltip-color(popover, success, $popover-success);
@include set-tooltip-color(popover, warning, $popover-warning);
@include set-tooltip-color(popover, danger, $popover-danger);