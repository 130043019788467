@media print {

    .login-button, #topmenu, .lang, .back, #header form, .toppart, .middlepart, .universal-gradient-box, .p2, .intm,
    #oceneni, .universal-big-button, .box-kontakty, .askonline-right-datova-strana, .slovnik, .promo-hp, .ui-droppable, .mapa_stranek, .opened, #paletka_odkaz
    , .buttons-note, .buttons-headline, .info-min, .info-middle, .info-max, .button-2lines, .kasicka-grafiky, #invest_kalk_selectbox_input, .switch, .boxes-right-hp-links {
        display:none !important;
    }

    * {

        background: transparent !important;
    }

    body{
        font-size: 13px;
        font-family: arial,sans-serif;

    }

    .col-left{
        width: 100%;
    }

    #header .gen-ppf{
        float:right;
    }

    #header h1{
        width:400px;
        display:inline;
    }

    #header{
        height:80px;
        border-bottom:1px solid #AAA;
    }

    .breadcrumb ul{
        padding: 0;
        float: left;
        list-style-type: none;

        width:100%;
    }

    .clearfix{margin: 0px 0 3px 0; padding-bottom: 40px;}

    .breadcrumb ul li{
        margin: 0;
        padding: 0;
        float: left;
        list-style-type: none;
        font-size: 12px;
        color:#666;
        letter-spacing: -0.02em;
    }

    .breadcrumb ul li a{
        padding-right: 12px;
        margin-right: 7px;
        color:#888;
        background-attachment: scroll;
        background-clip: border-box !important;
        background-color: transparent !important;
        background-image: url("../gfx/universal-box/arrow-grey-small.png") !important;
        background-origin: padding-box !important;
        background-position: right center !important;
        background-repeat: no-repeat !important;
        background-size: auto auto !important;

    }

    .breadcrumb .universal-lighter-button{
        margin: 3px 0 13px;
        float: right;
    }

    h1 {
        font-size: 40px;
        line-height: 48px;
        padding: 0;
        margin-bottom:26px;
        font-weight: normal;
        letter-spacing: 0.01em;
        position: relative;
        right: 2px;
    }

    h1 span{
        font-size: 13px;
        line-height: 14px;
        display: block;
        font-weight: bold;
        position: relative;
        bottom: 2px;
        padding-bottom: 9px;
    }

    h2 {
        font-size: 20px;
        line-height: 24px;
        padding: 0;
        margin: 40px 0 16px 0;
        display:block;
        font-weight: normal;
    }

    h3 {
        font-size: 16px;
        line-height: 18px;
        padding: 0;
        margin: 30px 0 12px 0;
        font-weight: bold;
    }
    h4 {
        font-size: 14px;
        line-height: 18px;
        padding: 0;
        margin: 20px 0 6px;
        font-weight: bold;
    }

    .tabulky table{
        width:100% !important;
        border:1px solid #ccc;
        border-bottom:0px;
        font-size: 13px;
    }

    .tabulky td{
        border-bottom:1px solid #ccc;
        border-left:1px solid #ccc;
        width:auto !important;


    }

    .tabulky th{
        font-weight:bold;
        border-bottom:1px solid #ccc;
        text-align: left;

    }

    .table th{
        width:100%;
        border-bottom:1px solid #ccc;

        font-size: 13px;
    }

    .table table{
        width:100%;
        border:1px solid #ccc;
        border-bottom:0px;
        font-size: 13px;
    }

    .table td{
        border-bottom:1px solid #ccc;
        border-left:1px solid #ccc;


    }

    th{
        width:100%;
        border:1px solid #ccc;
        border-bottom:0px;
        font-size: 13px;
    }

    table{
        width:100%;
        border:1px solid #ccc;

        font-size: 13px;
        margin:3px;
    }

    td{
        border-bottom:1px solid #ccc;
        border-left:1px solid #ccc;
    }

    .box-spendlik table{
        text-align:left;
        border:0px;
    }


    #content ul li {
        background-attachment: scroll;
        background-clip: border-box;
        background-color: transparent;
        background-image: url("../gfx/universal-box/arrow-grey-small.png");
        background-origin: padding-box;
        background-position: 0 5px;
        background-repeat: no-repeat;
        background-size: auto auto;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 6px;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 16px;
        padding-right: 0;
        padding-top: 0;
        text-align: justify;
    }

    #content p {
        line-height: 19px;
        margin-bottom: 10px;
        margin-left: 0;
        margin-right: 0;
        margin-top: 10px;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: justify;
    }

    a {
        text-decoration: underline;
        outline: none;
    }

    .infolisty-hp{
        display: inline !important;

    }
    .inner{
        color:#777;
    }

    .inv-calc, .kurzy{
        display: inherit;

    }

    .headline, .zisk{
        font-weight:bold;
    }

    .posunuti{
        position:absolute;
        margin-top:-270px;
    }

    .box{
        margin-top:55px;
    }

    .textove{
        color:#000;
    }

    .inner .left{
        padding-top:30px;
    }


    .box-spendlik-small table, td, th{
        border:0px;
        width:auto;
        padding-left:5px;
    }

    .one-line table{ border:1px solid #CCC;}

    #gmapsmall{
        position:relative;
        width:250px;
        height:250px;
    }

    .down-ico, .up-ico, .stay-ico{
        margin-right:3px;
    }

    .activ{
        font-size: 20px;
        line-height: 24px;
        padding: 0;
        margin: 40px 0 16px 0;
        font-weight: normal;
        color:#000;
        text-decoration:none;
    }

    .accordion-universal .table {
        display:block !important;
    }

    .last-news{display:block !important;}

    .cleaner {
        height: 0;
        font-size: 0;
        line-height: 0;
        clear: both;
    }

    .universal-white-box{
        display:block !important;

    }

    #tabs-kalkulacka{
        border:1px solid #CCC;
        padding:20px;
    }

    #tabs-kalkulacka h2{
        display:block;
    }


    input, select{
        border:1px solid;
        padding:5px;
        width:auto !important;
    }

    label{display: inline-block; width:200px;}

    .text label{display:inline-block; font-weight:bold; margin:3px; width:200px;}
    .text{margin:2px; display:block;}


    .cpi-konto{
        border:1px solid #CCC;
        padding:20px;
    }

    #content .clearfix{
        background-color:#ccc !important;
        width:150px;
        padding:3px;
        text-decoration:none;
        border:1px solid #000;
        display:block !important;
    }

    #invest_kalk_selectbox{
        display:block !important;
    }

    .small, .h, .jmeno{
        font-weight:bold;
    }

    .jmeno{
        font-size: 20px;
        line-height: 24px;
        padding: 0;
        margin: 40px 0 16px 0;
        font-weight: normal;
    }

    .contact-persons-boxes .overlay{
        display:block !important;
        color:#000;
    }

    button{
        background-color:#ccc !important;
        border:1px solid black;
    }

    textarea{
        border:1px solid black;
    }

    .a-l{
        font-weight:bold;
        display:inline-block !important;
    }

    .a-r{
        display: inline-block;
    }

    .oteviraci-doba table{
        width:250px;
        border:1px solid #CCC;

    }

    .cleft ul{
        width:500px !important;
    }

    .col-system {
        width:500px !important;
    }

}