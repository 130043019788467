//
// Pages
// --------------------------------------------------

// ========== Media Queries ==========

//== desktop +
@media screen and (min-width: $screen-desktop) {

}

//== desktop
@media screen and (min-width: $screen-desktop) and (max-width: ($screen-lg-desktop - 1)) {

}

//== phone & tablet
@media screen and (max-width: ($screen-desktop - 1)) {

}

//== tablet
@media screen and (min-width: $screen-tablet) and (max-width: ($screen-desktop - 1)) {

}

//== phone
@media screen and (max-width: ($screen-tablet - 1)) {

  .page-content {
    &.m-b-hg { margin-bottom: 0 !important; }
  }

}


//== print
@media print {

  .page-content {
    .content {
      width: 100% !important;
    }
  }

  &.print-special {
    .content * {
      display: none;
      &.fund-print {
        &, & * {
          display: inherit;
        }
        table    { display: table }
        tr       { display: table-row }
        thead    { display: table-header-group }
        tbody    { display: table-row-group }
        tfoot    { display: table-footer-group }
        col      { display: table-column }
        colgroup { display: table-column-group }
        td, th   { display: table-cell }
        caption  { display: table-caption }
      }
    }
  }

  .graph-container {
    width: 98%;
    overflow: hidden;
    .highcharts-container {
      &, & svg {
        width: 100%;
      }
    }
  }

  .row-col-transform {
    .col-md-12:first-child h3.m-t-0 { margin-top: $size-hg-y !important; }
  }

}