//
// List groups
// --------------------------------------------------


.list-group-item {
  @include clear-pseudo-content();
}

a.list-group-item {
  @include underline-not;
}

.list-group-news {
  .list-group-item {
    text-align: center;
    font-size: $font-size-large;
    &.active {
      color: $white !important;
      @include hover-focus-active { color: $white !important; }
    }
  }
}


// ========== Media Queries ==========

//== print
@media print {

  .list-group-item.active { border-color: $print-text-color !important; }

}

