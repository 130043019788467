/* ---------- User lang ---------- */

.user-lang {
  .dropdown-toggle {
    position: relative;
    padding-right: $size-nm-y;
    &:after {
      @include genicon-styles;
      content: genicon-char(angle-bracket-down);
      @include icon-size(12);
      position: absolute;
      right: 0;
      left: auto;
      top: 50%;
      margin-top: -6px;
    }
  }
  .btn-link {
    font-weight: bold;
    @include underline-not;
  }
}
.header .user-lang {
  .btn-link { font-size: $font-size-middle; }
}
.sidebar {
  &.navbar-light {
    .user-lang {
      .btn-link {
        color: $gray-dark !important;
        &:hover { color: $brand-primary !important; }
      }
    }
  }
  &.navbar-dark {
    .user-lang {
      .btn-link {
        color: $white !important;
        &:hover { color: $gray-lighter !important; }
      }
    }
  }
}

/* ---------- Responzive tools ---------- */

.scrollable-horizontal {
  overflow-x: initial;
  overflow-y: initial;
  position: relative;
  &.ps > .ps__rail-x,
  &.ps > .ps__rail-y {
    opacity: 0.6;
  }
  &.ps > .ps__rail-x {
    &, &:hover, &:focus, &.ps--clicking {
      .ps__thumb-x { height: 11px; }
    }
  }
  &.ps > .ps__rail-y {
    &, &:hover, &:focus, &.ps--clicking {
      .ps__thumb-y { width: 11px; }
    }
  }
}

/* ---------- Homepage ---------- */

.jumbotron {
  &.jumbotron-banner {
    &.jumbotron-signpost {
      a { padding: 0; }
      .jumbotron-title {
        small { color: $gray-light; }
      }
    }
  }
}

/* ---------- Filter ---------- */

.filter-panel {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (max-width: $screen-md-max) {
    @for $i from (1 + 1) through $grid-columns {
      .col-sm-#{$i},
      .col-md-#{$i},
      .col-lg-#{$i} {
        padding-left: $size-xs-y;
        padding-right: $size-xs-y;
      }
    }
    .form-group-sm { margin-bottom: $size-xs-y; }
  }
}

/* ---------- Tables ---------- */

.fund-courses {
  @media screen and (max-width: ($screen-tablet - 1)) {
    thead,
    tbody {
      tr {
        width: 100%;
        display: table-row;
        flex-direction: initial;
        flex-wrap: initial;
      }
    }
  }
}

.table-rules {
  &.table-bordered {
    > thead {
      > tr {
        > th, > td {
          border-left: 0 none !important;
          border-right: 0 none !important;
        }
      }
    }
  }
}
.table-rules-users {
  &.table-bordered {
    width: 100%;
    border: 0 none;
    margin: 0;
    > tbody {
      > tr {
        > td {
          &.td-operator {
            width: 1px;
            background-color: $gray-light;
          }
        }
      }
    }
  }
}

/* ---------- Transactions ---------- */

.table {
  tr {
    .detail-toggle {
      &:after {
        display: inline-block;
        content: ' ';
        @include genicon-styles;
        @include icon-size(10);
        margin-left: $size-tn-y;
        vertical-align: top;
        position: relative;
        top: 3px;
      }
      &[aria-expanded=false] {
        &:after { content: genicon-char(angle-bracket-down); }
      }
      &[aria-expanded=true] {
        text-decoration: none;
        color: $black;
        &:after { content: genicon-char(angle-bracket-up); }
      }
    }
    &.detail-opened {
      > td, > th {
        border-bottom: 0 none;
      }
    }
    &.transaction-detail {
      > td, > th {
        background-color: transparent;
      }
    }
  }
}

/* ---------- Panels ---------- */

.panel-rules {
  .panel {
    &-heading {
      padding: 0;
      @include clearfix;
      h4 {
        float: left;
        padding: $size-tn-x;
        color: $brand-primary;
      }
      .buttons {
        float: right;
        margin: 0;
      }
    }
    &-body {
      .input-group {
        .bootstrap-tagsinput {
          &:focus,
          &:focus-within {
            z-index: $zindex-dropdown;
          }
          .tt-dataset {
            margin-top: $size-tn-y;
            border: 1px solid $sw3-color3;
            background-color: $sw1-color5;
          }
          .tt-suggestion {
            display: block;
            width: 100%;
            cursor: pointer;
            &:hover {
              background-color: lighten($sw1-color5,5%);
            }
          }
        }
      }
      @media screen and (max-width: ($screen-tablet - 1)) {
        .input-group {
          &-addon {
            padding-left: $size-tn-y;
            padding-right: $size-tn-y;
            font-size: $font-size-small;
            .genicon {
              &.icon-size-16:before { @include icon-size(12); }
            }
          }
          .form-control {
            height: 100px;
            input {
              min-width: initial;
              font-size: $font-size-small;
            }
          }
        }
      }
    }
  }
}

/* ---------- Components ---------- */
.combobox-selected .combo-show {
  display: none;
}

/* :not doesn't work in IE8 */
.combobox-container:not(.combobox-selected) .combo-remove {
  display: none;
}