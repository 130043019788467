/* ---------- Valign ---------- */

.valign {
  //z-index: $zindex-element;
  display: table !important;
  table-layout: fixed;
  width: 100%;
  @include min-height(inherit);
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .valign-middle {
    display: table-cell !important;
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
}
table {
  td, th {
    &.valign-top { vertical-align: top !important; }
    &.valign-middle { vertical-align: middle !important; }
    &.valign-bottom { vertical-align: bottom !important; }
  }
}
