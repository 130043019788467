//
// Panels
// --------------------------------------------------

// Contextual variations

.panel,
.panel-heading,
.panel-body,
.panel-footer {
  @include border-radius($border-radius-base);
  @include opacity(1);
}

.panel {

  & { border-radius: $border-radius-base; }

  &-default {
    .panel-heading {
      color: $panel-title-color;
    }
  }

  &-heading {
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &-list,
  &-news,
  &-sales,
  &-chat,
  &-contact,
  &-hp {
    position: relative;
    .panel-heading {
      padding: $panel-padding-md $panel-padding-md 0 $panel-padding-md;
      border: 0 none;
      h2.h2, h3.h3, .panel-title {
        margin: 0;
        padding: 0;
        line-height: 1em;
        font-size: $font-size-h3;
      }
    }
    .panel-body {
      padding: $panel-padding-md;
    }
    .panel-footer {
      padding: 0 $panel-padding-md $panel-padding-md $panel-padding-md;
      border: 0 none;
      @include border-radius(0);
    }
  }

  &-list ul a { @include underline; }

  &-news,
  &-sales,
  &-chat,
  &-contact {
    .panel-footer {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &-sales,
  &-chat,
  &-contact {
    .panel-heading,
    .panel-body,
    .panel-footer {
      @include opacity(1);
      padding-left: ceil($size-lg-x * 1.3);
    }
    .panel-heading {
      position: relative;
      h3, .panel-title {
        font-size: $font-size-h3 - 4;
        color: $panel-title-color;
      }
      .genicon {
        @include icon-size(36);
        position: absolute;
        top: $panel-padding-md;
        left: $panel-padding-md;
      }
    }
    .genicon { color: $panel-icon-huge-color; }
  }

  &-sales {
    .panel-heading,
    .panel-body,
    .panel-footer {
      padding-left: $panel-padding-lg;
    }
    address.vcard {
      margin-bottom: 0;
      .type {display: none;}
      p {
        margin-bottom: 0;
        &.tel, &.tel *  { line-height: 36px; }
        &.email, &.email * { line-height: 23px; }
      }
      .tel .value {
        margin: $size-sm-y 0 !important;
        line-height: $line-height-equal;
        font-size: 32px;
        color: $panel-address-highlighted-color;
      }
      .email .value {
        @include underline;
        font-size: 16px;
        color: $panel-sales-email-color;
      }
    }
    .panel-body {
      padding-top: $size-nm-y;
      padding-bottom: $size-nm-y;
    }
    .panel-footer {
      position: relative;
      // left: ceil($grid-gutter-width / 2);
      .btn { width: 200px; }
    }
  }

  &-chat {
    .panel-body { padding-top: $panel-padding-sm; }
    .chat-status {
      @include border-radius(6px);
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: $size-tn-x;
      &, &.off-line { background-color: $chat-status-offline-color; }
      &.on-line { background-color: $chat-status-online-color; }
    }
    //== tablet
    @media screen and (min-width: $screen-tablet) and (max-width: ($screen-desktop - 1)) {
      p {
        &.phone-number { font-size: 22px !important; }
      }
    }
  }

  &-contact {
    .panel {
      &-heading,
      &-body,
      &-footer {
        max-width: 220px;
        margin: 0 auto;
        padding: 0;
        &, p {
          line-height: 1em;
          text-align: center;
        }
      }
      &-heading {
        margin-top: 70px;
        .genicon {
          position: absolute;
          top: 2px;
          left: -($size-lg-y);
        }
      }
      &-title { margin-bottom: $size-tn-y !important; }
    }
    p {
      &.phone-number {
        margin-bottom: $size-tn-y;
        font-size: ($font-size-h3 + 2) !important;
        color: $brand-danger;
      }
      &.email {
        font-size: 15px !important;
        a {
          &, &:hover, &:focus { color: $brand-primary; }
        }
      }
    }
    @media screen and (max-width: $screen-md-max) {
      .panel {
        &-heading {
          margin-top: 108px;
          .genicon {
            top: -72px;
            left: 50%;
            margin-left: -28px;
            @include icon-size(56);
          }
        }
      }
    }
    @media screen and (max-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      .panel {
        &-heading {
          .genicon {
            top: -64px;
            margin-left: -24px;
            @include icon-size(48);
          }
        }
        &-title { font-size: 18px !important; }
      }
      p {
        &.phone-number { font-size: $font-size-h3 !important; }
        &.email { font-size: 12.5px !important; }
      }
    }
  }

  &-news {
    .panel-heading {
      h3, .panel-title { color: $panel-news-title-color; }
    }
    .card-title a { color: $panel-news-link-color; }
    nav {
      display: block;
      height: $input-height-small + 4;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }
    .pagination-sm {
      > li {
        > a, > span {
          @include pagination-size(($input-height-small + 4), ($font-size-middle + 4), ($pagination-icon-size-sm + 4));
        }
      }
    }
  }

  &-hp {
    & {
      position: relative;
      border: 0 none;
      border-radius: $border-radius-base;
      margin-bottom: $size-md-y;
    }
    h3.panel-title {
      margin-bottom: $size-nm-y !important;
      font-family: $font-family-bold;
      color: $panel-info-hp-title;
    }
    .panel {
      &-heading {
        .panel-description {
          margin-bottom: 0;
          font-size: 16px;
          text-align: left;
        }
      }
    }
    &.panel-info {
      &,
      .panel-heading,
      .panel-body,
      .panel-footer {
        background-color: $panel-info-hp;
      }
      .panel-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
    &.panel-warning {
      &,
      .panel-heading,
      .panel-body,
      .panel-footer {
        background-color: $sw3-color2;
      }
      .panel-body {
        h4 { color: $brand-primary; }
        p { color: $sw1-color5; }
      }
    }
  }

  &-article {
    .panel-heading,
    .panel-body,
    .panel-footer {
      padding: $panel-padding-lg;
    }
    .panel-heading {
      border-bottom: 0;
      padding-bottom: 0;
    }
    .panel-title {
      font-size: $font-size-h2;
      color: $panel-title-color;
    }
  }

  &-sidebar {
    .panel-heading {
      padding: $panel-padding;
      padding-bottom: 0;
      h3.h3, .panel-title { font-size: ($font-size-h3 - 4) !important; }
    }
    .panel-body { padding: $panel-padding; }
    .panel-footer {
      padding: $panel-padding;
      padding-top: 0;
    }
  }

  &-sidebar {
    &.panel-chat {
      .panel-heading {
        padding-left: $panel-padding + 36px + $size-sm-x;
        .genicon {
          top: $panel-padding;
          left: $panel-padding;
        }
      }
      .panel-footer {
        position: relative;
        left: inherit;
        right: inherit;
        bottom: inherit;
        padding: $panel-padding-sm $panel-padding;
        border-top: 1px solid $panel-default-border;
        .genicon {
          @include icon-size(30);
          position: relative;
          top: 0px;
          vertical-align: top;
          margin-right: $size-tn-x;
        }
        .phone-number {
          margin: $size-xs-y 0 !important;
          line-height: 32px;
          font-size: ($font-size-h3 - 4);
          font-weight: bold;
          color: $panel-address-highlighted-color;
          &-long {
            line-height: 30px;
            font-size: ($font-size-huge - 1);
          }
        }
        .contact-email { font-size: $font-size-middle; }
      }
    }
  }

  &-signpost {
    .panel-body { padding: $panel-padding-md; }
    dl {
      margin: 0;
    }
    dt {
      display: block;
      float: left;
      width: $size-lg-x;
      .genicon {
        @include icon-size(48);
      }
    }
    dd {
      display: block;
      min-height: 48px;
      margin-left: 58px;
      margin-bottom: $size-nm-y;
      font-size: $font-size-middle - 1;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-download {
    border: 0 none;
    position: relative;
    &:after {
      content: ' ';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: -1px;
      right: -1px;
      border-bottom: $grid-gutter-width solid $panel-download-corner-bg;
      border-right: $grid-gutter-width solid transparent;
      /*
      -webkit-border-bottom-left-radius: $border-radius-base;
      -moz-border-radius-bottomleft: $border-radius-base;
      border-bottom-left-radius: $border-radius-base;
      */
      background-color: $panel-download-corner-empty-bg;
    }
    .genicon { color: $panel-icon-huge-color; }
    .list-links a { @include underline; }
  }

  &-collapse {
    .panel-body {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-graph {
    .form-inline {
      .form-control {
        width: 120px;
        height: $input-height-small + 2;
        font-size: $font-size-middle;
        text-align: center !important;
      }
      label { margin-right: $size-xs-x; }
    }
    .btn-group {
      .btn-default {
        &.active,
        &:hover,
        &:focus {
          background-color: $btn-group-active-bg;
          border-color: $btn-group-active-border;
          color: $btn-group-active-color !important;
        }
      }
      &-narrow {
        .btn-info {
          &.btn-sm {
            width: 61px;
            &.active,
            &:hover,
            &:focus {
              background-color: $btn-group-narrow-active-bg;
              border-color: $btn-group-narrow-active-border;
              color: $btn-group-narrow-active-color !important;
            }
          }
        }
      }
    }
  }

  p { text-aling: left;  }

}

//== Accordion

.panel-group {
  .panel-default {
    @include box-shadow(none);
    border: 0 none;
    .panel-heading {
      @include border-radius($accordion-radius);
      padding: 0;
      background-color: $accordion-heading-bg;
      .panel-title {
        border: 0 none;
        font-size: $font-size-huge;
        font-weight: normal;
        > a {
          display: block;
          position: relative;
          padding: $size-sm-y 50px $size-sm-y $size-sm-x;
          line-height: 32px;
          color: $accordion-heading-color;
          text-decoration: none;
          &:after {
            position: absolute;
            top: $size-sm-y;
            right: $size-sm-x;
            @include genicon-styles;
            content: genicon-char(arrow-down-circle);
            @include icon-size(32);
            color: $accordion-heading-icon-color;
          }
        }
      }
      + .panel-collapse > .panel-body,
      + .panel-collapse > .list-group {
        border: 0 none;
      }
    }
    &.active {
      .panel-heading {
        background-color: $accordion-heading-active-bg;
        .panel-title > a {
          color: $accordion-heading-active-color;
          &:after {
            content: genicon-char(arrow-up-circle);
            color: $accordion-heading-active-icon-color;
          }
        }
      }
    }
  }
}

// Contextual variations
.panel-default {
  @include panel-variant($panel-default-bg, $panel-default-border, $panel-default-link, $panel-default-text, $panel-default-heading);
}
.panel-primary {
  @include panel-variant($panel-primary-bg, $panel-primary-border, $panel-primary-link, $panel-primary-text, $panel-primary-heading);
}
.panel-success {
  @include panel-variant($panel-success-bg, $panel-success-border, $panel-success-link, $panel-success-text, $panel-success-heading);
}
.panel-info {
  @include panel-variant($panel-info-bg, $panel-info-border, $panel-info-link, $panel-info-text, $panel-info-heading);
}
.panel-warning {
  @include panel-variant($panel-warning-bg, $panel-warning-border, $panel-warning-link, $panel-warning-text, $panel-warning-heading);
}
.panel-danger {
  @include panel-variant($panel-danger-bg, $panel-danger-border, $panel-danger-link, $panel-danger-text, $panel-danger-heading);
}
.panel-highlight {
  @include panel-variant($panel-highlight-bg, $panel-highlight-border, $panel-highlight-link, $panel-highlight-text, $panel-highlight-heading);
}

// ========== Media Queries ==========

//== desktop +
@media screen and (min-width: $screen-desktop) {

}

//== desktop
@media screen and (min-width: $screen-desktop) and (max-width: ($screen-lg-desktop - 1)) {

  .block-important {
    .panel-list,
    .panel-news {
      .panel-heading .panel-title {
        &, h3 {
          font-size: 24px;
        }
      }
    }
  }

  .panel {

    &-sales {
      address.vcard {
        .tel .value {
          font-size: 34px;
        }
      }
      .col-md-6 {
        .btn-default { display: none; }
      }
    }

    &-chat {
      .panel-body,
      .panel-footer {
        padding-left: $panel-padding;
      }
    }

  }

}

//== phone & tablet
@media screen and (max-width: ($screen-desktop - 1)) {

  .panel {

    &-sales {
      .svg-map { display:none; }
      .hidden-xs { display:none; }
    }

  }

}

//== tablet
@media screen and (min-width: $screen-tablet) and (max-width: ($screen-desktop - 1)) {

}

//== phone
@media screen and (max-width: ($screen-tablet - 1)) {

}

//== print
@media print {

  .panel {
    border: 1px solid $print-border-color;
    &-graph {
      .btn-group {
        .btn-default {
          &.active,
          &:hover,
          &:focus {
            color: $print-text-color !important;
          }
        }
      }
    }
  }

  .panel-group .panel-default {
    .panel-heading { border: 1px solid $print-border-color; }
    &.active .panel-heading {
      border: 0 none;
      border-bottom: 1px solid $print-text-color;
    }
  }


}
