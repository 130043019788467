/* ------------ Modal ------------ */

.modal {

  @mixin set-indent($size) {
    &-header,
    &-body,
    &-footer {
      padding-left: $size;
      padding-right: $size;
    }
    &-header { padding-top: $size; }
    &-footer { padding-bottom: $size; }
    &-body {
      hr {
        margin: $size 0;
        margin-left: -($size);
        margin-right: -($size);
      }
    }
  }

  &-dialog {
    z-index: $zindex-modal;
    &.modal {
      &, &-nm { max-width: $modal-nm-width; }
      &-sm { max-width: $modal-sm-width; }
      &-md { max-width: $modal-md-width; }
      &-lg { max-width: $modal-lg-width; }
      &-hg { max-width: $modal-hg-width; }
    }
  }

  h4.h4, h4.modal-title {
    margin: 0 $modal-inner-padding 0 0;
    font-size: $font-size-huge;
    color: $modal-title-color;
  }

  &-header {
    position: relative;
    padding-bottom: 0;
    border-bottom: 0;
    .close {
      display: inline-block;
      width: 30px;
      height: 30px;
      position: absolute;
      top: $modal-inner-padding-sm;
      right: $modal-inner-padding-sm;
      padding: 0;
      text-align: center;
      color: $gray-dark;
      &:hover { color: $modal-title-color; }
      .genicon-close {
        line-height: 1em;
        @include icon-size(20);
      }
    }
  }

  &-content {
    border: 0 none;
    @include box-shadow(none);
  }

  &-footer {
    display: block;
    padding-top: 0;
    border-top-width: 0;
    &.bordered { border-top-width: 1px; }
  }

  @media screen and (min-width: $screen-md-min) { @include set-indent($modal-inner-padding); }
  @media screen and (max-width: $screen-sm-max) { @include set-indent($modal-inner-padding-sm); }

  &.preloader-show {
    .modal-content { pointer-events: none; }
  }

}

/* ---------- Modal Map ---------- */

.modal-map {
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: $modal-map-padding;
  }
  .modal-header {
    padding-bottom: 0;
    .close .genicon { @include icon-size(16); }
  }
  .modal-title {
    font-size: $font-size-middle;
  }
  .modal-body {
    address { margin-bottom: 0; }
    font-size: $font-size-base;
    .place-location {
      margin-bottom: 0;
      color: $modal-map-place;
    }
  }
  .modal-footer {
    padding-top: 0;
    .btn { margin: 0; }
  }
}

/* ---------- Modal Video ---------- */

.modal-video {
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: $size-sm-x;
  }
  .modal-body {
    padding-top: 0;
    text-align: center;
  }
  iframe {
    margin-left: auto;
    margin-right: auto;
  }
}