.warning-headline{
    margin: 20px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.42857143;
    color: #6f7072;
    font-family: HelveticaNeueW02-75Bold,"Helvetica Neue",Helvetica,Arial,sans-serif;
}


.dukat {
  display: inline-block;
} 
.dukat img {
  width: 80px;
  height: 80px;
  border: 0 none;
  margin: 0;
}
.header .dukat { 
  margin-top: 25px;
  margin-left: 30px;
}
.header .dukat img {
  width: 65px !important;
  height: 65px !important;
}
.content .dukat,
.sidebar .dukat {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.sidebar *:first-child {
  margin-top: 0;
}
.sidebar *:last-child {
  margin-bottom: 0;
}
.footer .dukat {
  margin-left: 30px;
  float: right;
}
.footer .dukat img {
  width: 65px !important;
  height: 65px !important;
}

.prestup-hidden {
    display: none;
}

.banner-main.banner-soutez.year-2020 {

}
@media screen and (max-width: 767px) {
    .banner-main.banner-soutez.year-2020 {
        margin-top: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        width: calc(100% + 600px) !important;
        margin-left: -30px !important;
        margin-right: -30px !important;
    }
    .banner-main.banner-soutez.year-2020 .jumbotron-content {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
    .banner-main.banner-soutez.year-2020 h1 {
        margin-top: 0 !important;
    }
    .genfw .banner-main.banner-soutez.year-2020 .jumbotron-polygon.gen-polygon {
        opacity: 60% !important;
    }
    .banner-main.banner-soutez.year-2020 .position-absolute.xs-mov-b-hg {
        bottom: 90px !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .banner-main.banner-soutez.year-2020 {
        width: calc(100% + 100px) !important;
        margin-left: -50px !important;
        margin-right: -50px !important;
    }
    .genfw .jumbotron-polygon.gen-polygon {
        width: 75% !important;
    }
}
@media screen and (min-width: 992px) and (max-width: 1279px) {
    .genfw .jumbotron-polygon.gen-polygon {
        width: 65% !important;
    }
}
@media screen and (min-width: 1280px) {
    .genfw .jumbotron-polygon.gen-polygon {
        width: 57.5% !important;
    }
}