//
// Forms
// --------------------------------------------------

fieldset {
  padding: $size-md-y $size-md-x;
  margin: 0;
  border: 2px solid $fieldset-border;
  min-width: 0;
  legend {
    display: inline;
    width: auto;
    padding: 0 $size-md-x;
    margin-bottom: 0;
    border: 0;
    font-size: $font-size-middle;
    font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */
    color: $fieldset-legend-color;
  }
}

label {
  font-size: $font-size-middle;
  color: $form-label-color;
  small {
    font-weight: normal;
  }
}

// Common form controls

.form-group {
  &.focus, &.focus-permanent {
    &:not(.has-error):not(.has-success) {
      label, .select-wrapper:after { color: $input-focus-text; }
      .form-control { border-color: $input-focus-border; }
    } 
  }
  &-inline {
    label { color: $inline-form-label-color; }
    .form-control {
      display: inline-block;
      margin: 0 $size-tn-x;
    }
  }
  &-sm {
    label { font-size: $font-size-small; }
  }
  &.has-error,
  &.has-warning,
  &.has-success {
    .form-control {
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }
  }
  &.has-error {
    .form-control,
    .input-group-addon {
      border-color: $sw1-color1;
      color: $sw1-color1;
    }
  }
  &.has-warning {
    .form-control,
    .input-group-addon {
      border-color: $sw1-color4;
      color: $sw1-color4;
    }
  }
  &.has-success {
    .form-control,
    .input-group-addon {
      border-color: $sw2-color5;
      color: $sw2-color5;
    }
  }
}

.form-control {
  padding: $size-xs-y $size-xs-x;
  font-size: $font-size-middle;
  border-radius: $border-radius-base;
  border-color: $gray-dark;
  @include box-shadow(none);
  &.input-lg {
    padding-left: $size-sm-x;
    padding-right: $size-sm-x;
  }
  &.input-sm { font-size: $font-size-small; }
}
textarea.form-control {
  height: auto;
}

.control-label {
  &.h3 {
    margin-top: 0;
    margin-bottom: $size-md-y;
  }
  @media screen and (max-width: $screen-xs-max) {
    &.h3 {
      font-size: $font-size-large;
      margin-top: $size-nm-y;
      margin-bottom: $size-nm-y;
    }
  }
}

// Help text

.help-block {
  font-style: italic;
  color: $help-block-color;
}

// iCheck - Radiobutton and Checkboxes

.iCheck-helper:before {
  content: 'x';
  opacity: 1;
}
.icheckbox,
.iradio {
  display: inline-block;
  vertical-align: middle;
  margin: 0 $size-tn-x 0 0;
  padding: 0;
  width: $input-icheck-size;
  height: $input-icheck-size;
  border: none;
  cursor: pointer;
  &:before {
    @include genicon-styles;
    @include icon-size(24);
    color: $input-border;    
  }
  &.hover:before { color: $input-icheck-hover;  }
  &.checked:before { color: $input-icheck-checked; }
  &.disabled:before { color: $input-disabled-border; }
}
.icheckbox {
  &:before { content: genicon-char(checkbox); }
  &.checked:before { content: genicon-char(checkbox-checked); }
  &-invert {
    &.checked:before { content: genicon-char(checkbox-invert-checked); }
  }
}
.iradio {
  &:before { content: genicon-char(radiobutton); }
  &.checked:before { content: genicon-char(radiobutton-checked); }
}

.radio,
.checkbox {
  margin-top: $size-nm-y;
  margin-bottom: $size-nm-y;
  @include clearfix();
  label {
    min-height: $line-height-icheck;
    line-height: $line-height-icheck; 
    padding-left: 0;
    font-size: $font-size-middle;
    color: $icheck-label-color;
    &.label-lines {
      .iradio,
      .icheckbox {
        float: left;
      }
      .label-content {
        display: inline-block;
        margin-left: $size-xs-x;
        font-size: $font-size-base;
        h4 { margin: ceil($size-tn-y / 2) 0 0 0; }
      }
    }
  }
  &-white {
    .icheckbox:before,
    .iradio:before {
      color: $icheck-white-color;
    }
    label { color: $icheck-white-color; }
  }
  &.input-sm {
    padding: 0;
    label { font-size: $font-size-small; }
  }
}

.form-inline {
  .radio,
  .icheckbox {
    margin-top: -3px;
  }
}

//== select

.select-wrapper {
  display: block;
  position: relative;
  $icon-size: 20;
  &:after {    
    position: absolute;
    top: 0;
    bottom: 0;    
    right: $padding-base-horizontal;
    @include genicon-styles;
    content: genicon-char(downward);
    @include icon-size($icon-size);
    color: $select-arrow-color;
    /* z-index: -1; */
  }
  select {
    &:not(.select2-control) {
      position: relative;
      z-index: $zindex-select;
      width: 100%;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      text-overflow: "";
      /* This hides dropdown button arrow in IE */
      &::-ms-expand { display: none; }
      /* This hides focus around selected option in FF */
      &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $black;
      }
    }
    &.select2-control {
      visibility: hidden;
    }
  }
  &.input-lg, &.input-sm { padding: 0; }
  & {
    &:after { top: floor(($input-height-base - $icon-size) / 2); }
  }
  &.input-lg {
    &:after { top: floor(($input-height-large - $icon-size) / 2); }
  }
  &.input-sm {
    select {
      padding: 0 $size-nm-x 0 $size-sm-y;
      font-size: $font-size-small;
    }
    &:after {
      @include icon-size(16);
      top: floor(($input-height-small - 16px) / 2);
      right: $size-tn-x;
    }
  }
}
.form-inline {
  .select-wrapper {
    display: inline-block;
    select {
      margin-left: 0;
      margin-right: 0;
    }
  }
  label,
  .select-wrapper,
  .btn {
    margin-right: $size-tn-x;
  }
}
.form-group-sm {
  &.row {
    label {
      margin-bottom: 0;
      line-height: $input-height-small;
    }
  }
  .select-wrapper {

  }
}

.select2 {
  &.select2-container {
    z-index: $zindex-select;
    display: block;
    position: relative;
    border-radius: $border-radius-base;
    border: 1px solid $gray-dark;
    @include box-shadow(none);
  }
  .selection {
    display: block;
    width: 100%;
    height: 40px;
    .select2-selection {
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      line-height: 1.6em;
      font-size: $font-size-middle;
      padding: $size-xs-y $size-xs-x;
      border: 0 none;
      background-color: transparent;
      box-shadow: transparent;
    }
  }
}

.select2-container--bootstrap {
  &.select2-container--open {
    z-index: $zindex-dropdown;
    display: block;
    margin-top: -1px;
    padding: $size-xs-y $size-xs-x;
    border-radius: $border-radius-base;
    border: 1px solid $gray-dark;
    background-color: $white;
    &.select2-container--below {
      padding: 0;
      margin-top: 0;
    }
  }
  &.select2-container--open .select2-selection,
  &.select2-container--focus .select2-selection {
    @include box-shadow(none);
  }
  .select2-selection--single .select2-selection__arrow { display: none; }
  .select2-results__options {
    padding-left: 0;
    cursor: pointer;
    &:before { display: none; }
    .select2-results__option {
      margin-bottom: 0;
      &--highlighted { background-color: $brand-primary; }
    }
  }
  .select2-search--dropdown {
    .select2-search__field {
      @extend .form-control;
      border-radius: $border-radius-base;
      &:focus {
        @include box-shadow(none);
        border-color: $sw2-color1;
      }
    }
  }
}



// ========== Media Queries ==========

//== print
@media print {

  .form-control {
    &, &:focus { border-color: $print-border-color; }
  }

  .form-group {
    &.has-success,
    &.has-warning,
    &.has-error,
    &.focus,
    &.focus-permanent {
      .control-label { color: $print-text-color; }
      .help-block { color: $print-border-color; }
      .form-control { border-color: $print-border-color !important; }
    }
  }


}