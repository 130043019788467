/* ------------ Colors ------------ */

.text-color {
  &.color-white { color: $color-white !important; }
  &.color-black { color: $color-black !important; }
  &.color-default { color: $brand-default !important; }
  &.color-primary { color: $brand-primary !important; }
  &.color-success { color: $brand-success !important; }
  &.color-info { color: $brand-info !important; }
  &.color-warning { color: $brand-warning !important; }
  &.color-danger { color: $brand-danger !important; }
  &.color-gray-light { color: $gray-lighter !important; }
  &.color-orange { color: $sw2-color1 !important; }
  &.sw1-color1 { color: $sw1-color1; }
  &.sw1-color2 { color: $sw1-color2; }
  &.sw1-color3 { color: $sw1-color3; }
  &.sw1-color4 { color: $sw1-color4; }
  &.sw1-color5 { color: $sw1-color5; }
  &.sw1-color6 { color: $sw1-color6; }
  &.sw2-color1 { color: $sw2-color1; }
  &.sw2-color2 { color: $sw2-color2; }
  &.sw2-color3 { color: $sw2-color3; }
  &.sw2-color4 { color: $sw2-color4; }
  &.sw2-color5 { color: $sw2-color5; }
  &.sw2-color6 { color: $sw2-color6; }
  &.sw2-color7 { color: $sw2-color7; }
  &.sw3-color1 { color: $sw3-color1; }
  &.sw3-color2 { color: $sw3-color2; }
  &.sw3-color3 { color: $sw3-color3; }
  &.sw3-color4 { color: $sw3-color4; }
  &.sw3-color5 { color: $sw3-color5; }
  &.sw3-color6 { color: $sw3-color6; }
  &.sw3-color7 { color: $sw3-color7; }
  &.sw3-color8 { color: $sw3-color8; }
  &.sw3-color9 { color: $sw3-color9; }
  &.sw3-color10 { color: $sw3-color10; }
  &.sw3-color11 { color: $sw3-color11; }
  &.sw3-color12 { color: $sw3-color12; }
  &.sw3-color13 { color: $sw3-color13; }
}
.bg-color {
  &.color-white { background-color: $color-white !important; }
  &.color-black { background-color: $color-black !important; }
  &.color-default { background-color: $brand-default !important; }
  &.color-primary { background-color: $brand-primary !important; }
  &.color-success { background-color: $brand-success !important; }
  &.color-info { background-color: $brand-info !important; }
  &.color-warning { background-color: $brand-warning !important; }
  &.color-danger { background-color: $brand-danger !important; }
  &.color-gray-light { background-color: $gray-lighter !important; }
  &.color-orange { background-color: $sw2-color1 !important; }
  &.sw1-color1 { background-color: $sw1-color1; }
  &.sw1-color2 { background-color: $sw1-color2; }
  &.sw1-color3 { background-color: $sw1-color3; }
  &.sw1-color4 { background-color: $sw1-color4; }
  &.sw1-color5 { background-color: $sw1-color5; }
  &.sw1-color6 { background-color: $sw1-color6; }
  &.sw2-color1 { background-color: $sw2-color1; }
  &.sw2-color2 { background-color: $sw2-color2; }
  &.sw2-color3 { background-color: $sw2-color3; }
  &.sw2-color4 { background-color: $sw2-color4; }
  &.sw2-color5 { background-color: $sw2-color5; }
  &.sw2-color6 { background-color: $sw2-color6; }
  &.sw2-color7 { background-color: $sw2-color7; }
  &.sw3-color1 { background-color: $sw3-color1; }
  &.sw3-color2 { background-color: $sw3-color2; }
  &.sw3-color3 { background-color: $sw3-color3; }
  &.sw3-color4 { background-color: $sw3-color4; }
  &.sw3-color5 { background-color: $sw3-color5; }
  &.sw3-color6 { background-color: $sw3-color6; }
  &.sw3-color7 { background-color: $sw3-color7; }
  &.sw3-color8 { background-color: $sw3-color8; }
  &.sw3-color9 { background-color: $sw3-color9; }
  &.sw3-color10 { background-color: $sw3-color10; }
  &.sw3-color11 { background-color: $sw3-color11; }
  &.sw3-color12 { background-color: $sw3-color12; }
  &.sw3-color13 { background-color: $sw3-color13; }
}
.border-color {
  &.color-white { border-color: $color-white !important; }
  &.color-black { border-color: $color-black !important; }
  &.color-default { border-color: $brand-default !important; }
  &.color-primary { border-color: $brand-primary !important; }
  &.color-success { border-color: $brand-success !important; }
  &.color-info { border-color: $brand-info !important; }
  &.color-warning { border-color: $brand-warning !important; }
  &.color-danger { border-color: $brand-danger !important; }
  &.color-gray-light { border-color: $gray-lighter !important; }
  &.color-orange { border-color: $sw2-color1 !important; }
  &.sw1-color1 { border-color: $sw1-color1; }
  &.sw1-color2 { border-color: $sw1-color2; }
  &.sw1-color3 { border-color: $sw1-color3; }
  &.sw1-color4 { border-color: $sw1-color4; }
  &.sw1-color5 { border-color: $sw1-color5; }
  &.sw1-color6 { border-color: $sw1-color6; }
  &.sw2-color1 { border-color: $sw2-color1; }
  &.sw2-color2 { border-color: $sw2-color2; }
  &.sw2-color3 { border-color: $sw2-color3; }
  &.sw2-color4 { border-color: $sw2-color4; }
  &.sw2-color5 { border-color: $sw2-color5; }
  &.sw2-color6 { border-color: $sw2-color6; }
  &.sw2-color7 { border-color: $sw2-color7; }
  &.sw3-color1 { border-color: $sw3-color1; }
  &.sw3-color2 { border-color: $sw3-color2; }
  &.sw3-color3 { border-color: $sw3-color3; }
  &.sw3-color4 { border-color: $sw3-color4; }
  &.sw3-color5 { border-color: $sw3-color5; }
  &.sw3-color6 { border-color: $sw3-color6; }
  &.sw3-color7 { border-color: $sw3-color7; }
  &.sw3-color8 { border-color: $sw3-color8; }
  &.sw3-color9 { border-color: $sw3-color9; }
  &.sw3-color10 { border-color: $sw3-color10; }
  &.sw3-color11 { border-color: $sw3-color11; }
  &.sw3-color12 { border-color: $sw3-color12; }
  &.sw3-color13 { border-color: $sw3-color13; }
}

/* ------------ Float ------------ */

.pull-none { float: none !important; }
.pull-left { float: left !important; }
.pull-right { float: right !important; }

.mov-up    {top:0 !important;}
.mov-left  {left:0 !important;}
.mov-down  {bottom:0 !important;}
.mov-right {right:0 !important;}

.v-0 {position: relative !important; left: 0 !important; right: 0 !important; top: 0 !important; bottom: 0 !important;}
.v-t-0 {position: relative !important; top: 0 !important;}
.v-b-0 {position: relative !important; bottom: 0 !important;}
.v-l-0 {position: relative !important; left: 0 !important;}
.v-r-0 {position: relative !important; right: 0 !important;}

/* ------------ Font ------------ */

.f-s-sm { font-size: $font-size-small !important; }
.f-s    { font-size: $font-size-base !important; }
.f-s-md { font-size: $font-size-middle !important; }
.f-s-lg { font-size: $font-size-large !important; }
.f-s-hg { font-size: $font-size-huge !important; }
.f-s-h1 { font-size: $font-size-h1 !important; }
.f-s-h2 { font-size: $font-size-h2 !important; }
.f-s-h3 { font-size: $font-size-h3 !important; }
.f-s-h4 { font-size: $font-size-h4 !important; }
.f-s-h5 { font-size: $font-size-h5 !important; }
.f-s-h6 { font-size: $font-size-h6 !important; }

.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }

.f-w-n { font-family: $font-family-base; font-weight: normal; /* font-weight: normal; */ }
.f-w-b { font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */ }
.f-w-l { font-family: $font-family-light; font-weight: normal; /* font-weight: light; */ }

/* ------------ Margin ------------ */

.m-a-tn {margin: $size-tn-y !important;}
.m-a-xs {margin: $size-xs-y !important;}
.m-a-sm {margin: $size-sm-y !important;}
.m-a    {margin: $size-nm-y !important;}
.m-a-md {margin: $size-md-y !important;}
.m-a-lg {margin: $size-lg-y !important;}
.m-a-hg {margin: $size-hg-y !important;}

.m-y-tn {margin-top: $size-tn-y !important; margin-bottom: $size-tn-y !important; }
.m-y-xs {margin-top: $size-xs-y !important; margin-bottom: $size-xs-y !important; }
.m-y-sm {margin-top: $size-sm-y !important; margin-bottom: $size-sm-y !important; }
.m-y    {margin-top: $size-nm-y !important; margin-bottom: $size-nm-y !important;  }
.m-y-md {margin-top: $size-md-y !important; margin-bottom: $size-md-y !important; }
.m-y-lg {margin-top: $size-lg-y !important; margin-bottom: $size-lg-y !important; }
.m-y-hg {margin-top: $size-hg-y !important; margin-bottom: $size-hg-y !important; }

.m-t-tn {margin-top: $size-tn-y !important; }
.m-t-xs {margin-top: $size-xs-y !important; }
.m-t-sm {margin-top: $size-sm-y !important; }
.m-t    {margin-top: $size-nm-y !important; }
.m-t-md {margin-top: $size-md-y !important; }
.m-t-lg {margin-top: $size-lg-y !important; }
.m-t-hg {margin-top: $size-hg-y !important; }

.m-b-tn {margin-bottom: $size-tn-y !important; }
.m-b-xs {margin-bottom: $size-xs-y !important; }
.m-b-sm {margin-bottom: $size-sm-y !important; }
.m-b    {margin-bottom: $size-nm-y !important; }
.m-b-md {margin-bottom: $size-md-y !important; }
.m-b-lg {margin-bottom: $size-lg-y !important; }
.m-b-hg {margin-bottom: $size-hg-y !important; }

.m-x-tn {margin-left: $size-tn-x !important; margin-right: $size-tn-x !important;}
.m-x-xs {margin-left: $size-xs-x !important; margin-right: $size-xs-x !important;}
.m-x-sm {margin-left: $size-sm-x !important; margin-right: $size-sm-x !important;}
.m-x    {margin-left: $size-nm-x !important; margin-right: $size-nm-x !important;}
.m-x-md {margin-left: $size-md-x !important; margin-right: $size-md-x !important;}
.m-x-lg {margin-left: $size-lg-x !important; margin-right: $size-lg-x !important;}
.m-x-hg {margin-left: $size-hg-x !important; margin-right: $size-hg-x !important;}

.m-l-tn {margin-left: $size-tn-x !important;}
.m-l-xs {margin-left: $size-xs-x !important;}
.m-l-sm {margin-left: $size-sm-x !important;}
.m-l    {margin-left: $size-nm-x !important;}
.m-l-md {margin-left: $size-md-x !important;}
.m-l-lg {margin-left: $size-lg-x !important;}
.m-l-hg {margin-left: $size-hg-x !important;}

.m-r-tn {margin-right: $size-tn-x !important;}
.m-r-xs {margin-right: $size-xs-x !important;}
.m-r-sm {margin-right: $size-sm-x !important;}
.m-r    {margin-right: $size-nm-x !important;}
.m-r-md {margin-right: $size-md-x !important;}
.m-r-lg {margin-right: $size-lg-x !important;}
.m-r-hg {margin-right: $size-hg-x !important;}

.m-0 {margin: 0 !important;}
.m-y-0 {margin-top: 0 !important; margin-bottom: 0 !important;}
.m-x-0 {margin-left: 0 !important; margin-right: 0 !important;}
.m-l-0 {margin-left: 0 !important;}
.m-r-0 {margin-right: 0 !important;}
.m-t-0 {margin-top: 0 !important;}
.m-b-0 {margin-bottom: 0 !important;}

.m-x-a {margin-left: auto !important; margin-right: auto !important;}

/* ------------ Padding ------------ */

.p-a-tn {padding: $size-tn-y !important;}
.p-a-xs {padding: $size-xs-y !important;}
.p-a-sm {padding: $size-sm-y !important;}
.p-a    {padding: $size-nm-y !important;}
.p-a-md {padding: $size-md-y !important;}
.p-a-lg {padding: $size-lg-y !important;}
.p-a-hg {padding: $size-hg-y !important;}

.p-y-tn {padding-top: $size-tn-y !important; padding-bottom: $size-tn-y !important;}
.p-y-xs {padding-top: $size-xs-y !important; padding-bottom: $size-xs-y !important;}
.p-y-sm {padding-top: $size-sm-y !important; padding-bottom: $size-sm-y !important;}
.p-y    {padding-top: $size-nm-y !important; padding-bottom: $size-nm-y !important;}
.p-y-md {padding-top: $size-md-y !important; padding-bottom: $size-md-y !important;}
.p-y-lg {padding-top: $size-lg-y !important; padding-bottom: $size-lg-y !important;}
.p-y-hg {padding-top: $size-hg-y !important; padding-bottom: $size-hg-y !important;}

.p-t-tn {padding-top: $size-tn-y !important;}
.p-t-xs {padding-top: $size-xs-y !important;}
.p-t-sm {padding-top: $size-sm-y !important;}
.p-t    {padding-top: $size-nm-y !important;}
.p-t-md {padding-top: $size-md-y !important;}
.p-t-lg {padding-top: $size-lg-y !important;}
.p-t-hg {padding-top: $size-hg-y !important;}

.p-b-tn {padding-bottom: $size-tn-y !important;}
.p-b-xs {padding-bottom: $size-xs-y !important;}
.p-b-sm {padding-bottom: $size-sm-y !important;}
.p-b    {padding-bottom: $size-nm-y !important;}
.p-b-md {padding-bottom: $size-md-y !important;}
.p-b-lg {padding-bottom: $size-lg-y !important;}
.p-b-hg {padding-bottom: $size-hg-y !important;}

.p-x-tn {padding-left: $size-tn-x !important; padding-right: $size-tn-x !important;}
.p-x-xs {padding-left: $size-xs-x !important; padding-right: $size-xs-x !important;}
.p-x-sm {padding-left: $size-sm-x !important; padding-right: $size-sm-x !important;}
.p-x    {padding-left: $size-nm-x !important; padding-right: $size-nm-x !important;}
.p-x-md {padding-left: $size-md-x !important; padding-right: $size-md-x !important;}
.p-x-lg {padding-left: $size-lg-x !important; padding-right: $size-lg-x !important;}
.p-x-hg {padding-left: $size-hg-x !important; padding-right: $size-hg-x !important;}

.p-l-tn {padding-left: $size-tn-x !important;}
.p-l-xs {padding-left: $size-xs-x !important;}
.p-l-sm {padding-left: $size-sm-x !important;}
.p-l    {padding-left: $size-nm-x !important;}
.p-l-md {padding-left: $size-md-x !important;}
.p-l-lg {padding-left: $size-lg-x !important;}
.p-l-hg {padding-left: $size-hg-x !important;}

.p-r-tn {padding-right: $size-tn-x !important;}
.p-r-xs {padding-right: $size-xs-x !important;}
.p-r-sm {padding-right: $size-sm-x !important;}
.p-r    {padding-right: $size-nm-x !important;}
.p-r-md {padding-right: $size-md-x !important;}
.p-r-lg {padding-right: $size-lg-x !important;}
.p-r-hg {padding-right: $size-hg-x !important;}

.p-0 {padding: 0 !important;}
.p-y-0 {padding-top: 0 !important; padding-bottom: 0 !important;}
.p-x-0 {padding-left: 0 !important; padding-right: 0 !important;}
.p-l-0 {padding-left: 0 !important;}
.p-r-0 {padding-right: 0 !important;}
.p-t-0 {padding-top: 0 !important;}
.p-b-0 {padding-bottom: 0 !important;}

/* ------------ Move ------------ */

.mov-t-auto {top: auto !important;}
.mov-t-tn {top: $size-tn-y !important;}
.mov-t-xs {top: $size-xs-y !important;}
.mov-t-sm {top: $size-sm-y !important;}
.mov-t    {top: $size-nm-y !important;}
.mov-t-md {top: $size-md-y !important;}
.mov-t-lg {top: $size-lg-y !important;}
.mov-t-hg {top: $size-hg-y !important;}
.mov-t-0  {top: 0 !important;}
.mov-t-10pr { top: 10% !important;}
.mov-t-20pr { top: 20% !important;}
.mov-t-30pr { top: 30% !important;}
.mov-t-40pr { top: 40% !important;}
.mov-t-50pr { top: 50% !important;}
.mov-t-60pr { top: 60% !important;}
.mov-t-70pr { top: 70% !important;}
.mov-t-80pr { top: 80% !important;}
.mov-t-90pr { top: 90% !important;}
.mov-t-100pr { top: 100% !important;}

.mov-b-auto {bottom: auto !important;}
.mov-b-tn {bottom: $size-tn-y !important;}
.mov-b-xs {bottom: $size-xs-y !important;}
.mov-b-sm {bottom: $size-sm-y !important;}
.mov-b    {bottom: $size-nm-y !important;}
.mov-b-md {bottom: $size-md-y !important;}
.mov-b-lg {bottom: $size-lg-y !important;}
.mov-b-hg {bottom: $size-hg-y !important;}
.mov-b-0  { bottom: 0 !important;}
.mov-b-10pr { bottom: 10% !important;}
.mov-b-20pr { bottom: 20% !important;}
.mov-b-30pr { bottom: 30% !important;}
.mov-b-40pr { bottom: 40% !important;}
.mov-b-50pr { bottom: 50% !important;}
.mov-b-60pr { bottom: 60% !important;}
.mov-b-70pr { bottom: 70% !important;}
.mov-b-80pr { bottom: 80% !important;}
.mov-b-90pr { bottom: 90% !important;}
.mov-b-100pr { bottom: 100% !important;}

.mov-l-auto {left: auto !important;}
.mov-l-tn {left: $size-tn-x !important;}
.mov-l-xs {left: $size-xs-x !important;}
.mov-l-sm {left: $size-sm-x !important;}
.mov-l    {left: $size-nm-x !important;}
.mov-l-md {left: $size-md-x !important;}
.mov-l-lg {left: $size-lg-x !important;}
.mov-l-hg {left: $size-hg-x !important;}
.mov-l-0  {left: 0 !important;}
.mov-l-10pr { left: 10% !important;}
.mov-l-20pr { left: 20% !important;}
.mov-l-30pr { left: 30% !important;}
.mov-l-40pr { left: 40% !important;}
.mov-l-50pr { left: 50% !important;}
.mov-l-60pr { left: 60% !important;}
.mov-l-70pr { left: 70% !important;}
.mov-l-80pr { left: 80% !important;}
.mov-l-90pr { left: 90% !important;}
.mov-l-100pr { left: 100% !important;}

.mov-r-auto {right: auto !important;}
.mov-r-tn {right: $size-tn-x !important;}
.mov-r-xs {right: $size-xs-x !important;}
.mov-r-sm {right: $size-sm-x !important;}
.mov-r    {right: $size-nm-x !important;}
.mov-r-md {right: $size-md-x !important;}
.mov-r-lg {right: $size-lg-x !important;}
.mov-r-hg {right: $size-hg-x !important;}
.mov-r-0  {right: 0 !important;}
.mov-r-10pr { right: 10% !important;}
.mov-r-20pr { right: 20% !important;}
.mov-r-30pr { right: 30% !important;}
.mov-r-40pr { right: 40% !important;}
.mov-r-50pr { right: 50% !important;}
.mov-r-60pr { right: 60% !important;}
.mov-r-70pr { right: 70% !important;}
.mov-r-80pr { right: 80% !important;}
.mov-r-90pr { right: 90% !important;}
.mov-r-100pr { right: 100% !important;}

.imov-t-tn {top: -$size-tn-y !important;}
.imov-t-xs {top: -$size-xs-y !important;}
.imov-t-sm {top: -$size-sm-y !important;}
.imov-t    {top: -$size-nm-y !important;}
.imov-t-md {top: -$size-md-y !important;}
.imov-t-lg {top: -$size-lg-y !important;}
.imov-t-hg {top: -$size-hg-y !important;}
.imov-t-10pr { top: -10% !important;}
.imov-t-20pr { top: -20% !important;}
.imov-t-30pr { top: -30% !important;}
.imov-t-40pr { top: -40% !important;}
.imov-t-50pr { top: -50% !important;}
.imov-t-60pr { top: -60% !important;}
.imov-t-70pr { top: -70% !important;}
.imov-t-80pr { top: -80% !important;}
.imov-t-90pr { top: -90% !important;}
.imov-t-100pr { top: -100% !important;}

.imov-b-tn {bottom: -$size-tn-y !important;}
.imov-b-xs {bottom: -$size-xs-y !important;}
.imov-b-sm {bottom: -$size-sm-y !important;}
.imov-b    {bottom: -$size-nm-y !important;}
.imov-b-md {bottom: -$size-md-y !important;}
.imov-b-lg {bottom: -$size-lg-y !important;}
.imov-b-hg {bottom: -$size-hg-y !important;}
.imov-b-10pr { bottom: -10% !important;}
.imov-b-20pr { bottom: -20% !important;}
.imov-b-30pr { bottom: -30% !important;}
.imov-b-40pr { bottom: -40% !important;}
.imov-b-50pr { bottom: -50% !important;}
.imov-b-60pr { bottom: -60% !important;}
.imov-b-70pr { bottom: -70% !important;}
.imov-b-80pr { bottom: -80% !important;}
.imov-b-90pr { bottom: -90% !important;}
.imov-b-100pr { bottom: -100% !important;}

.imov-l-tn {left: -$size-tn-x !important;}
.imov-l-xs {left: -$size-xs-x !important;}
.imov-l-sm {left: -$size-sm-x !important;}
.imov-l    {left: -$size-nm-x !important;}
.imov-l-md {left: -$size-md-x !important;}
.imov-l-lg {left: -$size-lg-x !important;}
.imov-l-hg {left: -$size-hg-x !important;}
.imov-l-10pr { left: -10% !important;}
.imov-l-20pr { left: -20% !important;}
.imov-l-30pr { left: -30% !important;}
.imov-l-40pr { left: -40% !important;}
.imov-l-50pr { left: -50% !important;}
.imov-l-60pr { left: -60% !important;}
.imov-l-70pr { left: -70% !important;}
.imov-l-80pr { left: -80% !important;}
.imov-l-90pr { left: -90% !important;}
.imov-l-100pr { left: -100% !important;}

.imov-r-tn {right: -$size-tn-x !important;}
.imov-r-xs {right: -$size-xs-x !important;}
.imov-r-sm {right: -$size-sm-x !important;}
.imov-r    {right: -$size-nm-x !important;}
.imov-r-md {right: -$size-md-x !important;}
.imov-r-lg {right: -$size-lg-x !important;}
.imov-r-hg {right: -$size-hg-x !important;}
.imov-r-10pr { right: -10% !important;}
.imov-r-20pr { right: -20% !important;}
.imov-r-30pr { right: -30% !important;}
.imov-r-40pr { right: -40% !important;}
.imov-r-50pr { right: -50% !important;}
.imov-r-60pr { right: -60% !important;}
.imov-r-70pr { right: -70% !important;}
.imov-r-80pr { right: -80% !important;}
.imov-r-90pr { right: -90% !important;}
.imov-r-100pr { right: -100% !important;}

/* ------------ Tablet ------------ */

@media screen and (min-width: $screen-tablet) and (max-width: ($screen-desktop - 1)) {

  .sm-pull-none { float: none !important; }
  .sm-pull-left { float: left !important; }
  .sm-pull-right { float: right !important; }

  /* ------------ Margin ------------ */

  .sm-m-a-tn {margin: $size-tn-y !important;}
  .sm-m-a-sm {margin: $size-sm-y !important;}
  .sm-m-a-sm {margin: $size-sm-y !important;}
  .sm-m-a    {margin: $size-nm-y !important;}
  .sm-m-a-md {margin: $size-md-y !important;}
  .sm-m-a-lg {margin: $size-lg-y !important;}
  .sm-m-a-hg {margin: $size-hg-y !important;}

  .sm-m-y-tn {margin-top: $size-tn-y !important; margin-bottom: $size-tn-y !important; }
  .sm-m-y-sm {margin-top: $size-sm-y !important; margin-bottom: $size-sm-y !important; }
  .sm-m-y-sm {margin-top: $size-sm-y !important; margin-bottom: $size-sm-y !important; }
  .sm-m-y    {margin-top: $size-nm-y !important; margin-bottom: $size-nm-y !important;  }
  .sm-m-y-md {margin-top: $size-md-y !important; margin-bottom: $size-md-y !important; }
  .sm-m-y-lg {margin-top: $size-lg-y !important; margin-bottom: $size-lg-y !important; }
  .sm-m-y-hg {margin-top: $size-hg-y !important; margin-bottom: $size-hg-y !important; }

  .sm-m-t-tn {margin-top: $size-tn-y !important; }
  .sm-m-t-sm {margin-top: $size-sm-y !important; }
  .sm-m-t-sm {margin-top: $size-sm-y !important; }
  .sm-m-t    {margin-top: $size-nm-y !important; }
  .sm-m-t-md {margin-top: $size-md-y !important; }
  .sm-m-t-lg {margin-top: $size-lg-y !important; }
  .sm-m-t-hg {margin-top: $size-hg-y !important; }

  .sm-m-b-tn {margin-bottom: $size-tn-y !important; }
  .sm-m-b-sm {margin-bottom: $size-sm-y !important; }
  .sm-m-b-sm {margin-bottom: $size-sm-y !important; }
  .sm-m-b    {margin-bottom: $size-nm-y !important; }
  .sm-m-b-md {margin-bottom: $size-md-y !important; }
  .sm-m-b-lg {margin-bottom: $size-lg-y !important; }
  .sm-m-b-hg {margin-bottom: $size-hg-y !important; }

  .sm-m-x-tn {margin-left: $size-tn-x !important; margin-right: $size-tn-x !important;}
  .sm-m-x-sm {margin-left: $size-sm-x !important; margin-right: $size-sm-x !important;}
  .sm-m-x-sm {margin-left: $size-sm-x !important; margin-right: $size-sm-x !important;}
  .sm-m-x    {margin-left: $size-nm-x !important; margin-right: $size-nm-x !important;}
  .sm-m-x-md {margin-left: $size-md-x !important; margin-right: $size-md-x !important;}
  .sm-m-x-lg {margin-left: $size-lg-x !important; margin-right: $size-lg-x !important;}
  .sm-m-x-hg {margin-left: $size-hg-x !important; margin-right: $size-hg-x !important;}

  .sm-m-l-tn {margin-left: $size-tn-x !important;}
  .sm-m-l-sm {margin-left: $size-sm-x !important;}
  .sm-m-l-sm {margin-left: $size-sm-x !important;}
  .sm-m-l    {margin-left: $size-nm-x !important;}
  .sm-m-l-md {margin-left: $size-md-x !important;}
  .sm-m-l-lg {margin-left: $size-lg-x !important;}
  .sm-m-l-hg {margin-left: $size-hg-x !important;}

  .sm-m-r-tn {margin-right: $size-tn-x !important;}
  .sm-m-r-sm {margin-right: $size-sm-x !important;}
  .sm-m-r-sm {margin-right: $size-sm-x !important;}
  .sm-m-r    {margin-right: $size-nm-x !important;}
  .sm-m-r-md {margin-right: $size-md-x !important;}
  .sm-m-r-lg {margin-right: $size-lg-x !important;}
  .sm-m-r-hg {margin-right: $size-hg-x !important;}

  .sm-m-0 {margin: 0 !important;}
  .sm-m-y-0 {margin-top: 0 !important; margin-bottom: 0 !important;}
  .sm-m-x-0 {margin-left: 0 !important; margin-right: 0 !important;}
  .sm-m-l-0 {margin-left: 0 !important;}
  .sm-m-r-0 {margin-right: 0 !important;}
  .sm-m-t-0 {margin-top: 0 !important;}
  .sm-m-b-0 {margin-bottom: 0 !important;}

  .sm-m-x-a {margin-left: auto !important; margin-right: auto !important;}

  /* ------------ Padding ------------ */

  .sm-p-a-tn {padding: $size-tn-y !important;}
  .sm-p-a-sm {padding: $size-sm-y !important;}
  .sm-p-a-sm {padding: $size-sm-y !important;}
  .sm-p-a    {padding: $size-nm-y !important;}
  .sm-p-a-md {padding: $size-md-y !important;}
  .sm-p-a-lg {padding: $size-lg-y !important;}
  .sm-p-a-hg {padding: $size-hg-y !important;}

  .sm-p-y-tn {padding-top: $size-tn-y !important; padding-bottom: $size-tn-y !important;}
  .sm-p-y-sm {padding-top: $size-sm-y !important; padding-bottom: $size-sm-y !important;}
  .sm-p-y-sm {padding-top: $size-sm-y !important; padding-bottom: $size-sm-y !important;}
  .sm-p-y    {padding-top: $size-nm-y !important; padding-bottom: $size-nm-y !important;}
  .sm-p-y-md {padding-top: $size-md-y !important; padding-bottom: $size-md-y !important;}
  .sm-p-y-lg {padding-top: $size-lg-y !important; padding-bottom: $size-lg-y !important;}
  .sm-p-y-hg {padding-top: $size-hg-y !important; padding-bottom: $size-hg-y !important;}

  .sm-p-t-tn {padding-top: $size-tn-y !important;}
  .sm-p-t-sm {padding-top: $size-sm-y !important;}
  .sm-p-t-sm {padding-top: $size-sm-y !important;}
  .sm-p-t    {padding-top: $size-nm-y !important;}
  .sm-p-t-md {padding-top: $size-md-y !important;}
  .sm-p-t-lg {padding-top: $size-lg-y !important;}
  .sm-p-t-hg {padding-top: $size-hg-y !important;}

  .sm-p-b-tn {padding-bottom: $size-tn-y !important;}
  .sm-p-b-sm {padding-bottom: $size-sm-y !important;}
  .sm-p-b-sm {padding-bottom: $size-sm-y !important;}
  .sm-p-b    {padding-bottom: $size-nm-y !important;}
  .sm-p-b-md {padding-bottom: $size-md-y !important;}
  .sm-p-b-lg {padding-bottom: $size-lg-y !important;}
  .sm-p-b-hg {padding-bottom: $size-hg-y !important;}

  .sm-p-x-tn {padding-left: $size-tn-x !important; padding-right: $size-tn-x !important;}
  .sm-p-x-sm {padding-left: $size-sm-x !important; padding-right: $size-sm-x !important;}
  .sm-p-x-sm {padding-left: $size-sm-x !important; padding-right: $size-sm-x !important;}
  .sm-p-x    {padding-left: $size-nm-x !important; padding-right: $size-nm-x !important;}
  .sm-p-x-md {padding-left: $size-md-x !important; padding-right: $size-md-x !important;}
  .sm-p-x-lg {padding-left: $size-lg-x !important; padding-right: $size-lg-x !important;}
  .sm-p-x-hg {padding-left: $size-hg-x !important; padding-right: $size-hg-x !important;}

  .sm-p-l-tn {padding-left: $size-tn-x !important;}
  .sm-p-l-sm {padding-left: $size-sm-x !important;}
  .sm-p-l-sm {padding-left: $size-sm-x !important;}
  .sm-p-l    {padding-left: $size-nm-x !important;}
  .sm-p-l-md {padding-left: $size-md-x !important;}
  .sm-p-l-lg {padding-left: $size-lg-x !important;}
  .sm-p-l-hg {padding-left: $size-hg-x !important;}

  .sm-p-r-tn {padding-right: $size-tn-x !important;}
  .sm-p-r-sm {padding-right: $size-sm-x !important;}
  .sm-p-r-sm {padding-right: $size-sm-x !important;}
  .sm-p-r    {padding-right: $size-nm-x !important;}
  .sm-p-r-md {padding-right: $size-md-x !important;}
  .sm-p-r-lg {padding-right: $size-lg-x !important;}
  .sm-p-r-hg {padding-right: $size-hg-x !important;}

  .sm-p-0 {padding: 0 !important;}
  .sm-p-y-0 {padding-top: 0 !important; padding-bottom: 0 !important;}
  .sm-p-x-0 {padding-left: 0 !important; padding-right: 0 !important;}
  .sm-p-l-0 {padding-left: 0 !important;}
  .sm-p-r-0 {padding-right: 0 !important;}
  .sm-p-t-0 {padding-top: 0 !important;}
  .sm-p-b-0 {padding-bottom: 0 !important;}

  /* ------------ Move ------------ */

  .sm-mov-t-auto {top: auto !important;}
  .sm-mov-t-tn {top: $size-tn-y !important;}
  .sm-mov-t-sm {top: $size-sm-y !important;}
  .sm-mov-t-sm {top: $size-sm-y !important;}
  .sm-mov-t    {top: $size-nm-y !important;}
  .sm-mov-t-md {top: $size-md-y !important;}
  .sm-mov-t-lg {top: $size-lg-y !important;}
  .sm-mov-t-hg {top: $size-hg-y !important;}
  .sm-mov-t-10pr { top: 10% !important;}
  .sm-mov-t-20pr { top: 20% !important;}
  .sm-mov-t-30pr { top: 30% !important;}
  .sm-mov-t-40pr { top: 40% !important;}
  .sm-mov-t-50pr { top: 50% !important;}
  .sm-mov-t-60pr { top: 60% !important;}
  .sm-mov-t-70pr { top: 70% !important;}
  .sm-mov-t-80pr { top: 80% !important;}
  .sm-mov-t-90pr { top: 90% !important;}
  .sm-mov-t-100pr { top: 100% !important;}

  .sm-mov-b-auto {bottom: auto !important;}
  .sm-mov-b-tn {bottom: $size-tn-y !important;}
  .sm-mov-b-sm {bottom: $size-sm-y !important;}
  .sm-mov-b-sm {bottom: $size-sm-y !important;}
  .sm-mov-b    {bottom: $size-nm-y !important;}
  .sm-mov-b-md {bottom: $size-md-y !important;}
  .sm-mov-b-lg {bottom: $size-lg-y !important;}
  .sm-mov-b-hg {bottom: $size-hg-y !important;}
  .sm-mov-b-10pr { bottom: 10% !important;}
  .sm-mov-b-20pr { bottom: 20% !important;}
  .sm-mov-b-30pr { bottom: 30% !important;}
  .sm-mov-b-40pr { bottom: 40% !important;}
  .sm-mov-b-50pr { bottom: 50% !important;}
  .sm-mov-b-60pr { bottom: 60% !important;}
  .sm-mov-b-70pr { bottom: 70% !important;}
  .sm-mov-b-80pr { bottom: 80% !important;}
  .sm-mov-b-90pr { bottom: 90% !important;}
  .sm-mov-b-100pr { bottom: 100% !important;}

  .sm-mov-l-auto {left: auto !important;}
  .sm-mov-l-tn {left: $size-tn-x !important;}
  .sm-mov-l-sm {left: $size-sm-x !important;}
  .sm-mov-l-sm {left: $size-sm-x !important;}
  .sm-mov-l    {left: $size-nm-x !important;}
  .sm-mov-l-md {left: $size-md-x !important;}
  .sm-mov-l-lg {left: $size-lg-x !important;}
  .sm-mov-l-hg {left: $size-hg-x !important;}
  .sm-mov-l-10pr { left: 10% !important;}
  .sm-mov-l-20pr { left: 20% !important;}
  .sm-mov-l-30pr { left: 30% !important;}
  .sm-mov-l-40pr { left: 40% !important;}
  .sm-mov-l-50pr { left: 50% !important;}
  .sm-mov-l-60pr { left: 60% !important;}
  .sm-mov-l-70pr { left: 70% !important;}
  .sm-mov-l-80pr { left: 80% !important;}
  .sm-mov-l-90pr { left: 90% !important;}
  .sm-mov-l-100pr { left: 100% !important;}

  .sm-mov-r-auto {right: auto !important;}
  .sm-mov-r-tn {right: $size-tn-x !important;}
  .sm-mov-r-sm {right: $size-sm-x !important;}
  .sm-mov-r-sm {right: $size-sm-x !important;}
  .sm-mov-r    {right: $size-nm-x !important;}
  .sm-mov-r-md {right: $size-md-x !important;}
  .sm-mov-r-lg {right: $size-lg-x !important;}
  .sm-mov-r-hg {right: $size-hg-x !important;}
  .sm-mov-r-10pr { right: 10% !important;}
  .sm-mov-r-20pr { right: 20% !important;}
  .sm-mov-r-30pr { right: 30% !important;}
  .sm-mov-r-40pr { right: 40% !important;}
  .sm-mov-r-50pr { right: 50% !important;}
  .sm-mov-r-60pr { right: 60% !important;}
  .sm-mov-r-70pr { right: 70% !important;}
  .sm-mov-r-80pr { right: 80% !important;}
  .sm-mov-r-90pr { right: 90% !important;}
  .sm-mov-r-100pr { right: 100% !important;}

  .sm-imov-t-tn {top: -$size-tn-y !important;}
  .sm-imov-t-sm {top: -$size-sm-y !important;}
  .sm-imov-t-sm {top: -$size-sm-y !important;}
  .sm-imov-t    {top: -$size-nm-y !important;}
  .sm-imov-t-md {top: -$size-md-y !important;}
  .sm-imov-t-lg {top: -$size-lg-y !important;}
  .sm-imov-t-hg {top: -$size-hg-y !important;}
  .sm-imov-t-10pr { top: -10% !important;}
  .sm-imov-t-20pr { top: -20% !important;}
  .sm-imov-t-30pr { top: -30% !important;}
  .sm-imov-t-40pr { top: -40% !important;}
  .sm-imov-t-50pr { top: -50% !important;}
  .sm-imov-t-60pr { top: -60% !important;}
  .sm-imov-t-70pr { top: -70% !important;}
  .sm-imov-t-80pr { top: -80% !important;}
  .sm-imov-t-90pr { top: -90% !important;}
  .sm-imov-t-100pr { top: -100% !important;}

  .sm-imov-b-tn {bottom: -$size-tn-y !important;}
  .sm-imov-b-sm {bottom: -$size-sm-y !important;}
  .sm-imov-b-sm {bottom: -$size-sm-y !important;}
  .sm-imov-b    {bottom: -$size-nm-y !important;}
  .sm-imov-b-md {bottom: -$size-md-y !important;}
  .sm-imov-b-lg {bottom: -$size-lg-y !important;}
  .sm-imov-b-hg {bottom: -$size-hg-y !important;}
  .sm-imov-b-10pr { bottom: -10% !important;}
  .sm-imov-b-20pr { bottom: -20% !important;}
  .sm-imov-b-30pr { bottom: -30% !important;}
  .sm-imov-b-40pr { bottom: -40% !important;}
  .sm-imov-b-50pr { bottom: -50% !important;}
  .sm-imov-b-60pr { bottom: -60% !important;}
  .sm-imov-b-70pr { bottom: -70% !important;}
  .sm-imov-b-80pr { bottom: -80% !important;}
  .sm-imov-b-90pr { bottom: -90% !important;}
  .sm-imov-b-100pr { bottom: -100% !important;}

  .sm-imov-l-tn {left: -$size-tn-x !important;}
  .sm-imov-l-sm {left: -$size-sm-x !important;}
  .sm-imov-l-sm {left: -$size-sm-x !important;}
  .sm-imov-l    {left: -$size-nm-x !important;}
  .sm-imov-l-md {left: -$size-md-x !important;}
  .sm-imov-l-lg {left: -$size-lg-x !important;}
  .sm-imov-l-hg {left: -$size-hg-x !important;}
  .sm-imov-l-10pr { left: -10% !important;}
  .sm-imov-l-20pr { left: -20% !important;}
  .sm-imov-l-30pr { left: -30% !important;}
  .sm-imov-l-40pr { left: -40% !important;}
  .sm-imov-l-50pr { left: -50% !important;}
  .sm-imov-l-60pr { left: -60% !important;}
  .sm-imov-l-70pr { left: -70% !important;}
  .sm-imov-l-80pr { left: -80% !important;}
  .sm-imov-l-90pr { left: -90% !important;}
  .sm-imov-l-100pr { left: -100% !important;}

  .sm-imov-r-tn {right: -$size-tn-x !important;}
  .sm-imov-r-sm {right: -$size-sm-x !important;}
  .sm-imov-r-sm {right: -$size-sm-x !important;}
  .sm-imov-r    {right: -$size-nm-x !important;}
  .sm-imov-r-md {right: -$size-md-x !important;}
  .sm-imov-r-lg {right: -$size-lg-x !important;}
  .sm-imov-r-hg {right: -$size-hg-x !important;}
  .sm-imov-r-10pr { right: -10% !important;}
  .sm-imov-r-20pr { right: -20% !important;}
  .sm-imov-r-30pr { right: -30% !important;}
  .sm-imov-r-40pr { right: -40% !important;}
  .sm-imov-r-50pr { right: -50% !important;}
  .sm-imov-r-60pr { right: -60% !important;}
  .sm-imov-r-70pr { right: -70% !important;}
  .sm-imov-r-80pr { right: -80% !important;}
  .sm-imov-r-90pr { right: -90% !important;}
  .sm-imov-r-100pr { right: -100% !important;}

  /* ------------ Font ------------ */

  .sm-f-s-sm { font-size: $font-size-small !important; }
  .sm-f-s    { font-size: $font-size-base !important; }
  .sm-f-s-md { font-size: $font-size-middle !important; }
  .sm-f-s-lg { font-size: $font-size-large !important; }
  .sm-f-s-hg { font-size: $font-size-huge !important; }
  .sm-f-s-h1 { font-size: $font-size-h1 !important; }
  .sm-f-s-h2 { font-size: $font-size-h2 !important; }
  .sm-f-s-h3 { font-size: $font-size-h3 !important; }
  .sm-f-s-h4 { font-size: $font-size-h4 !important; }
  .sm-f-s-h5 { font-size: $font-size-h5 !important; }
  .sm-f-s-h6 { font-size: $font-size-h6 !important; }

  .sm-text-left { text-align: left !important; }
  .sm-text-right { text-align: right !important; }
  .sm-text-center { text-align: center !important; }

  .sm-f-w-n { font-family: $font-family-base; font-weight: normal; /* font-weight: normal; */ }
  .sm-f-w-b { font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */ }
  .sm-f-w-l { font-family: $font-family-light; font-weight: normal; /* font-weight: light; */ }

}

/* ------------ Phone ------------ */

@media screen and (max-width: $screen-xs-max) {

  .xs-pull-none { float: none !important; }
  .xs-pull-left { float: left !important; }
  .xs-pull-right { float: right !important; }

  /* ------------ Margin ------------ */

  .xs-m-a-tn {margin: $size-tn-y !important;}
  .xs-m-a-xs {margin: $size-xs-y !important;}
  .xs-m-a-sm {margin: $size-sm-y !important;}
  .xs-m-a    {margin: $size-nm-y !important;}
  .xs-m-a-md {margin: $size-md-y !important;}
  .xs-m-a-lg {margin: $size-lg-y !important;}
  .xs-m-a-hg {margin: $size-hg-y !important;}

  .xs-m-y-tn {margin-top: $size-tn-y !important; margin-bottom: $size-tn-y !important; }
  .xs-m-y-xs {margin-top: $size-xs-y !important; margin-bottom: $size-xs-y !important; }
  .xs-m-y-sm {margin-top: $size-sm-y !important; margin-bottom: $size-sm-y !important; }
  .xs-m-y    {margin-top: $size-nm-y !important; margin-bottom: $size-nm-y !important;  }
  .xs-m-y-md {margin-top: $size-md-y !important; margin-bottom: $size-md-y !important; }
  .xs-m-y-lg {margin-top: $size-lg-y !important; margin-bottom: $size-lg-y !important; }
  .xs-m-y-hg {margin-top: $size-hg-y !important; margin-bottom: $size-hg-y !important; }

  .xs-m-t-tn {margin-top: $size-tn-y !important; }
  .xs-m-t-xs {margin-top: $size-xs-y !important; }
  .xs-m-t-sm {margin-top: $size-sm-y !important; }
  .xs-m-t    {margin-top: $size-nm-y !important; }
  .xs-m-t-md {margin-top: $size-md-y !important; }
  .xs-m-t-lg {margin-top: $size-lg-y !important; }
  .xs-m-t-hg {margin-top: $size-hg-y !important; }

  .xs-m-b-tn {margin-bottom: $size-tn-y !important; }
  .xs-m-b-xs {margin-bottom: $size-xs-y !important; }
  .xs-m-b-sm {margin-bottom: $size-sm-y !important; }
  .xs-m-b    {margin-bottom: $size-nm-y !important; }
  .xs-m-b-md {margin-bottom: $size-md-y !important; }
  .xs-m-b-lg {margin-bottom: $size-lg-y !important; }
  .xs-m-b-hg {margin-bottom: $size-hg-y !important; }

  .xs-m-x-tn {margin-left: $size-tn-x !important; margin-right: $size-tn-x !important;}
  .xs-m-x-xs {margin-left: $size-xs-x !important; margin-right: $size-xs-x !important;}
  .xs-m-x-sm {margin-left: $size-sm-x !important; margin-right: $size-sm-x !important;}
  .xs-m-x    {margin-left: $size-nm-x !important; margin-right: $size-nm-x !important;}
  .xs-m-x-md {margin-left: $size-md-x !important; margin-right: $size-md-x !important;}
  .xs-m-x-lg {margin-left: $size-lg-x !important; margin-right: $size-lg-x !important;}
  .xs-m-x-hg {margin-left: $size-hg-x !important; margin-right: $size-hg-x !important;}

  .xs-m-l-tn {margin-left: $size-tn-x !important;}
  .xs-m-l-xs {margin-left: $size-xs-x !important;}
  .xs-m-l-sm {margin-left: $size-sm-x !important;}
  .xs-m-l    {margin-left: $size-nm-x !important;}
  .xs-m-l-md {margin-left: $size-md-x !important;}
  .xs-m-l-lg {margin-left: $size-lg-x !important;}
  .xs-m-l-hg {margin-left: $size-hg-x !important;}

  .xs-m-r-tn {margin-right: $size-tn-x !important;}
  .xs-m-r-xs {margin-right: $size-xs-x !important;}
  .xs-m-r-sm {margin-right: $size-sm-x !important;}
  .xs-m-r    {margin-right: $size-nm-x !important;}
  .xs-m-r-md {margin-right: $size-md-x !important;}
  .xs-m-r-lg {margin-right: $size-lg-x !important;}
  .xs-m-r-hg {margin-right: $size-hg-x !important;}

  .xs-m-0 {margin: 0 !important;}
  .xs-m-y-0 {margin-top: 0 !important; margin-bottom: 0 !important;}
  .xs-m-x-0 {margin-left: 0 !important; margin-right: 0 !important;}
  .xs-m-l-0 {margin-left: 0 !important;}
  .xs-m-r-0 {margin-right: 0 !important;}
  .xs-m-t-0 {margin-top: 0 !important;}
  .xs-m-b-0 {margin-bottom: 0 !important;}

  .xs-m-x-a {margin-left: auto !important; margin-right: auto !important;}

  /* ------------ Padding ------------ */

  .xs-p-a-tn {padding: $size-tn-y !important;}
  .xs-p-a-xs {padding: $size-xs-y !important;}
  .xs-p-a-sm {padding: $size-sm-y !important;}
  .xs-p-a    {padding: $size-nm-y !important;}
  .xs-p-a-md {padding: $size-md-y !important;}
  .xs-p-a-lg {padding: $size-lg-y !important;}
  .xs-p-a-hg {padding: $size-hg-y !important;}

  .xs-p-y-tn {padding-top: $size-tn-y !important; padding-bottom: $size-tn-y !important;}
  .xs-p-y-xs {padding-top: $size-xs-y !important; padding-bottom: $size-xs-y !important;}
  .xs-p-y-sm {padding-top: $size-sm-y !important; padding-bottom: $size-sm-y !important;}
  .xs-p-y    {padding-top: $size-nm-y !important; padding-bottom: $size-nm-y !important;}
  .xs-p-y-md {padding-top: $size-md-y !important; padding-bottom: $size-md-y !important;}
  .xs-p-y-lg {padding-top: $size-lg-y !important; padding-bottom: $size-lg-y !important;}
  .xs-p-y-hg {padding-top: $size-hg-y !important; padding-bottom: $size-hg-y !important;}

  .xs-p-t-tn {padding-top: $size-tn-y !important;}
  .xs-p-t-xs {padding-top: $size-xs-y !important;}
  .xs-p-t-sm {padding-top: $size-sm-y !important;}
  .xs-p-t    {padding-top: $size-nm-y !important;}
  .xs-p-t-md {padding-top: $size-md-y !important;}
  .xs-p-t-lg {padding-top: $size-lg-y !important;}
  .xs-p-t-hg {padding-top: $size-hg-y !important;}

  .xs-p-b-tn {padding-bottom: $size-tn-y !important;}
  .xs-p-b-xs {padding-bottom: $size-xs-y !important;}
  .xs-p-b-sm {padding-bottom: $size-sm-y !important;}
  .xs-p-b    {padding-bottom: $size-nm-y !important;}
  .xs-p-b-md {padding-bottom: $size-md-y !important;}
  .xs-p-b-lg {padding-bottom: $size-lg-y !important;}
  .xs-p-b-hg {padding-bottom: $size-hg-y !important;}

  .xs-p-x-tn {padding-left: $size-tn-x !important; padding-right: $size-tn-x !important;}
  .xs-p-x-xs {padding-left: $size-xs-x !important; padding-right: $size-xs-x !important;}
  .xs-p-x-sm {padding-left: $size-sm-x !important; padding-right: $size-sm-x !important;}
  .xs-p-x    {padding-left: $size-nm-x !important; padding-right: $size-nm-x !important;}
  .xs-p-x-md {padding-left: $size-md-x !important; padding-right: $size-md-x !important;}
  .xs-p-x-lg {padding-left: $size-lg-x !important; padding-right: $size-lg-x !important;}
  .xs-p-x-hg {padding-left: $size-hg-x !important; padding-right: $size-hg-x !important;}

  .xs-p-l-tn {padding-left: $size-tn-x !important;}
  .xs-p-l-xs {padding-left: $size-xs-x !important;}
  .xs-p-l-sm {padding-left: $size-sm-x !important;}
  .xs-p-l    {padding-left: $size-nm-x !important;}
  .xs-p-l-md {padding-left: $size-md-x !important;}
  .xs-p-l-lg {padding-left: $size-lg-x !important;}
  .xs-p-l-hg {padding-left: $size-hg-x !important;}

  .xs-p-r-tn {padding-right: $size-tn-x !important;}
  .xs-p-r-xs {padding-right: $size-xs-x !important;}
  .xs-p-r-sm {padding-right: $size-sm-x !important;}
  .xs-p-r    {padding-right: $size-nm-x !important;}
  .xs-p-r-md {padding-right: $size-md-x !important;}
  .xs-p-r-lg {padding-right: $size-lg-x !important;}
  .xs-p-r-hg {padding-right: $size-hg-x !important;}

  .xs-p-0 {padding: 0 !important;}
  .xs-p-y-0 {padding-top: 0 !important; padding-bottom: 0 !important;}
  .xs-p-x-0 {padding-left: 0 !important; padding-right: 0 !important;}
  .xs-p-l-0 {padding-left: 0 !important;}
  .xs-p-r-0 {padding-right: 0 !important;}
  .xs-p-t-0 {padding-top: 0 !important;}
  .xs-p-b-0 {padding-bottom: 0 !important;}

  /* ------------ Move ------------ */

  .xs-mov-t-auto {top: auto !important;}
  .xs-mov-t-tn {top: $size-tn-y !important;}
  .xs-mov-t-xs {top: $size-xs-y !important;}
  .xs-mov-t-sm {top: $size-sm-y !important;}
  .xs-mov-t    {top: $size-nm-y !important;}
  .xs-mov-t-md {top: $size-md-y !important;}
  .xs-mov-t-lg {top: $size-lg-y !important;}
  .xs-mov-t-hg {top: $size-hg-y !important;}
  .xs-mov-t-10pr { top: 10% !important;}
  .xs-mov-t-20pr { top: 20% !important;}
  .xs-mov-t-30pr { top: 30% !important;}
  .xs-mov-t-40pr { top: 40% !important;}
  .xs-mov-t-50pr { top: 50% !important;}
  .xs-mov-t-60pr { top: 60% !important;}
  .xs-mov-t-70pr { top: 70% !important;}
  .xs-mov-t-80pr { top: 80% !important;}
  .xs-mov-t-90pr { top: 90% !important;}
  .xs-mov-t-100pr { top: 100% !important;}
  .xs-mov-t-110pr { top: 110% !important;}
  .xs-mov-t-120pr { top: 120% !important;}
  .xs-mov-t-130pr { top: 130% !important;}
  .xs-mov-t-140pr { top: 140% !important;}
  .xs-mov-t-150pr { top: 150% !important;}

  .xs-mov-b-auto {bottom: auto !important;}
  .xs-mov-b-tn {bottom: $size-tn-y !important;}
  .xs-mov-b-xs {bottom: $size-xs-y !important;}
  .xs-mov-b-sm {bottom: $size-sm-y !important;}
  .xs-mov-b    {bottom: $size-nm-y !important;}
  .xs-mov-b-md {bottom: $size-md-y !important;}
  .xs-mov-b-lg {bottom: $size-lg-y !important;}
  .xs-mov-b-hg {bottom: $size-hg-y !important;}
  .xs-mov-b-10pr { bottom: 10% !important;}
  .xs-mov-b-20pr { bottom: 20% !important;}
  .xs-mov-b-30pr { bottom: 30% !important;}
  .xs-mov-b-40pr { bottom: 40% !important;}
  .xs-mov-b-50pr { bottom: 50% !important;}
  .xs-mov-b-60pr { bottom: 60% !important;}
  .xs-mov-b-70pr { bottom: 70% !important;}
  .xs-mov-b-80pr { bottom: 80% !important;}
  .xs-mov-b-90pr { bottom: 90% !important;}
  .xs-mov-b-100pr { bottom: 100% !important;}
  .xs-mov-b-110pr { bottom: 110% !important;}
  .xs-mov-b-120pr { bottom: 120% !important;}
  .xs-mov-b-130pr { bottom: 130% !important;}
  .xs-mov-b-140pr { bottom: 140% !important;}
  .xs-mov-b-150pr { bottom: 150% !important;}

  .xs-mov-l-auto {left: auto !important;}
  .xs-mov-l-tn {left: $size-tn-x !important;}
  .xs-mov-l-xs {left: $size-xs-x !important;}
  .xs-mov-l-sm {left: $size-sm-x !important;}
  .xs-mov-l    {left: $size-nm-x !important;}
  .xs-mov-l-md {left: $size-md-x !important;}
  .xs-mov-l-lg {left: $size-lg-x !important;}
  .xs-mov-l-hg {left: $size-hg-x !important;}
  .xs-mov-l-10pr { left: 10% !important;}
  .xs-mov-l-20pr { left: 20% !important;}
  .xs-mov-l-30pr { left: 30% !important;}
  .xs-mov-l-40pr { left: 40% !important;}
  .xs-mov-l-50pr { left: 50% !important;}
  .xs-mov-l-60pr { left: 60% !important;}
  .xs-mov-l-70pr { left: 70% !important;}
  .xs-mov-l-80pr { left: 80% !important;}
  .xs-mov-l-90pr { left: 90% !important;}
  .xs-mov-l-100pr { left: 100% !important;}
  .xs-mov-l-110pr { left: 110% !important;}
  .xs-mov-l-120pr { left: 120% !important;}
  .xs-mov-l-130pr { left: 130% !important;}
  .xs-mov-l-140pr { left: 140% !important;}
  .xs-mov-l-150pr { left: 150% !important;}

  .xs-mov-r-auto {right: auto !important;}
  .xs-mov-r-tn {right: $size-tn-x !important;}
  .xs-mov-r-xs {right: $size-xs-x !important;}
  .xs-mov-r-sm {right: $size-sm-x !important;}
  .xs-mov-r    {right: $size-nm-x !important;}
  .xs-mov-r-md {right: $size-md-x !important;}
  .xs-mov-r-lg {right: $size-lg-x !important;}
  .xs-mov-r-hg {right: $size-hg-x !important;}
  .xs-mov-r-10pr { right: 10% !important;}
  .xs-mov-r-20pr { right: 20% !important;}
  .xs-mov-r-30pr { right: 30% !important;}
  .xs-mov-r-40pr { right: 40% !important;}
  .xs-mov-r-50pr { right: 50% !important;}
  .xs-mov-r-60pr { right: 60% !important;}
  .xs-mov-r-70pr { right: 70% !important;}
  .xs-mov-r-80pr { right: 80% !important;}
  .xs-mov-r-90pr { right: 90% !important;}
  .xs-mov-r-100pr { right: 100% !important;}
  .xs-mov-r-110pr { right: 110% !important;}
  .xs-mov-r-120pr { right: 120% !important;}
  .xs-mov-r-130pr { right: 130% !important;}
  .xs-mov-r-140pr { right: 140% !important;}
  .xs-mov-r-150pr { right: 150% !important;}

  .xs-imov-t-tn {top: -$size-tn-y !important;}
  .xs-imov-t-xs {top: -$size-xs-y !important;}
  .xs-imov-t-sm {top: -$size-sm-y !important;}
  .xs-imov-t    {top: -$size-nm-y !important;}
  .xs-imov-t-md {top: -$size-md-y !important;}
  .xs-imov-t-lg {top: -$size-lg-y !important;}
  .xs-imov-t-hg {top: -$size-hg-y !important;}
  .xs-imov-t-10pr { top: -10% !important;}
  .xs-imov-t-20pr { top: -20% !important;}
  .xs-imov-t-30pr { top: -30% !important;}
  .xs-imov-t-40pr { top: -40% !important;}
  .xs-imov-t-50pr { top: -50% !important;}
  .xs-imov-t-60pr { top: -60% !important;}
  .xs-imov-t-70pr { top: -70% !important;}
  .xs-imov-t-80pr { top: -80% !important;}
  .xs-imov-t-90pr { top: -90% !important;}
  .xs-imov-t-100pr { top: -100% !important;}
  .xs-imov-t-110pr { top: -110% !important;}
  .xs-imov-t-120pr { top: -120% !important;}
  .xs-imov-t-130pr { top: -130% !important;}
  .xs-imov-t-140pr { top: -140% !important;}
  .xs-imov-t-150pr { top: -150% !important;}

  .xs-imov-b-tn {bottom: -$size-tn-y !important;}
  .xs-imov-b-xs {bottom: -$size-xs-y !important;}
  .xs-imov-b-sm {bottom: -$size-sm-y !important;}
  .xs-imov-b    {bottom: -$size-nm-y !important;}
  .xs-imov-b-md {bottom: -$size-md-y !important;}
  .xs-imov-b-lg {bottom: -$size-lg-y !important;}
  .xs-imov-b-hg {bottom: -$size-hg-y !important;}
  .xs-imov-b-10pr { bottom: -10% !important;}
  .xs-imov-b-20pr { bottom: -20% !important;}
  .xs-imov-b-30pr { bottom: -30% !important;}
  .xs-imov-b-40pr { bottom: -40% !important;}
  .xs-imov-b-50pr { bottom: -50% !important;}
  .xs-imov-b-60pr { bottom: -60% !important;}
  .xs-imov-b-70pr { bottom: -70% !important;}
  .xs-imov-b-80pr { bottom: -80% !important;}
  .xs-imov-b-90pr { bottom: -90% !important;}
  .xs-imov-b-100pr { bottom: -100% !important;}
  .xs-imov-b-110pr { bottom: -110% !important;}
  .xs-imov-b-120pr { bottom: -120% !important;}
  .xs-imov-b-130pr { bottom: -130% !important;}
  .xs-imov-b-140pr { bottom: -140% !important;}
  .xs-imov-b-150pr { bottom: -150% !important;}

  .xs-imov-l-tn {left: -$size-tn-x !important;}
  .xs-imov-l-xs {left: -$size-xs-x !important;}
  .xs-imov-l-sm {left: -$size-sm-x !important;}
  .xs-imov-l    {left: -$size-nm-x !important;}
  .xs-imov-l-md {left: -$size-md-x !important;}
  .xs-imov-l-lg {left: -$size-lg-x !important;}
  .xs-imov-l-hg {left: -$size-hg-x !important;}
  .xs-imov-l-10pr { left: -10% !important;}
  .xs-imov-l-20pr { left: -20% !important;}
  .xs-imov-l-30pr { left: -30% !important;}
  .xs-imov-l-40pr { left: -40% !important;}
  .xs-imov-l-50pr { left: -50% !important;}
  .xs-imov-l-60pr { left: -60% !important;}
  .xs-imov-l-70pr { left: -70% !important;}
  .xs-imov-l-80pr { left: -80% !important;}
  .xs-imov-l-90pr { left: -90% !important;}
  .xs-imov-l-100pr { left: -100% !important;}
  .xs-imov-l-110pr { left: -110% !important;}
  .xs-imov-l-120pr { left: -120% !important;}
  .xs-imov-l-130pr { left: -130% !important;}
  .xs-imov-l-140pr { left: -140% !important;}
  .xs-imov-l-150pr { left: -150% !important;}

  .xs-imov-r-tn {right: -$size-tn-x !important;}
  .xs-imov-r-xs {right: -$size-xs-x !important;}
  .xs-imov-r-sm {right: -$size-sm-x !important;}
  .xs-imov-r    {right: -$size-nm-x !important;}
  .xs-imov-r-md {right: -$size-md-x !important;}
  .xs-imov-r-lg {right: -$size-lg-x !important;}
  .xs-imov-r-hg {right: -$size-hg-x !important;}
  .xs-imov-r-10pr { right: -10% !important;}
  .xs-imov-r-20pr { right: -20% !important;}
  .xs-imov-r-30pr { right: -30% !important;}
  .xs-imov-r-40pr { right: -40% !important;}
  .xs-imov-r-50pr { right: -50% !important;}
  .xs-imov-r-60pr { right: -60% !important;}
  .xs-imov-r-70pr { right: -70% !important;}
  .xs-imov-r-80pr { right: -80% !important;}
  .xs-imov-r-90pr { right: -90% !important;}
  .xs-imov-r-100pr { right: -100% !important;}
  .xs-imov-r-110pr { right: -110% !important;}
  .xs-imov-r-120pr { right: -120% !important;}
  .xs-imov-r-130pr { right: -130% !important;}
  .xs-imov-r-140pr { right: -140% !important;}
  .xs-imov-r-150pr { right: -150% !important;}

  /* ------------ Font ------------ */

  .xs-f-s-sm { font-size: $font-size-small !important; }
  .xs-f-s    { font-size: $font-size-base !important; }
  .xs-f-s-md { font-size: $font-size-middle !important; }
  .xs-f-s-lg { font-size: $font-size-large !important; }
  .xs-f-s-hg { font-size: $font-size-huge !important; }
  .xs-f-s-h1 { font-size: $font-size-h1 !important; }
  .xs-f-s-h2 { font-size: $font-size-h2 !important; }
  .xs-f-s-h3 { font-size: $font-size-h3 !important; }
  .xs-f-s-h4 { font-size: $font-size-h4 !important; }
  .xs-f-s-h5 { font-size: $font-size-h5 !important; }
  .xs-f-s-h6 { font-size: $font-size-h6 !important; }

  .xs-text-left { text-align: left !important; }
  .xs-text-right { text-align: right !important; }
  .xs-text-center { text-align: center !important; }

  .xs-f-w-n { font-family: $font-family-base; font-weight: normal; /* font-weight: normal; */ }
  .xs-f-w-b { font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */ }
  .xs-f-w-l { font-family: $font-family-light; font-weight: normal; /* font-weight: light; */ }
  
}

/* ------------ Width & Height ------------ */

.w-100pr {width: 100% !important;}
.h-100pr {height: 100% !important;}
.w-95pr  {width: 95% !important;}
.w-90pr  {width: 90% !important;}
.w-85pr  {width: 85% !important;}
.w-80pr  {width: 80% !important;}
.w-75pr  {width: 75% !important;}
.w-70pr  {width: 70% !important;}
.w-65pr  {width: 65% !important;}
.w-60pr  {width: 60% !important;}
.w-55pr  {width: 55% !important;}
.w-50pr  {width: 50% !important;}
.w-45pr  {width: 45% !important;}
.w-40pr  {width: 40% !important;}
.w-35pr  {width: 35% !important;}
.w-30pr  {width: 30% !important;}
.w-25pr  {width: 25% !important;}
.w-20pr  {width: 20% !important;}
.w-15pr  {width: 15% !important;}
.w-10pr  {width: 10% !important;}
.w-5pr  {width: 5% !important;}
.w-1pr  {width: 1% !important;}
.w-0pr  {width: 0% !important;}
.w-auto {width: auto !important;}
.h-50pr  {height: 50% !important;}

.w-100  {width: 100px !important;}
.w-220  {width: 220px !important;}
.w-250  {width: 250px !important;}
.w-300  {width: 300px !important;}

.max-w-360 {max-width: 360px !important;}
.max-w-480 {max-width: 480px !important;}
.max-w-520 {max-width: 520px !important;}
.max-w-600 {max-width: 600px !important;}
.max-w-800 {max-width: 800px !important;}

.h-50  {height: 50px !important;}
.h-130 {height: 130px !important;}
.h-200 {height: 200px !important;}
.h-230 {height: 230px !important;}
.h-250 {height: 250px !important;}
.h-300 {height: 300px !important;}
.h-350 {height: 350px !important;}
.h-360 {height: 360px !important;}
.h-400 {height: 400px !important;}
.h-590 {height: 590px !important;}
.h-600 {height: 600px !important;}

@media screen and (max-width: $screen-xs-max) {
  .xs-w-a { width: auto !important; max-width: none; }
  .xs-w-200pr {width: 200% !important; max-width: none;}
}

.dt-w-50 {
  dt {width: 50px !important;}
  dd {margin-left: 50px !important;}
}
.dt-w-80 {
  dt {width: 80px !important;}
  dd {margin-left: 80px !important;}
}
.dt-w-120 {
  dt {width: 120px !important;}
  dd {margin-left: 120px !important;}
}

.line-height-input { line-height: $input-height-base !important; }
.line-height-computed { line-height: $line-height-computed !important; }

// align
.v-align-t { vertical-align: top !important; }
.v-align-m { vertical-align: middle !important; }
.v-align-b { vertical-align: bottom !important; }

// position
.position-relative {position: relative !important;}
.position-absolute {position: absolute !important;}
.overflow-hidden { overflow: hidden !important; }
.left-0 { left: 0 !important; }
.right-0 { right: 0 !important; }
.top-0 { top: 0 !important; }
.bottom-0 { bottom: 0 !important; }

// @include generate-selector(height, h, 5, 200);
// @include generate-selector(width, w, 5, 200);

/* ------------ Borders ------------ */

@mixin border-bottom-dotted { border-bottom: 2px dotted $border-dotted-color; }
.border-bottom-dotted { @include border-bottom-dotted; }
@mixin border-top-dotted { border-top: 2px dotted $border-dotted-color; }
.border-top-dotted { @include border-top-dotted; }
@mixin border-left-dotted { border-left: 2px dotted $border-dotted-color; }
.border-left-dotted { @include border-left-dotted; }
@mixin border-right-dotted { border-right: 2px dotted $border-dotted-color; }
.border-right-dotted { @include border-right-dotted; }
.border-top { border-top: 1px solid $gray-lighter; }
.border-bottom { border-bottom: 1px solid $gray-lighter; }
.border-top-none {
  &, &.table { border-top: 0 none; }
}
.border-left-none {
  &, &.table { border-top: 0 none; }
}
.border-bottom-none {
  &, &.table { border-top: 0 none; }
}
.border-right-none {
  &, &.table { border-top: 0 none; }
}

.display-block { display: block; }
.display-flex { flex: block; }
.display-inline-block { display: inline-block; }
.display-inline { display: inline; }

.hidden { display: none !important; }

.display-table { display: table !important; }
.table-row { display: table-row !important; }
.table-cell { display: table-cell !important; }

/* ------------ Opacity ------------ */

.opacity-0 { @include opacity-important(0); }
.opacity-10 { @include opacity-important(.1); }
.opacity-20 { @include opacity-important(.2); }
.opacity-30 { @include opacity-important(.3); }
.opacity-40 { @include opacity-important(.4); }
.opacity-50 { @include opacity-important(.5); }
.opacity-60 { @include opacity-important(.6); }
.opacity-70 { @include opacity-important(.7); }
.opacity-80 { @include opacity-important(.8); }
.opacity-90 { @include opacity-important(.9); }
.opacity-100 { @include opacity-important(1); }

/* ------------ Icons ------------ */

@include generate-icons($icon-sizes);
.genicon { @extend %genicon; }

/* ------------ Scrollable ------------ */

.scrollable {
  &-horizontal {
    @mixin scrollable-horizontal() {
      width: 100%;
      overflow-y: hidden;
      overflow-x: auto;
    }
    & { @include scrollable-horizontal; }
    &-upmd {
      @media screen and (min-width: $screen-desktop) { @include scrollable-horizontal; }
    }
    &-sm {
      @media screen and (min-width: $screen-tablet) and (max-width: ($screen-desktop - 1)) { @include scrollable-horizontal; }
    }
    &-xs {
      @media screen and (max-width: ($screen-tablet - 1)) { @include scrollable-horizontal; }
    }
  }
}