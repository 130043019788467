//
// GENERALI profile variables
// --------------------------------------------------

//== Colors

// Swatch1: brand colors 1
$sw1-color1:              #c21b17;
$sw1-color2:              #752127;
$sw1-color3:              #8e1230;
$sw1-color4:              #e9573d;
$sw1-color5:              #6f7072;
$sw1-color6:              #b0b2b3;

// Swatch2: brand colors 2
$sw2-color1:              #f09273;
$sw2-color2:              #f9d3c2;
$sw2-color3:              #bf6364;
$sw2-color4:              #edbab9;
$sw2-color5:              #5e906b;
$sw2-color6:              #d0e5d3;
$sw2-color7:              #d8d9da;

// Swatch3: other colors
$sw3-color1:              #efbea8;
$sw3-color2:              #faf0e1;
$sw3-color3:              #f6e5d9;
$sw3-color4:              #faf1f1;
$sw3-color5:              #f5f5f6;
$sw3-color6:              #749e76;
$sw3-color7:              #dff0d8;
$sw3-color8:              #f2dede;
$sw3-color9:              #8b80a9;
$sw3-color10:             #596d7b;
$sw3-color11:             #a1b575;
$sw3-color12:             #573b8f;
$sw3-color13:             #f5f5f5;

// Generate color selectors
//@include generate-color-swatch(1, 6);
//@include generate-color-swatch(2, 7);
//@include generate-color-swatch(3, 12);

// Basic colors
$brand-default:           $gray;
$brand-primary:           $sw1-color1;
$brand-success:           $sw2-color5;
$brand-info:              $sw1-color5;
$brand-warning:           $sw1-color4;
$brand-danger:            $sw1-color2;

// Grayscale colors
$white:                   #ffffff;
$black:                   #000000;
$gray-base:               $black;
$gray-darker:             #3c3c3c;
$gray-dark:               $sw1-color5;
$gray:                    $sw1-color6;
$gray-light:              #dadbdc;
$gray-lighter:            #ececed;

// Link colors
$link-color:              $brand-primary;
$link-hover-color:        $brand-primary;
$link-type2-color:        $sw1-color6;
$link-type2-hover-color:  $sw1-color6;
$link-active-color:       $sw1-color5;
$link-modest-color:       $sw1-color5;

// Type colors
$h1-color:                $brand-danger;
$h2-color:                $brand-danger;
$h3-color:                $brand-danger;
$h4-color:                $gray-darker;
$h5-color:                $gray-dark;
$h6-color:                $gray-dark;
$headings-color:          $brand-danger;
$text-color:              $gray-darker;
$note-color:              $gray;


//== Bootstrap variables ==

$component-active-bg:     $brand-primary;


//== Border ==

$border-radius-base:            0px;
$border-radius-large:           0px;
$border-radius-small:           0px;

$border-dotted-color:           $brand-primary;

//== Panels ==

$panel-default-bg:                  $white;
$panel-default-border:              $sw2-color7;
$panel-default-link:                $brand-danger;
$panel-default-text:                $gray-dark;
$panel-default-heading:             $gray-dark;

$panel-info-bg:                     $gray-lighter;
$panel-info-border:                 $gray-lighter;
$panel-info-link:                   $brand-primary;
$panel-info-text:                   $gray-dark;
$panel-info-heading:                $gray-dark;

$panel-primary-bg:                  $sw2-color3;
$panel-primary-border:              $sw2-color3;
$panel-primary-link:                $white;
$panel-primary-text:                $white;
$panel-primary-heading:             $white;

$panel-success-bg:                  $sw3-color6;
$panel-success-border:              $sw3-color6;
$panel-success-link:                $gray-lighter;
$panel-success-text:                $white;
$panel-success-heading:             $white;

$panel-warning-bg:                  $sw2-color1;
$panel-warning-border:              $sw2-color1;
$panel-warning-link:                $gray-lighter;
$panel-warning-text:                $white;
$panel-warning-heading:             $white;

$panel-danger-bg:                   $brand-danger;
$panel-danger-border:               $brand-danger;
$panel-danger-link:                 $gray-lighter;
$panel-danger-text:                 $white;
$panel-danger-heading:              $white;

$panel-highlight-bg:                $brand-warning;
$panel-highlight-border:            $brand-warning;
$panel-highlight-link:              $gray-lighter;
$panel-highlight-text:              $white;
$panel-highlight-heading:           $white;

$panel-title-color:                 $brand-danger;
$panel-address-highlighted-color:   $brand-danger;
$panel-icon-huge-color:             $gray-dark;

// Panel News
$panel-news-title-color:            $gray-darker;
$panel-news-link-color:             $brand-primary;

// Panel Download
$panel-download-corner-bg:          $gray;
$panel-download-corner-empty-bg:    $white;

// Chat
$chat-status-online-color:          $sw3-color11;
$chat-status-offline-color:         $gray;

// Sales
$panel-sales-email-color:           $brand-primary;

// HP
$panel-info-hp:                     $sw3-color13;
$panel-info-hp-title:               $sw1-color2;

// Accordion
$accordion-radius:                      0;
$accordion-heading-color:               $brand-danger;
$accordion-heading-bg:                  $sw2-color2;
$accordion-heading-icon-color:          $brand-primary;
$accordion-heading-active-color:        $white;
$accordion-heading-active-bg:           $sw1-color4;
$accordion-heading-active-icon-color:   $white;


//== Buttons

$btn-border-large:              3px;
$btn-border-normal:             2px;
$btn-border-small:              2px;
$btn-border-radius-base:        0;
$btn-border-radius-small:       0;
$btn-border-radius-large:       0;
$btn-font-weight:               bold;

$btn-default-filled:                 false;
$btn-default-color:                  $brand-primary;
$btn-default-bg:                     $brand-primary;
$btn-default-active-color:           $brand-danger;
$btn-default-active-bg:              $brand-danger;
$btn-default-disabled-color:         $gray;
$btn-default-disabled-bg:            $gray;

$btn-primary-filled:                 true;
$btn-primary-color:                  $white;
$btn-primary-bg:                     $brand-primary;
$btn-primary-active-color:           $white;
$btn-primary-active-bg:              $brand-danger;
$btn-primary-disabled-color:         $white;
$btn-primary-disabled-bg:            $gray;

$btn-info-filled:                    false;
$btn-info-color:                     $gray-dark;
$btn-info-bg:                        $gray-dark;
$btn-info-active-color:              $sw2-color1;
$btn-info-active-bg:                 $sw2-color1;
$btn-info-disabled-color:            $gray;
$btn-info-disabled-bg:               $gray;

$btn-success-filled:                 false;
$btn-success-color:                  $brand-success;
$btn-success-bg:                     $brand-success;
$btn-success-active-color:           darken($brand-success,20%);
$btn-success-active-bg:              darken($brand-success,20%);
$btn-success-disabled-color:         $gray;
$btn-success-disabled-bg:            $gray;

$btn-warning-filled:                 false;
$btn-warning-color:                  $brand-warning;
$btn-warning-bg:                     $brand-warning;
$btn-warning-active-color:           darken($brand-warning,20%);
$btn-warning-active-bg:              darken($brand-warning,20%);
$btn-warning-disabled-color:         $gray;
$btn-warning-disabled-bg:            $gray;

$btn-danger-filled:                 false;
$btn-danger-color:                  $brand-danger;
$btn-danger-bg:                     $brand-danger;
$btn-danger-active-color:           darken($brand-danger,20%);
$btn-danger-active-bg:              darken($brand-danger,20%);
$btn-danger-disabled-color:         $gray;
$btn-danger-disabled-bg:            $gray;

$btn-modest-filled:                  true;
$btn-modest-color:                   $brand-primary;
$btn-modest-bg:                      $white;
$btn-modest-active-color:            $brand-danger;
$btn-modest-active-bg:               $sw3-color4;
$btn-modest-disabled-color:          $gray;
$btn-modest-disabled-bg:             $gray;

$btn-white-filled:                   false;
$btn-white-color:                    $white;
$btn-white-bg:                       transparent;
$btn-white-active-color:             $sw3-color1;
$btn-white-active-bg:                transparent;
$btn-white-disabled-color:           $gray;
$btn-white-disabled-bg:              $gray;

$btn-link-filled:                    false;
$btn-link-color:                     $brand-primary;
$btn-link-bg:                        transparent;
$btn-link-active-color:              $sw2-color1;
$btn-link-active-bg:                 transparent;
$btn-link-disabled-color:            $gray;
$btn-link-disabled-bg:               $gray;

$btn-secondary-filled:               true;
$btn-secondary-color:                transparent;
$btn-secondary-bg:                   transparent;
$btn-secondary-active-color:         transparent;
$btn-secondary-active-bg:            transparent;
$btn-secondary-disabled-color:       transparent;
$btn-secondary-disabled-bg:          transparent;

// Button group
$btn-group-active-bg:                $brand-primary;
$btn-group-active-border:            $brand-primary;
$btn-group-active-color:             $white;
$btn-group-narrow-active-bg:         $brand-primary;
$btn-group-narrow-active-border:     $brand-primary;
$btn-group-narrow-active-color:      $white;

// Other buttons
$btn-search-bg:                      $white;


//== Tables

$table-cell-padding:            8px;
$table-condensed-cell-padding:  5px;
$table-border-radius:           $border-radius-base;

$table-bg:                      transparent;
$table-bg-accent:               $sw3-color2;
$table-bg-hover:                $sw3-color3;
$table-bg-active:               $table-bg-hover;
$table-bg-stripped:             $sw3-color4;
$table-border:                  $brand-primary;
$table-border-width:            2px;
$table-header-border:           $brand-primary;
$table-header-inner-border:     $white;
$table-footer-border:           $brand-primary;
$table-cell-border:             $gray;
$table-text:                    $gray-dark;
$table-head-bg:                 $brand-primary;
$table-head-color:              $white;


//== Forms

$form-label-color:                                  $gray-dark;
$inline-form-label-color:                           $gray-darker;
$input-bg:                                          $white;
$input-disabled-bg:                                 $sw3-color5;
$input-disabled-border:                             $gray;
$input-color:                                       $gray-dark;
$input-border:                                      $gray-dark;

$input-icheck-size:                                 24px;
$input-icheck-hover:                                $brand-primary;
$input-icheck-checked:                              $brand-primary;
$input-icheck-checkbox-icon-content:                genicon-char(checkbox);
$input-icheck-checkbox-checked-icon-content:        genicon-char(checkbox-checked);
$input-icheck-checkbox-invert-checked-icon-content: genicon-char(checkbox-invert-checked);

// Fieldset
$fieldset-legend-color:   $gray-dark;
$fieldset-border:         $gray-lighter;
$help-block-color:        $gray;

// iCheck
$icheck-label-color:      $gray-dark;
$icheck-white-color:      $white;

// Select
$select-arrow-color:      $brand-primary;


//== Form states and alerts

// States
$state-success-text:      $sw2-color5;
$state-success-bg:        $sw3-color7;
$state-success-border:    $sw2-color5;

$state-danger-text:       $brand-primary;
$state-danger-bg:         $sw3-color8;
$state-danger-border:     $brand-primary;

$state-warning-text:      $state-danger-text;
$state-warning-bg:        $state-danger-bg;
$state-warning-border:    $state-danger-border;

$input-focus-text:        $sw2-color1;
$input-focus-bg:          $white;
$input-focus-border:      $sw2-color1;

// Alerts
$alert-success-bg:        $sw2-color6;
$alert-success-text:      $sw2-color5;
$alert-success-border:    $sw2-color6;
$alert-success-btn-white: false;

$alert-info-bg:           $gray-lighter;
$alert-info-text:         $gray-dark;
$alert-info-border:       $gray-lighter;
$alert-info-btn-white:    false;

$alert-warning-bg:        $sw2-color2;
$alert-warning-text:      $sw1-color4;
$alert-warning-border:    $sw2-color2;
$alert-warning-btn-white: false;

$alert-danger-bg:         $sw2-color4;
$alert-danger-text:       $brand-danger;
$alert-danger-border:     $sw2-color4;
$alert-danger-btn-white:  false;

//== Thumbnails

$thumbnail-border:        $gray-lighter;

//== Breadcrumbs

$breadcrumb-bg:                   transparent;
$breadcrumb-color:                $gray;
$breadcrumb-active-color:         $gray;


//== Pagination

$pagination-color:                      $gray-dark !important;
$pagination-bg:                         transparent !important;
$pagination-border:                     $gray-dark !important;

$pagination-hover-color:                $white !important;
$pagination-hover-bg:                   $brand-danger !important;
$pagination-hover-border:               $brand-danger !important;

$pagination-active-color:               $white !important;
$pagination-active-bg:                  $brand-primary !important;
$pagination-active-border:              $brand-primary !important;

$pagination-disabled-color:             $gray;
$pagination-disabled-bg:                $sw3-color5;
$pagination-disabled-border:            $gray;

$pagination-primary-color:              $sw2-color7;
$pagination-primary-bg:                 $sw2-color3;
$pagination-primary-border:             $sw2-color7;

$pagination-primary-hover-color:        $white;
$pagination-primary-hover-bg:           $sw2-color1;
$pagination-primary-hover-border:       $white;

$pagination-primary-active-color:       $white;
$pagination-primary-active-bg:          $brand-primary;
$pagination-primary-active-border:      $white;

$pagination-primary-disabled-color:     $sw2-color7;
$pagination-primary-disabled-bg:        $gray;
$pagination-primary-disabled-border:    $sw2-color7;

$pagination-success-color:              $sw2-color7;
$pagination-success-bg:                 $sw3-color6;
$pagination-success-border:             $sw2-color7;

$pagination-success-hover-color:        $white;
$pagination-success-hover-bg:           $sw3-color6;
$pagination-success-hover-border:       $white;

$pagination-success-active-color:       $white;
$pagination-success-active-bg:          $sw2-color5;
$pagination-success-active-border:      $white;

$pagination-success-disabled-color:     $sw2-color7;
$pagination-success-disabled-bg:        $gray;
$pagination-success-disabled-border:    $sw2-color7;


//== Labels

$label-default-bg:            $gray-lighter;
$label-default-color:         $gray-dark;
$label-default-border:        $sw2-color7;
$label-primary-bg:            $brand-primary;
$label-success-bg:            $brand-success;
$label-info-bg:               $brand-info;
$label-warning-bg:            $brand-warning;
$label-danger-bg:             $brand-danger;
$label-color:                 $white;
$label-link-hover-color:      $white;

//== Tooltips

$tooltip-color:               $white;
$tooltip-bg:                  $gray-dark;
$tooltip-arrow-color:         $tooltip-bg;

$tooltip-default:             $gray-dark;
$tooltip-info:                $gray;
$tooltip-success:             $brand-success;
$tooltip-warning:             $brand-warning;
$tooltip-danger:              $brand-danger;


//== Popovers

$popover-bg:                          $white;
$popover-border-color:                $sw2-color7;
$popover-border-radius:               $border-radius-base;
$popover-fallback-border:             $sw2-color7;
$popover-title-color:                 $white;
$popover-title-bg:                    $gray-lighter;
$popover-arrow-color:                 $popover-bg;
$popover-arrow-outer-width:           ($popover-arrow-width + 1);
$popover-arrow-outer-color:           fadein($popover-border-color, 5%);
$popover-arrow-outer-fallback-color:  darken($popover-fallback-border, 20%);

$popover-default:             $gray-lighter;
$popover-default-text:        $gray-dark;
$popover-info:                $gray;
$popover-success:             $brand-success;
$popover-warning:             $brand-warning;
$popover-danger:              $brand-danger;


//== Jumbotron

$jumbotron-color:                 $white;
$jumbotron-heading-color:         $white;
$jumbotron-link-color:            $white;
$jumbotron-bg:                    $gray-dark;
$jumbotron-box-bg:                $white;

$jumbotron-description-color:     $gray-dark;
$jumbotron-description-bg:        $gray-light;

$jumbotron-heading-font-size:     $font-size-h2;
$jumbotron-font-size:             $font-size-base;
$jumbotron-heading-font-size-lg:  $font-size-h1;
$jumbotron-font-size-lg:          $font-size-large;
$jumbotron-heading-font-size-sm:  $font-size-h3;
$jumbotron-font-size-sm:          $font-size-base;

$jumbotron-funds-nav-bg:          $sw3-color6;
$jumbotron-funds-link-color:      $gray-lighter;


//== Carousels

$carousel-control-bg:            $sw2-color4;
$carousel-control-icon-color:    $brand-danger;

//== Slick Slider

$slick-arrow-bg:                 $sw2-color4;
$slick-arrow-color:              $brand-danger;
$slick-dots-color:               $brand-primary;
$slider-testemonials-color:      $sw2-color1;
$slider-article-title-color:     $sw1-color2;
$slider-card-bg:                 $sw3-color13;
$slider-article-date-color:      $gray-dark;
$slider-article-btn-hover-color: $black;


//== Tabs

$tab-padding:                           14px;
$tab-margin-right:                      -1px;

$tab-line-color:                        $sw2-color7;
$tab-color:                             $gray-dark;
$tab-bg:                                $white;
$tab-border:                            $sw2-color7;
$tab-hover-color:                       $gray-dark;
$tab-hover-bg:                          $white;
$tab-hover-border:                      $sw1-color4;
$tab-active-color:                      $white;
$tab-active-bg:                         $brand-primary;
$tab-active-border:                     $brand-primary;
$tab-slim-active-border:                $brand-primary;
$tab-disabled-color:                    $sw2-color7;
$tab-disabled-bg:                       $white;
$tab-disabled-border:                   $sw2-color7;

$tab-type2-border:                      transparent;
$tab-type2-bg:                          transparent;
$tab-type2-color:                       transparent;
$tab-type2-hover-border:                transparent;
$tab-type2-hover-bg:                    transparent;
$tab-type2-hover-color:                 transparent;
$tab-type2-active-border:               transparent;
$tab-type2-active-bg:                   transparent;
$tab-type2-active-color:                transparent;


//== Modals

$modal-title-line-height:               $line-height-base;
$modal-title-color:                     $sw1-color2;
$modal-content-bg:                      $white;
$modal-content-border:                  transparent;
$modal-content-fallback-border:         transparent;
$modal-backdrop-bg:                     $black;
$modal-header-border:                   transparent;
$modal-footer-border:                   transparent;
$modal-map-place:                       $gray;


//== Video

$video-bg:                    $sw2-color7;

//== Progress bars

$progress-bg:                 $sw2-color7;
$progress-color:              $gray-dark;
$progress-bar-color:          $white;
$progress-ticks-color:        $gray-dark;
$progress-border-radius:      $border-radius-base;
$progress-bar-bg:             $brand-primary;
$progress-bar-success-bg:     $brand-success;
$progress-bar-warning-bg:     $brand-warning;
$progress-bar-danger-bg:      $brand-danger;
$progress-bar-info-bg:        $brand-info;
$progress-marker-color:       $brand-primary;
$progress-marker-bg:          $white;


//== Navbar

// Basics of a navbar

$navbar-default-color:              $gray-dark;
$navbar-default-bg:                 transparent;
$navbar-default-border:             transparent;
$navbar-border-radius:              $border-radius-base;

// Navbar links
$navbar-default-link-color:                 $gray-darker;
$navbar-default-link-hover-color:           $brand-primary;
$navbar-default-link-hover-bg:              transparent;
$navbar-default-link-active-color:          $brand-primary;
$navbar-default-link-active-bg:             transparent;
$navbar-default-link-disabled-color:        $gray-light;
$navbar-default-link-disabled-bg:           transparent;

// Menu
$mainmenu-link-active-color:                $brand-primary;
$mainmenu-link-active-border:               $brand-primary;
$mainmenu-panel-form-control-border:        $white;
$mainmenu-panel-label-color:                $white;

$menu-mobile-border:                        $gray-light;
$menu-mobile-item-border:                   $gray-lighter;

$nav-link-hover-bg:                         transparent;

$nav-disabled-link-color:                   $gray-light;
$nav-disabled-link-hover-color:             transparent;

$menu-highlight-bg:                         $brand-warning;
$menu-highlight-link-color:                 $white;
$menu-highlight-link-hover-color:           $white;
$menu-highlight-icon-color:                 $sw3-color1;

$menutop-bg:                                $sw1-color1;
$menutop-pills-hover-bg:                    darken($sw1-color1, 10%);
$menutop-icon-color:                        $sw3-color3;


//== Dropdowns

$dropdown-bg:                    rgba(red($sw3-color5), green($sw3-color5), blue($sw3-color5), 0.95);
$dropdown-border:                $sw3-color5;
$dropdown-fallback-border:       $gray;
$dropdown-divider-bg:            $brand-primary;
$dropdown-link-color:            $brand-primary;
$dropdown-link-hover-color:      $brand-danger;
$dropdown-link-hover-bg:         transparent;
$dropdown-link-active-color:     $brand-primary;
$dropdown-link-active-bg:        transparent;
$dropdown-link-disabled-color:   $gray-light;
$dropdown-header-color:          $gray-light;
$dropdown-caret-color:           $gray-dark;
$dropdown-icon-color:            $gray-dark;


//== Layout

// header
$header-background:              inherit;
$header-border:                  $sw2-color7;
$header-logo-width:              304px;
$header-logo-height:             65px;

// Search
$search-icon-color:              $gray-dark;
$search-icon-hover-color:        $brand-primary;
$search-btn-border:              $gray-dark;

// Tool
$tool-color:                     $brand-primary;
$tool-active-color:              $brand-danger;

// Footer
$footer-link-color:              $brand-danger;
$footer-color:                   $gray;
$footer-border:                  $sw2-color7;
$menu-footer-bg:                 $gray-lighter;
$menu-footer-text:               $gray-dark;
$menu-footer-link-color:         $gray-dark;
$menu-footer-link-hover-color:   $gray-darker;


//== Lists

// List group
$list-group-bg:                     $white;
$list-group-border:                 $sw2-color7;
$list-group-border-radius:          $border-radius-base;
$list-group-hover-bg:               $gray-lighter;
$list-group-active-color:           $component-active-color;
$list-group-active-bg:              $component-active-bg;
$list-group-active-border:          $list-group-active-bg;
$list-group-active-text:            $gray-light;
$list-group-disabled-color:         $gray-light;
$list-group-disabled-bg:            $gray-lighter;
$list-group-disabled-text:          $list-group-disabled-color;
$list-group-link-color:             $gray-dark;
$list-group-link-hover-color:       $list-group-link-color;
$list-group-link-heading-color:     $gray-darker;

// List languages
$list-lang-link-color:              $gray;
$list-lang-link-hover-color:        $gray-dark;
$list-lang-divider-color:           $gray;

// List
$list-icon-bold-color:              $brand-primary;
$list-icon-gray-color:              $gray-dark;
$list-primary-bold-color:           $sw3-color1;

// List Header
$list-heading-color:                $brand-primary;


//== Map

$map-point-a-color:             $brand-primary;
$map-point-b-color:             $sw2-color3;
$map-point-c-color:             $sw1-color4;
$map-point-d-color:             $brand-danger;
$map-point-e-color:             $gray-dark;
$map-point-stroke-color:        $white;
$cezetmap-region-color:         $sw2-color1;
$cezetmap-region-active-color:  $sw1-color1;


//== Infograph

// Legend
$infograph-legend-color-1:              $sw1-color2;
$infograph-legend-color-2:              $sw1-color1;
$infograph-legend-color-3:              $sw2-color1;
$infograph-legend-color-4:              $sw1-color5;

// Abstract
$infograph-h2-color:                    $brand-danger;
$infograph-h3-color:                    $gray-darker;
$infograph-link-color:                  $brand-primary;
$infograph-text:                        $gray-darker;
$infograph-panel-primary-text:          $white;
$infograph-panel-primary-label-color:   $sw3-color1;
$infograph-label-color:                 $gray-dark;
$infograph-result-color:                $brand-danger;

// Interject
$interject-icon-color:                  $brand-primary;
$interject-btn-bg:                      $white;
$interject-btn-text:                    $brand-primary;

// Arrow
$infograph-arrow-color:                 $brand-primary;
$infograph-arrow-title-color:           $gray-dark;

// Step
$infograph-step-bg:                     $white;
$infograph-step-color:                  $brand-primary;
$infograph-step-border:                 $brand-primary;
$infograph-step-title-color:            $gray-dark;
$infograph-step-active-title-color:     $brand-primary;
$infograph-step-active-icon-bg:         $brand-primary;
$infograph-step-active-icon-color:      $white;

// Tick
$infograph-tick-bg:                     $white;
$infograph-tick-color:                  $brand-primary;
$infograph-tick-border:                 $brand-primary;
$infograph-tick-arrow-color:            $brand-primary;

// Intervals
$infograph-intervals-arrow-60pr:        $sw2-color5;

// Moneybox
$infograph-moneybox-bg:                 $brand-danger;
$infograph-moneybox-icon-color:         $sw3-color1;

// Phase
$infograph-phase-border:                $gray-light;


//== Calendar

$calendar-header-color:         $white;
$calendar-text:                 $brand-primary;
$calendar-border:               $gray-light;
$calendar-icon-active-color:    $sw3-color1;
$calendar-focus-bg:             $sw2-color2;
$calendar-focus-text:           $brand-danger;
$calendar-active-bg:            $sw2-color5;
$calendar-active-text:          $white;
$calendar-info-bg:              $sw3-color4;
$calendar-info-text:            $gray;

//== Cards

$card-icon-color:               $brand-primary;
$card-link-color:               $brand-primary;
$card-link-url-color:           $gray;
$card-date-bg:                  $gray-lighter;
$card-date-text:                $gray;
$card-phone-color:              $brand-danger;
$card-icon-huge-color:          $brand-danger;
$card-h4-color:                 $gray-dark;

// Images
$card-img-bg:                   $sw2-color4;
$card-nophoto-icon-color:       $white;

// Director & Adviser
$card-card-filter:              $sw2-color3;
$card-card-footer-bg:           $sw2-color3;
$card-card-h4-color:            $sw3-color1;
$card-card-text:                $white;
$card-dl-dt-color:              $sw3-color1;
$card-dl-dd-color:              $white;

// Event
$card-event-dd-color:           $brand-primary;
$card-event-icon-color:         $brand-danger;
$card-event-footer-bg:          $gray-lighter;
$card-vacancies-color:          $brand-primary;


//== Lightbox

$lightbox-nav-anchor-color:     $white;
$lightbox-nav-icon-bg:          $white;
$lightbox-nav-icon-color:       $brand-danger;
$lightbox-icon-close-color:     $white;
$lightbox-text-color:           $white;


//== Pages

// Fund courses
$fund-icon-color:               $brand-primary;
$fund-state-color:              $gray-dark;
$fund-growth-color:             $brand-success;
$fund-decline-color:            $brand-primary;
$fund-value-color:              $brand-danger;
// Fund tags
$fund-tag1:                     $sw1-color1;
$fund-tag2:                     $sw1-color4;
$fund-tag3:                     $sw3-color6;
$fund-tag4:                     $sw1-color2;
$fund-tag5:                     $sw3-color9;

//== Graph
$graph-bg:                      $white;


//== New Customer
$new-customer-bg:               $sw1-color4;
