.bootstrap-tagsinput {

  @extend .form-control;
  > .bootstrap-tagsinput {
    height: auto;
    border: 0 none;
    padding: 0;
  }

  input {
    width: 100%;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    min-width: 210px;
    max-width: inherit;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .tag {
    margin-right: 2px;
    font-weight: normal;
    font-size: $font-size-h6;
    color: white;

    [data-role="remove"] {
      margin-left:8px;
      cursor:pointer;
      &:after{
        content: "x";
        padding:0px 2px;
      }
      &:hover {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
        &:active {
          box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
        }
      }
    }
  }

  .tt-suggestion {
    display: inline-block;
    padding: $size-tn-y $size-tn-x;
    font-size: $font-size-h6;
    background-color: $gray-dark;
    white-space: nowrap;
    color: $white;
  }

  @media screen and (max-width: ($screen-tablet - 1)) {
    .tag,
    .tt-suggestion {
      font-size: $font-size-small;
    }
  }

}
