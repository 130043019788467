/* ---------- ENVIRONMENT STYLES ---------- */

// Bootstrap variables
@import "./assets/scss/bootstrap/variables";

// Profile & layout
@import "profile";
@import "./assets/scss/profile/variables";

// Bootstrap styles
@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import "./assets/scss/bootstrap/mixins";

// genfw base setting & functions
@import "./assets/scss/mixins/mixins";

// Fonts
@import './assets/fonts/icons/icons';
@import "./assets/scss/profile/icons";

/* ---------- COMMON STYLES ---------- */

.genfw {

  // genfw selectors
  @import "./assets/scss/components/selectors";
  @import "./assets/scss/components/definitions";
  @import "./assets/scss/components/scaffolding";

  // genfw selectors
  @import "./assets/scss/components/selectors";
  @import "./assets/scss/components/definitions";
  @import "./assets/scss/components/scaffolding";

  // genfw elements
  @import "./assets/scss/components/type";
  @import "./assets/scss/components/tables";
  @import "./assets/scss/components/buttons";
  @import "./assets/scss/components/panels";
  @import "./assets/scss/components/forms";
  @import "./assets/scss/components/alerts";
  @import "./assets/scss/components/labels";
  @import "./assets/scss/components/tooltip";
  @import "./assets/scss/components/list-group";
  // @import "./assets/scss/components/thumbnails";

  // genfw components
  @import "./assets/scss/components/breadcrumbs";
  @import "./assets/scss/components/jumbotron";
  @import "./assets/scss/components/pagination";
  @import "./assets/scss/components/navs";
  @import "./assets/scss/components/navbar";
  @import "./assets/scss/components/popovers";
  // @import "./assets/scss/components/carousel";
  // @import "./assets/scss/components/slider";
  @import "./assets/scss/components/modals";
  // @import "./assets/scss/components/gallery";
  // @import "./assets/scss/components/video";
  @import "./assets/scss/components/progress-bars";
  // @import "./assets/scss/components/calendar";
  // @import "./assets/scss/components/map";
  @import "./assets/scss/components/directory";

  // genfw specific
  @import "./assets/scss/site/layouts";
  // @import "./assets/scss/site/cards";
  @import "./assets/scss/site/funds";
  // @import "./assets/scss/site/infobox";
  // @import "./assets/scss/site/infographics";
  // @import "./assets/scss/site/survey";
  @import "./assets/scss/site/pages";
  // @import "./assets/scss/site/site";

  // Vendors
  // @import "./assets/scss/vendors/lightbox";
  @import "./assets/scss/vendors/tagsinput";
  @import "./assets/scss/vendors/datepicker";

}

/* ---------- DAM STYLES ---------- */

@import "layout";

.genfw {

  @import "site";

}