//
// Tables
// --------------------------------------------------

// Baseline styles

.table {
  border-top: $table-border-width solid $table-footer-border;
  border-bottom: $table-border-width solid $table-footer-border;
  border-collapse: separate;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th, > td {
        font-size: $font-size-small;
        /*border-top: 1px solid $table-cell-border;*/
        border-top: 0 none;
        border-bottom: 1px solid $table-cell-border;
        color: $table-text;
        &.collapse {
          &.in { display: table-cell; }
        }
      }
    }
  }
  > thead > tr {
    > th, > td {
      vertical-align: middle;
      font-weight: normal;
    }
    > th {
      border-bottom: 0;
      background-color: $table-head-bg;
      color: $table-head-color;
    }
    > td {
      background-color: $table-head-color;
      color: $table-head-bg;
      border-bottom: 1px solid $table-header-border;
    }
  }
  > tbody > tr > th {
    background-color: $table-bg-accent;
  }
  @include border-radius($table-border-radius);
  tr {
    &:first-child {
      > th, > td {
        &:first-child {
          @include border-radius($radius-top-left: $table-border-radius);
        }
        &:last-child {
          @include border-radius($radius-top-right: $table-border-radius);
        }
      }
    }
    &:last-child {
      > td, > th {
        &:first-child {
          @include border-radius($radius-bottom-left: $table-border-radius);
        }
        &:last-child {
          @include border-radius($radius-bottom-right: $table-border-radius);
        }
      }
    }
  }
  thead {
    > tr:last-child {
      > th, > td {
        &:first-child {
          @include border-radius($radius-bottom-left: 0);
        }
        &:last-child {
          @include border-radius($radius-bottom-right: 0);
        }
      }
    }
  }
  tbody {
    > tr:last-child {
      > th, > td {
        border-bottom: 0 none;
      }
    }
  }
}

// Bordered version

.table-bordered, .table-bordered-rows {
  border: 1px solid $table-cell-border;
  border-top: $table-border-width solid $table-footer-border;
  border-bottom: $table-border-width solid $table-footer-border;
  > thead > tr,
  > thead:first-child > tr:first-child {
    > th,
    > td {
      border: 1px solid $table-header-border;
    }
    > th:last-child, > td:last-child {
      border: 1px solid $table-header-border;
    }    
  }
}
.table-bordered {  
  > thead > tr,
  > thead:first-child > tr:first-child {
    > th,
    > td {
      border-left: 0 none;
      border-right: 1px solid $table-header-border;
    }
  }
  > tbody {
    > tr {
      > th,
      > td {
        border-left: 0 none;
        border-right: 1px solid $table-cell-border;
        &:last-child { border-right: 0; }
      }
    }
  }
}

// Zebra-striping

.table-striped {
  > tbody > tr:nth-of-type(even) {
    th { background-color: $table-bg-hover; }
    td { background-color: $table-bg-stripped; }
  }
  > tbody > tr:nth-of-type(odd) {
    td { background-color: $white; }
  }
}

.table-striped-double {
  > tbody {
    td { background-color: $white; }
  }
  > tbody > tr:nth-child(4n + 1) {
    th { background-color: $table-bg-hover; }
    td { background-color: $table-bg-stripped; }
  }
}

.table-nowrap {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th, > td {
        white-space: nowrap;
      }
    }
  }
}

.table-narrow {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th, > td {
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
  }
}

// ========== Media Queries ==========

//== phone
@media screen and (max-width: ($screen-tablet - 1)) {
  .table {
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > td {
          p { text-align: left; }
        }
      }
    }
  }
}

//== print
@media print {

  .table {
    border-color: $print-text-color;
    > thead > tr > th {
      border-bottom: 1px solid $print-text-color !important;
    }

    &-bordered {
      th, td {
        border: 1px solid $print-text-color !important;
      }
    }
  }

}