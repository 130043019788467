//== Header

.header {
  position: relative;
  height: $header-height;
  .logo {
    height: auto;
    width: $header-logo-width;
    height: $header-logo-height;
    overflow: hidden;
    margin-top: 20px;
  }
  .brand-strips {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: -1px;
    bottom: 0;
    &, img, svg { @include size(15px, $header-height); }
    img, svg { float: left; }
  }
  .menu-main {
    .navbar { margin-bottom: 0; }
  }
  &-border { border-bottom: 1px solid $header-border; }
  .form-inline { margin-bottom: 0; }
}

@if $header-background == transparent {
  .header { background: $header-background; }
}

//== List Languages

ol, ul {
  &.list-languages {
    margin: 12px 0 10px 0;
    li {
      line-height: 24px;
      margin-bottom: 0;
      a {
        color: $list-lang-link-color;
        .genicon { vertical-align: middle; }
      }
      &.active a,
      a:hover {
        color: $list-lang-link-hover-color;
      }
    }
    &.list-dividers {
      li:nth-child(n+2):before { color: $list-lang-divider-color; }
    }
  }
}


//== Header tools

.header-tools {
  .input-group {
    .form-control { height: 40px; }
  }
  .btn {
    height: 40px;
    &-default { margin-right: $size-tn-x; }
    &.btn-lines {
      font-size: $font-size-base - 2;
      strong {
        font-size: $font-size-base;
        text-transform: uppercase;
      }
    }
  }
}

.input-search-group {
  .form-control,
  .btn-search {
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-control {
    height: 40px;
    &, &:hover {
      border-right: 0 none;
    }
  }
  .btn-search {
    height: 40px;
    overflow: hidden;
    border: 1px solid $search-btn-border;
    border-left: 0 none;
    background-color: $btn-search-bg;
    @include clear-pseudo-content(after);
    .genicon {
      margin-right: 0;
      &:before {
        @include icon-size(24);
        color: $search-icon-color;
      }
    }
    &:hover .genicon:before {
      color: $search-icon-hover-color;
    }
  }
}

//== Tools

.block-tools {
  .tool {
    display: inline-block;
    position: relative;
    margin: 0 auto;
    padding: $size-nm-y $size-nm-x $size-nm-y (70 + $size-sm-x + $size-md-x);
    text-align: left;
    font-size: 24px;
    color: $tool-color;
    .genicon {
      @include icon-size(80);
      position: absolute;
      top: ($size-nm-y - 5);
      left: $size-nm-x;
      vertical-align: middle;
      &.icon-size-70 {
        @include icon-size(70);
        top: $size-nm-y;
      }
    }
    &:hover {
      color: $tool-active-color;
      .genicon:before {
        color: $tool-active-color;
      }
    }
  }
}

//== Content

.content {
  h1 { margin: 0 0 $size-lg-y 0; }
  &-header {
    padding: $size-lg-y 0;
    h1, h2, h3, h4, h5, h6 {
      margin: 0;
      line-height: $line-height-mobile;
    }
  }
}

//== Sidebar

.sidebar {

  .row {
    width: 100%;
    margin: 0;
  }

  .panel-login {
    .panel-body { padding: $panel-padding-md; }
    label.h3 { margin-bottom: $size-nm-y !important; }
    .checkbox { margin: $size-xs-y 0 $size-nm-y 0; }
    .btn { &.pull-right { float: none !important; } }
  }

}

//== Footer

.img-map {
  display: none;
  a { @include underline-not;}
  img {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    border: 0 none;
    &:hover { opacity: .9; }
  }
}
.footer-hp {
  border-top: 1px solid $footer-border;
}


// ========== Media Queries ==========

//== desktop +
@media screen and (min-width: $screen-desktop) {

  .header-tools {
    margin-top: 50px;
    .input-search-group {
      margin-right: ($size-tn-x - 4px);
    }
  }

  .link-facebook {
    display: block;
    @include square(24px);
    margin: 12px 0 10px $size-sm-x;
    color: $list-lang-link-color;
    &:hover { color: $list-lang-link-hover-color; }
  }

}

//== desktop
@media screen and (min-width: $screen-desktop) and (max-width: ($screen-lg-desktop - 1)) {

}

//== phone & tablet
@media screen and (max-width: ($screen-desktop - 1)) {

  .header {
    .container { width: 100%; }
    .logo {
      width: 185px;
      float: none !important;
      margin-top: ($size-sm-y + 4px);
      @include center-block;
      &, img, svg {
        width: 185px;
        height: 40px;
      }
    }
    .container .row .col-xs-12 { padding-right: 0; }
  }
  .cpifw .header {
    .container .row .col-xs-12 { padding-left: 0; }
  }

  .footer {
    .navbar-footer .navbar-nav {
      & {
        margin-top: $size-sm-y;
        margin-bottom: $size-sm-y;
        display: block;
        text-align: center;
      }
      & > li {
        display: inline-block;
        float: none;
        a {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  .link-facebook { display: none; }

  .header-tools {
    &.m-t-lg { margin-top: 0 !important; }
  }

  .menu-main {
    &.m-t-sm { margin-top: 0 !important; }
  }

  .sidebar { margin-top: $size-lg-y; }

  .motive-404 {
    padding: $size-nm-y 0 !important;
    img { margin: 0 auto; }
  }

}

//== tablet
@media screen and (min-width: $screen-tablet) and (max-width: ($screen-desktop - 1)) {

  .header {
    height: $tablet-header-height;
    .brand-strips {
      &, img, svg { @include size(14px, $tablet-header-height); }
    }
  }

  .form-inline .input-search-group {
    width: 100%;
    .input-group-btn,
    .btn {
      width: 50px;
    }
  }

  .block-tools {
    .tool {
      padding-left: 100px;
      padding-right: $size-sm-x;
      @include icon-text-size(72, 20px);
      .genicon {
        left: $size-sm-x;
        &.icon-size-70 {
          @include icon-size(68);
          top: ($grid-gutter-width / 2);
        }
      }
    }
  }

}

//== phone
@media screen and (max-width: ($screen-tablet - 1)) {

  .header {
    height: $phone-header-height;
    .brand-strips {
      &, img, svg { @include size(13px, $phone-header-height); }
    }
  }

}

//== print

@media print {

  body * {
    background-color: $print-bg-color;
    color: $print-text-color !important;
  }

  .container {
    width: 100% !important;
  }

  .header {
    height: 100px !important;
    margin-bottom: $size-md-y;
    border-bottom: 1px solid $print-border-color;
    padding: 0 !important;
    .container,
    .row,
    .col-xs-12 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .logo {
      text-align: center;
      &, img, svg {
        height: 40px !important;
        margin: 0 auto;
      }
    }
  }

  .footer {
    margin-top: $size-md-y;
    border-top: 1px solid $print-border-color;
    .container,
    .navbar-footer,
    .navbar-footer .navbar-nav,
    .navbar-footer .navbar-nav > li,
    .navbar-footer .navbar-nav > li > span {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .navbar {
      display: block;
      .pull-right {
        float: none !important;
        > li {
          @include clear-pseudo-content(after);
          > span { margin-left: -($grid-gutter-width / 2) }
        }
      }
    }
  }

}
