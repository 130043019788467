//== Fund courses

.fund-courses {
  &.table tr td {
    padding-top: $size-nm-y;
    padding-bottom: $size-nm-y;
  }
  thead.thead-light {
    tr > td,
    tr > th {
      background-color: $white;
      color: $brand-primary;
      border-bottom: 1px solid $gray;
    }
  }
  tbody {
    tr > td {
      &.td-icon {
        width: 1%;
        vertical-align: middle;
      }
      &.td-fund {}
      &.td-state { text-align: center; }
      &.td-value { vertical-align: middle; }
    }
  }
  .fund-icon {
    @include underline-not;
    .genicon:before {
      @include icon-size(48);
      color: $fund-icon-color;
    }
  }
  .fund-title {
    margin: $size-tn-y 0 $size-sm-y 0;
    font-size: $font-size-large;
    font-weight: normal;
  }
  .fund-text {
    margin: 0;
    font-size: $font-size-base;
  }
  .fund-state {
    .genicon:before {
      @include icon-size(24);
      margin-right: $size-tn-x;
      vertical-align: middle;
    }
    .fund-difference {
      font-size: $font-size-large;
      font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */
    }
    color: $fund-state-color;
    &.growth { color: $fund-growth-color; }
    &.decline { color: $fund-decline-color; }
  }
  .fund-value {
    font-size: $font-size-large;
    font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */
    color: $fund-value-color;
  }
  @media screen and (max-width: ($screen-tablet - 1)) {
    thead,
    tbody {
      tr {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    thead {
      tr > th {
        &.w-60pr {
          width: 100% !important;
          padding-bottom: 0;
          border-bottom: 0 none;
        }
        &.w-20pr { width: 50% !important; }
      }
    }
    tbody {
      tr > td {
        &.td-icon { width: 20%; }
        &.td-fund { width: 80%; }
        &.td-icon,
        &.td-fund {
          border-bottom: 0 none;
          padding-bottom: $size-sm-y;
        }
        &.td-state,
        &.td-value {
          width: 50%;
          padding-top: 0;
        }
      }
    }
  }
}

//== Fund carousel

.fund-selection {
  .panel-body {
    padding: $size-md-x;
  }
  .carousel {
    &-inner {
      min-height: 500px;
    }
    &-indicators {
      bottom: -$size-md-y;
      > li {
        @include square(12px);
        border: 1px solid $brand-primary;
        &.active {
          margin: 0;
          background-color: $brand-primary;
        }
      };
    }
    &-control {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      @include square(40px);
      margin-top: -16px;
      background-color: $carousel-control-bg;
      &.left { left: -$size-md-x; }
      &.right { right: -$size-md-x; }
      .genicon {
        position: relative;
        top: 0;
        margin-top: 0;
        background-color: transparent;
      }
    }
  }
  //== Phone
  @media screen and (max-width: ($screen-tablet - 1)) {
    & {
      width: calc(100% + 30px);
      margin-left: -$size-sm-x;
      margin-right: -$size-sm-x;
    }
    .panel-body {
      padding: $size-nm-x $size-nm-x $size-md-x $size-nm-x;
    }
    .carousel-control {
      &.left { left: -$size-nm-x; }
      &.right { right: -$size-nm-x; }
    }
  }
}

//== Fund tags

.fund-section {
  .btn-info {
    background-color: $gray-lighter !important;
    color: $sw1-color5;
    border-color: $gray-light;
    &:after { display: none !important; }
  }
}
.fund-chart {
  min-height: 400px;
  margin-bottom: $size-md-y;
  background-color: $gray-lighter;
}
.fund-tag {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: auto;
  height: 40px;
  padding: $size-sm-x $size-sm-y;
  color: $white;
  .title {
    font-family: $font-family-bold;
    font-size: 14px;
  }
  .genicon { color: $white; }
  > .genicon { margin-right: $size-tn-x; }
  .close {
    &, .genicon {
      display: block;
      width: 12px;
      height: 12px;
      line-height: 12px;
    }
    .genicon:before {
      @include icon-size(12);
      float: left;
    }
    margin-left: $size-tn-x;
  }
}
.fund-tags {
  @include clearfix;
  .fund-tag {
    border-radius: $border-radius-base;
    margin-bottom: $size-sm-y;
    &:nth-child(1) { background-color: $fund-tag1; }
    &:nth-child(2) { background-color: $fund-tag2; }
    &:nth-child(3) { background-color: $fund-tag3; }
    &:nth-child(4) { background-color: $fund-tag4; }
    &:nth-child(5) { background-color: $fund-tag5; }
  }
  &-right {
    margin-right: $size-nm-x;
    .btn-info { width: 100%; }
  }
  &-down {
    .fund-tag {
      float: left;
      margin-right: $size-tn-x;
    }
  }
}

.modal .fund-box {
  height: 400px;
  overflow: hidden;
  overflow-y: auto;
  .radio {
    margin: $size-sm-y 0;
    .genicon {
      vertical-align: middle;
      margin-right: $size-tn-x;
    }
  }
}

//== TOP funds

.top-funds {
  .fund {
    & {
      padding: $size-sm-y 0;
      border-bottom: 1px solid $gray-light;
    }
    &-title {
      display: block;
      margin-bottom: $size-tn-y;
    }
    &-information {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-items: space-between;
      align-items: center;
      color: $gray-darker;
      .fund-chart {
        display: block;
        width: 80px;
        height: 24px;
        margin-right: $size-tn-x;
      }
      .fund-stock { margin-left: auto; }
      .stock-value {
        display: inline-block;
        width: 60px;
        text-align: right;
      }
      .stock-movement {
        &.same { color: $gray-darker; }
        &.growth { color: $brand-success; }
        &.fall { color: $brand-danger; }
        .genicon {
          vertical-align: middle;
          margin-left: $size-tn-x;
        }
      }
    }
  }
}

.nodeChildBox { display: none !important; }