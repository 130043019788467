//
// Progress bars
// --------------------------------------------------


//== 1) Progress bar

.progress {
  position: relative;
  @include box-shadow(none);
}
.progress-bar {
  @include box-shadow(none);
  .sr-only {
    position: absolute;
    width: auto;
    height: auto;
    line-height: $line-height-computed + 4;
    left: 0;
    right: 0;
    clip: auto;
    font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */
  }
}
.progress, .progress-bar {
  height: $slider-height;
}

.progress-striped .progress-bar,
.progress-bar-striped {
  @include gradient_striped(rgba(255,255,255,.25), 125deg);  
  background-size: 25px 35px;
}

//== 2) Indicator

.indicators {
  &.elements-0-0-0 {
    > .col-sm-6 { display: none; }
  }
  &.elements-1-0-0 {
    > .col-sm-6 { display: none; }
    > .col-sm-6:nth-child(1) { display: block; }
  }
  &.elements-1-1-0 {
    > .col-sm-6 { display: block; }
    > .col-sm-6:nth-child(3) { display: none; }
  }
  &.elements-0-1-0 {
    > .col-sm-6 { display: none; }
    > .col-sm-6:nth-child(2) { display: block; }
  }
  &.elements-0-1-1 {
    > .col-sm-6 { display: block; }
    > .col-sm-6:nth-child(1) { display: none; }
  }
  &.elements-1-0-1 {
    > .col-sm-6 { display: block; }
    > .col-sm-6:nth-child(2) { display: none; }
  }
  &.elements-1-1-1 {
    > .col-sm-6 { display: block; }
  }
  &.elements-1-0-0,
  &.elements-0-1-0,
  &.elements-0-0-1,
  &.elements-1-1-0,
  &.elements-0-1-1,
  &.elements-1-0-1 {
    > .col-sm-6 { left: auto !important; }
  }
  @media screen and (min-width: $screen-lg-min) {
    &.elements-1-0-0,
    &.elements-0-1-0,
    &.elements-0-0-1 {
      width: 33.3333%;
      margin-left: auto;
      margin-right: auto;
      > .col-sm-6 { width: 100%; }
    }
    &.elements-1-1-0,
    &.elements-0-1-1,
    &.elements-1-0-1 {
      width: 66.66666%;
      margin-left: auto;
      margin-right: auto;
      > .col-sm-6 { width: 50%; }
    }
  }
}

.progress-indicator {  
  position: relative;
  padding: 32px 0 28px 0;
  .progress { margin-bottom: 0; }
  .progress, .progress-bar { height: $slider-height-sm; }
  .progress-bar { position: absolute; }
}

.progress-meters {
  ul {
    position: absolute;
    left: 0;
    right: 0;
    @include reset-list(left);
    li {
      margin: 0;
      text-align: center;
      font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */
    }
  }
  .progress-ticks {
    &-top { top: 0; }    
    &-bottom { bottom: 25px; }
    li {
      height: $line-height-computed;
      /* overflow: hidden; */
      position: relative;
      &:after {
        display: block;
        width: 1px;
        height: 6px;
        position: absolute;
        left: 50%;
        bottom: -8px;
        margin-left: -1px;
        content: "";
        background-color: $progress-ticks-color;
      }
      &:first-child:after, &:last-child:after { width: 3px; }
    }  
  }
  .progress-scope {
    bottom: 0;
    margin-left: 0;
    li {
      //border-right: 1px solid $gray-light;
      &:last-child { border-right: 0 none; } 
    } 
  }
  .progress-visible { overflow: hidden; } 
}

//== 3) Progress slider

.progress-slider {
  display: inline-block;
  width: 100%;
  height: $slider-height-sm + (2 * 32);
  overflow: hidden;
  position: relative;
  margin-bottom: 0;
  padding: 0 ($size-sm-y * 2);
  vertical-align: middle;
  
  .progress {
  	height: $slider-height-sm;
    overflow: visible;
    margin: 32px 0;
    .progress-bar {	height: $slider-height-sm; }
    .progress-value {
      position: absolute;
      top: -18px;
      .genicon-marker {
        width: 200px;
        position: absolute;
        top: 46px;
        left: -100px;
        text-align: center;
        font-family: $font-family-base !important;
        font-weight: bold !important;
        color: $progress-color;
        &:before, &:after {
          @include genicon-styles;
          @include icon-size(44);
          width: 44px;
          position: absolute;
          top: -59px;
          left: 78px;
          cursor: pointer;
        }
        &:before { 
          z-index: $zindex-layer-2;
          color: $progress-marker-color;
        }
        &:after {
          z-index: $zindex-layer-1;
          @include genicon-styles;
          content: genicon-char(marker-shape);
          color: $progress-marker-bg;
        }
      }
    }
  }
  
  .progress-meters {
    padding: 0 ($size-sm-y * 2);
    ul { left: $size-sm-y * 2; }
    .progress-ticks { bottom: 30px; }
  }
}


// ========== Media Queries ==========

//== desktop +
@media screen and (min-width: $screen-desktop) {

}

//== desktop
@media screen and (min-width: $screen-desktop) and (max-width: ($screen-lg-desktop - 1)) {

}

//== phone & tablet
@media screen and (max-width: ($screen-desktop - 1)) {
  .indicators {
    .progress-indicator {
      max-width: 300px;
      margin: 0 auto;
    }
  }
}

//== tablet
@media screen and (min-width: $screen-tablet) and (max-width: ($screen-desktop - 1)) {

}

//== phone
@media screen and (max-width: ($screen-tablet - 1)) {

}
