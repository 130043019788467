//
// Navs
// --------------------------------------------------

// Tabs

.nav-tabs {

  border-bottom: 1px solid $tab-line-color;
  margin-bottom: $size-md-y;

  > li {
    @include clear-pseudo-content;
    > a {
      margin-right: $tab-margin-right;
      padding: ceil($tab-padding / 2) $tab-padding;
      border: 1px solid $tab-border;
      border-bottom-color: $tab-line-color;
      font-size: $font-size-middle;
      text-decoration: none;
      background-color: $tab-bg;
      color: $tab-color;
      &:hover {
        border-color: $tab-hover-border;
        border-bottom-color: $tab-line-color;
        background-color: $tab-hover-bg;
        color: $tab-hover-color
      }
      .genicon { vertical-align: middle; }
      &:last-child a { margin-right: 0; }
    }
    &.active > a {
      &,
      &:hover,
      &:focus {
        border-color: $tab-active-border;
        border-bottom-color: $tab-line-color;
        background-color: $tab-active-bg;
        color: $tab-active-color;
        cursor: default;
      }      
    }
    &.disabled > a {
      &,
      &:hover,
      &:focus {
        @include opacity(1);
        border-color: $tab-disabled-border;
        border-bottom-color: $tab-line-color;
        background-color: $tab-disabled-bg;
        color: $tab-disabled-color;
        cursor: default;
      }      
    }    
  }

  &-md {
    > li > a { font-size: $font-size-large; }
  }
  &-lg {
    > li > a {
      font-size: $font-size-h3;
      .genicon { @include icon-size(40); }
    }
  }

  &.tabs-center {
    text-align: center;
    > li {
      display: inline-block;
      float: none;
    }
  }

  &.tabs-type2 {
    > li {
      > a {
        border-color: $tab-type2-border;
        border-bottom-color: $tab-line-color;
        background-color: $tab-type2-bg;
        color: $tab-type2-color;
        &:hover {
          border-color: $tab-type2-hover-border;
          border-bottom-color: $tab-line-color;
          background-color: $tab-type2-hover-bg;
          color: $tab-type2-hover-color;
        }
      }
      &.active > a {
        &,
        &:hover,
        &:focus {
          border-color: $tab-type2-active-border;
          border-bottom-color: $tab-line-color;
          background-color: $tab-type2-active-bg;
          color: $tab-type2-active-color;
        }
      }
    }
  }

  &.tabs-slim {
    border-bottom: 0;
    > li {
      margin-left: $size-tn-y;
      margin-right: $size-tn-y;
      > a {
        border-radius: 0;
        background-color: transparent;
        color: $tab-color;
        &, &:hover, &:focus {
          border-top-width: 3px;
          border-left: 0 none;
          border-right: 0 none;
          border-bottom: 0 none;
        }
      }
      &.active > a {
        &, &:hover, &:focus {
          border-color: $tab-slim-active-border;
          background-color: transparent;
          color: $tab-color;
        }
      }
      &:not(.active) {
        &:hover, &:focus { color: $tab-active-border; }
      }
    }
  }

}


// ========== Media Queries ==========

//== Tablet and small Desktop
@media screen and (min-width: $screen-tablet) and (max-width: ($screen-lg-desktop - 1)) {
  .nav-tabs {
    &-md {
      > li > a { font-size: $font-size-middle; }
    }
    &-lg {
      > li > a {
        font-size: ($font-size-h3 - 4);
        .genicon { @include icon-size(32); }
      }
    }
  }
}

//== phone
@media screen and (max-width: ($screen-tablet - 1)) {
  .nav-tabs {
    &-md {
      > li > a { font-size: $font-size-middle; }
    }
    &-lg {
      > li {
        &.w-50pr { width: 100% !important; }
        > a {
          font-size: ($font-size-h3 - 6px);
          .genicon { @include icon-size(24); }
        }
      }
    }
  }
}

//== print
@media print {

  .nav-tabs > li.active > a {
    border-color: $print-text-color !important;
    z-index: $zindex-layer-1;
  }

}
