//
// GENERAL profile variables
// --------------------------------------------------

//== Colors
$color-white:                       #ffffff;
$color-black:                       #000000;

//== Scaffolding

$link-decoration:                   underline;
$link-hover-decoration:             none;


//== Typography ==

$font-family-sans-serif:            "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base:                  $font-family-sans-serif;
$font-family-light:                 "HelveticaNeueW02-45Ligh", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-bold:                  "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-base:                    14px;
$font-size-huge:                    ceil(($font-size-base * 1.6));  // ~23px
$font-size-large:                   ceil(($font-size-base * 1.25)); // ~18px
$font-size-middle:                  ceil(($font-size-base * 1.10)); // ~16px
$font-size-small:                   ceil(($font-size-base * 0.90)); // ~13px

$font-size-h1:                      floor(($font-size-base * 3.45)); // ~48px
$font-size-h2:                      floor(($font-size-base * 2.3)); // ~32px
$font-size-h3:                      ceil(($font-size-base * 1.8)); // ~26px
$font-size-h4:                      ceil(($font-size-base * 1.10)); // ~16px
$font-size-h5:                      $font-size-base; // ~14px
$font-size-h6:                      ceil(($font-size-base * 0.90)); // ~13px

$line-height-base:                  1.428571429; // 20/14
$line-height-large:                 1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-computed:              floor(($font-size-base * $line-height-base)); // ~20px
$line-height-small:                 1.5;
$line-height-tiny:                  1.1;
$line-height-equal:                 1;
$line-height-common:                1.7;
$line-height-mobile:                40px;

$paragraph-align:                   justify;


//== Z-index master list

$zindex-bg:                         -1;
$zindex-base:                       0;
$zindex-select:                     1;
$zindex-layer-1:                    100;
$zindex-layer-2:                    150;
$zindex-layer-3:                    200;


//== Sizes ==

$size-items-1:                      100% / 1; //100%
$size-items-2:                      100% / 2; //50%
$size-items-3:                      100% / 3; //33.3%
$size-items-4:                      100% / 4; //25%
$size-items-5:                      100% / 5; //20%
$size-items-6:                      100% / 6; //16.5%
$size-items-7:                      100% / 7; //14.2%
$size-items-8:                      100% / 8; //12.5%
$size-items-9:                      100% / 9; //11.1%
$size-items-10:                     100% / 10; //10%

$size-tn-factor:                    4;
$size-xs-factor:                    3;
$size-sm-factor:                    2;
$size-nm-factor:                    1;
$size-md-factor:                    1.5;
$size-lg-factor:                    2;
$size-hg-factor:                    3;

$size-tn-x:                         ceil(($grid-gutter-width / $size-tn-factor));
$size-xs-x:                         ceil(($grid-gutter-width / $size-xs-factor));
$size-sm-x:                         ceil(($grid-gutter-width / $size-sm-factor));
$size-nm-x:                         ceil(($grid-gutter-width * $size-nm-factor));
$size-md-x:                         ceil(($grid-gutter-width * $size-md-factor));
$size-lg-x:                         ceil(($grid-gutter-width * $size-lg-factor));
$size-hg-x:                         ceil(($grid-gutter-width * $size-hg-factor));

$size-tn-y:                         ceil(($line-height-computed / $size-tn-factor));
$size-xs-y:                         ceil(($line-height-computed / $size-xs-factor));
$size-sm-y:                         ceil(($line-height-computed / $size-sm-factor));
$size-nm-y:                         ceil(($line-height-computed * $size-nm-factor));
$size-md-y:                         ceil(($line-height-computed * $size-md-factor));
$size-lg-y:                         ceil(($line-height-computed * $size-lg-factor));
$size-hg-y:                         ceil(($line-height-computed * $size-hg-factor));

$padding-xs-horizontal:             $size-tn-x; /* 5px; */
$padding-xs-vertical:               $size-tn-y; /* 1px; */

$padding-small-horizontal:          $size-xs-x; /* 8px; */
$padding-small-vertical:            $size-xs-y; /* 5px; */

$padding-base-horizontal:           $size-sm-x; /* 10px; */
$padding-base-vertical:             $size-sm-y; /* 6px; */

$padding-middle-horizontal:         $size-nm-x; /* 12px; */
$padding-middle-vertical:           $size-nm-y; /* 8px; */

$padding-large-horizontal:          $size-md-x; /* 16px; */
$padding-large-vertical:            $size-md-y; /* 12px; */

//== Panels ==

$panel-padding-sm:                  15px;
$panel-padding:                     20px;
$panel-padding-md:                  25px;
$panel-padding-lg:                  30px;


//== Iconography

$genfwicons-font-path:              "../fonts/icons/";
$genfwicons-icon-font-name:         "icons";
$genfwicons-icon-font-svg-id:       "icons";
$cezetmap-font-path:                "../fonts/cezetmap/";
$cezetmap-icon-font-name:           "cezetmap";
$genicon-empty-content:             "";

// icon sizes
$icon-sizes: 6, 8, 10, 12, 16, 18, 20, 22, 24, 28, 32, 48, 56, 64, 72, 80, 96, 128, 256, 512, 1024;


//== Forms

$line-height-icheck:                $input-icheck-size;
$input-height-base:                 ($line-height-computed + ($size-sm-y * 2));
$input-height-large:                (ceil($font-size-large * $line-height-large) + ($size-nm-y * 1.5));
$input-height-small:                (ceil($font-size-small * $line-height-small) + ($size-tn-y * 2));


//== Breadcrumbs

$breadcrumb-padding-vertical:       0;
$breadcrumb-padding-horizontal:     $size-xs-x;


//== Pagination

$pagination-icon-size-nm:           16;
$pagination-icon-size-sm:           12;
$pagination-icon-size-lg:           24;


//== Tooltips

$tooltip-max-width:                 200px;
$tooltip-opacity:                   .9;
$tooltip-arrow-width:               10px;


//== Popovers

$popover-max-width:                 276px;
$popover-arrow-width:               10px;


//== Jumbotron

$jumbotron-height:                  360px;
$jumbotron-height-header:           250px;
$jumbotron-padding:                 30px;
$jumbotron-padding-lg:              40px;
$jumbotron-padding-sm:              60px;
$jumbotron-padding-xs:              50px;


//== Carousels


//== Modals

$modal-inner-padding-sm:            20px;
$modal-inner-padding:               30px;
$modal-map-padding:                 $size-sm-x;
$modal-backdrop-opacity:            .5;

$modal-sm-width:                    298px;
$modal-nm-width:                    498px;
$modal-md-width:                    638px;
$modal-lg-width:                    798px;
$modal-hg-width:                    938px;

//== Slider

$slider-height:                     20px;
$slider-height-sm:                  12px;


//== Layout

$header-height:                     140px;
$tablet-header-height:              130px;
$phone-header-height:               120px;

//== Navbar

// Basics of a navbar
$navbar-height:                     50px;
$navbar-margin-bottom:              0;
$navbar-border-radius:              $border-radius-base;
$navbar-padding-horizontal:         floor(($grid-gutter-width / 2));
$navbar-padding-vertical:           (($navbar-height - $line-height-computed) / 2);
$navbar-collapse-max-height:        340px;
$navbar-sidebar-width:              320px;

// Main menu
$nav-link-padding-x:                15px;
$nav-link-padding-y:                10px;


//== Map

$map-point-size:                    50px;
$map-icon-size:                     32;


//== Calendar

$calendar-icon-size:                32;


//== Print

$print-border-color:                $gray;
$print-text-color:                  $black;
$print-bg-color:                    $white;
$print-highlight-color:             $gray-light;

//== Animation

$trans-base: all 0.3s ease;
$trans-slow: all 0.6s ease;


// ========== Media Queries ==========

/* ---------- Screen ---------- */

$screen-tn:                       375px;
$screen-tn-min:                   0;
$screen-tn-max:                   ($screen-xs-min - 1);

/*
$screen-xs:                       480px;
$screen-xs-min:                   $screen-xs;

$screen-sm:                       768px;
$screen-sm-min:                   $screen-sm;

$screen-md:                       992px;
$screen-md-min:                   $screen-md;

$screen-lg:                       1200px !default;
$screen-lg-min:                   $screen-lg !default;

$screen-xs-max:                   ($screen-sm-min - 1) !default;
$screen-sm-max:                   ($screen-md-min - 1) !default;
$screen-md-max:                   ($screen-lg-min - 1) !default;
*/

//== desktop +
@media screen and (min-width: $screen-lg-min) {

}

//== desktop
@media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {

}

//== <= tablet
@media screen and (max-width: $screen-sm-max) {

}

//== tablet
@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

}

//== <= phone
@media screen and (max-width: $screen-xs-max) {

}

//== phone
@media screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {

}

//== <= phone
@media screen and (max-width: $screen-tn-max) {

}

//== print
@media print {

}
