//
// Jumbotron
// --------------------------------------------------

.container,
.container-fluid {
  .jumbotron,
  .jumbotron-motive,
  .jumbotron-filter,
  .jumbotron-polygon {
    @include border-radius($border-radius-large);
    @include opacity(.99);
  }
}

.jumbotron {

  position: relative;
  overflow: hidden;
  padding: $jumbotron-padding;

  h1, h2, p { color: $jumbotron-heading-color; }
  h1, .h1, h2, .h2 { margin-top: $size-sm-y; }
  h2, .h2 { margin-bottom: $size-sm-y; }

  .container {
    position: relative;
    height: 100%;
  }

  &-motive {
    @include img-center;
    img {
      width: auto;
      min-width: auto;
      min-height: 50%;
    }
  }
  &-motive-wrapper {
    display: block;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-filter,
  &-polygon {
    @include filter;
  }

  &-content {
    position: relative;
    left: $jumbotron-padding;
    right: $jumbotron-padding;
    z-index: 120;
  }

  &-main,
  &-header {
    padding: $jumbotron-padding-lg 0;
    .jumbotron-content {
      left: floor(($grid-gutter-width / 2));
      right: floor(($grid-gutter-width / 2));
    }
    h1, .h1 {
      margin-bottom: ceil($jumbotron-padding-lg / 2);
      font-size: $jumbotron-heading-font-size-lg !important;
    }
    p {
      margin-bottom: ceil($jumbotron-padding-lg / 2);
      font-size: $jumbotron-font-size-lg;
    }
  }

  &-main {
    box-sizing: border-box;
    height: $jumbotron-height;
    margin-bottom: 0;
    .banner-main {
      height: $jumbotron-height;
      margin-top: -$jumbotron-padding-lg;
      padding:  $jumbotron-padding-lg 0;
    }
    .jumbotron-content { bottom: 0; }
    h1, .h1 { font-weight: normal; }
    h1.jumbotron-title {
      font-family: $font-family-light;
      &-sm { font-size: 36px !important; }
    }
    h1.jumbotron-claim { // just for GEN
      margin-top: 0;
      font-family: $font-family-bold;
      font-size: $font-size-h2 !important;
      text-align: left;
      strong {
        display: inline-block;
        padding: $size-sm-y $size-nm-x;
        color: $white;
        &.line1 { background-color: $sw1-color3; }
        &.line2 { background-color: $sw1-color2; }
        &.line3 { background-color: $sw1-color4; }
      }
    }
    h2, .h2 { font-weight: normal; }
    p.jumbotron-text {
      width: 60%;
      &-lg {
        font-size: $font-size-h3 !important;
        line-height: $line-height-base !important;
      }
    }
    .jumbotron-box {
      display: block;
      padding: $size-nm-x;
    }
    @media screen and (min-width: $screen-sm-min) {
      padding: $jumbotron-padding-lg;
      .container, .container-fluid { padding: 0; }
      .container &, .container-fluid & {
        padding:  $jumbotron-padding-lg;
      }
    }
  }

  .cpifw & {
    &-main {
      padding: $jumbotron-padding-lg;
      .banner-main {
        &, .container, &.container { padding: 0; }
      }
    }
  }

  &-header {
    /*&-fund,
    &-product {*/
      height: 250px;
      overflow: hidden;
      margin-bottom: 0;
    //}
    &-fund {
      padding: $jumbotron-padding 0;
      .jumbotron-content { height: 200px; }
      h1, .h1 { margin: 0 0 $size-nm-y !important; }
      p {
        &.fund-name-oficial,
        &.fund-name-previous {
          margin-bottom: $size-xs-y;
          line-height: $line-height-small;
          text-align: left;
        }
        &.fund-name-previous a { color: $jumbotron-link-color; }
      }
    }
  }

  &-funds {
    height: 400px;
    margin-bottom: 0;
    padding: $jumbotron-padding-lg;
    .jumbotron-content {
      left: $jumbotron-padding-lg;
      right: $jumbotron-padding-lg;
    }
    .jumbotron-filter { @include opacity(.7); }
    .jumbotron-motive {
      &-wrapper { height: 320px; }
      img { left: -25%; }
    }
    h2 {
      margin: 0 0 $size-nm-y 0;
      > a {
        display: block;
        color: $jumbotron-heading-color;
      }
    }
    .fund-link {
      position: relative;
      padding-left: 55px;
      margin-bottom: $size-lg-y;
      .genicon {
        position: absolute;
        top: 0;
        left: 0;
        @include icon-size(36);
      }
      a {
        font-size: $font-size-middle;
        text-transform: uppercase;
        color: $jumbotron-link-color;
      }
    }
    .jumbotron-nav {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 130;
      padding: $jumbotron-padding;
      background-color: $jumbotron-funds-nav-bg;
      .pull-left {
        > * {
          float:left;
          line-height: $input-height-small;
          margin-right: $padding-base-horizontal;
        }
        nav {
          line-height: 1em;
        }
        a.personal {
          text-decoration: none;
          color: $jumbotron-link-color;
          > .genicon { @include icon-size(24); }
        }
        .pagination { margin: 0; }
      }
      a.pull-right {
        line-height: $input-height-small;
        color: $jumbotron-funds-link-color;
      }
    }
    @media screen and (min-width: $screen-sm-min) {
      padding: $jumbotron-padding-lg;
      .container &, .container-fluid & {
        padding:  $jumbotron-padding-lg;
      }
    }
  }

  &-banner {
    p { margin-bottom: $jumbotron-padding-sm; }
    .jumbotron-content {
      top: $jumbotron-padding-sm;
      bottom: $jumbotron-padding-sm;
      .carousel-indicators {
        margin-bottom: 0;
        bottom: $size-nm-y;
      }
      .jumbotron-link {
        position: absolute;
        top: -$jumbotron-padding-sm;
        bottom: -$jumbotron-padding-sm;
        left: -$jumbotron-padding;
        right: -$jumbotron-padding;
      }
      p { font-size: $font-size-middle; }
    }
  }

  &-sidebar {
    padding: $jumbotron-padding-sm;
    h2, .h2 {
      margin-bottom: ceil($jumbotron-padding / 2);
      font-size: $jumbotron-heading-font-size-sm;
    }
    p {
      margin-bottom: ceil($jumbotron-padding / 2);
      text-align: left;
      font-size: $jumbotron-font-size-sm;
    }
    .jumbotron-content {
      left: $jumbotron-padding-sm;
      right: $jumbotron-padding-sm;
    }
    @media screen and (min-width: $screen-sm-min) {
      padding: $jumbotron-padding-sm;
      .container &, .container-fluid & {
        padding:  $jumbotron-padding-sm;
      }
    }
  }

  &-signpost {
    $height: 123px;
    height: $height;
    margin-bottom: 16px;
    .jumbotron-title {
      display: block;
      font-size: $font-size-h3;
      &.jumbotron-lines { line-height: 1.1em; }
    }
    a {
      @include icon-text-size(52, 32px);
      display: block;
      height: $height;
      position: absolute;
      top: -$jumbotron-padding;
      left: -$jumbotron-padding;
      bottom: -$jumbotron-padding;
      right: -$jumbotron-padding;
      padding: $jumbotron-padding;
      line-height: 64px;
      vertical-align: middle;
      text-decoration: none;
      font-size: $font-size-h3;
      color: $jumbotron-link-color;
      .genicon {
        float: left;
        margin-top: $size-tn-y;
        margin-right: $size-sm-x;
        &.icon-size-72 {
          margin-top: $size-tn-y;
          @include icon-size(72);
        }
        &.icon-size-64 {
          margin-top: $size-tn-x;
          @include icon-size(64);
        }
      }
    }
    &:hover { @include opacity(.8); }

    &.jumbotron-banner {
      padding: 0;
      .jumbotron-title {
        display: inline-block;
        margin-top: 0;
        text-align: left;
      }
      .jumbotron-content, a {
          position: absolute !important;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
      }
      a {
        text-align: center;
        .valign-middle { margin: 0 auto; }
        .genicon {
          &.icon-size-72,
          &.icon-size-64 {
            float: none;
            margin-top: 0;
            margin-right: $size-sm-x;
            vertical-align: top;
          }
        }
      }
      @media screen and (max-width: $screen-md-max) {
        .jumbotron-title { text-align: center; }
        a .genicon {
          &.icon-size-72,
          &.icon-size-64 {
            display: block !important;
            margin: 0 auto $size-sm-y auto;
            clear: both;
          }
        }
      }
      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        .jumbotron-title { font-size: $font-size-large; }
        a .genicon {
          &.icon-size-72,
          &.icon-size-64 {
            &, &:before { @include icon-size(48); }
          }
        }
      }
      @media screen and (max-width: $screen-sm-max) {
        .jumbotron-title { font-size: $font-size-huge; }
      }
      @media screen and (max-width: $screen-xs-max) {
        .jumbotron-content, a {
          position: relative !important;
        }
        a .genicon {
          &.icon-size-72,
          &.icon-size-64 {
            &, &:before { @include icon-size(56); }
          }
        }
      }
    }

  }

  &-graph {
    position: absolute;
    top: -$size-md-y;
    left: -$size-nm-y;
    right: -$size-nm-y;
    .jumbotron-graph-head {
      @include clearfix;
      margin-bottom: $size-sm-y;
      h2 {
        margin: 0;
        line-height: $line-height-base;
        font-weight: bold;
        font-size: $font-size-h4;
      }
      a {
        line-height: $line-height-base;
        color: $jumbotron-funds-link-color;
      }
    }
    .jumbotron-graph-content {
      padding: $size-sm-y;
      background-color: $graph-bg;
    }
    .highcharts-container {
      width: 665px;
      height: 205px;
      overflow: hidden;
      margin-top: $size-xs-y;
    }

  }

  &-header {
    &-fund {
      @include stock-info-set(150px, 120, 56px, 48, true);
      .stock-movement {
        .genicon { vertical-align: middle; }
      }
    }
    p.product-icon {
      margin-top: $size-md-y;
      .genicon {
        float: right;
        @include icon-size(124);
      }
    }
  }

}

.content-header-fund {
  margin-bottom: $size-lg-y;
  h1, .h1 { margin: 0 0 $size-md-y !important; }
  p {
    &.fund-name-oficial,
    &.fund-name-previous {
      margin-bottom: $size-sm-y;
      line-height: $line-height-small;
      text-align: left;
    }
    font-size: $font-size-large;
    &.fund-name-previous a { color: $link-color; }
  }
}

// ========== Media Queries ==========

//== desktop +
@media screen and (min-width: $screen-desktop) {

  .jumbotron {

    &-header {
      &-fund {
        @include stock-info-set(120px, 96, 64px, 48, true);
        .stock-info { margin-top: $size-lg-y; }
        h1, .h1 {
          margin-top: $size-xs-y !important;
          font-size: 28px !important;
        }
        .fund-name-oficial { font-size: 28px; }
      }
    }
  }

}

//== desktop
@media screen and (min-width: $screen-desktop) and (max-width: ($screen-lg-desktop - 1)) {

  .jumbotron {
    &-header {
      &-fund {
        h1, .h1 {
          margin-top: $size-xs-y !important;
          font-size: 24px !important;
        }
        p.fund-name-oficial { font-size: 24px; }
      }
    }
  }

  .col-md-8 {
    .jumbotron {
      &-funds {
        padding: $jumbotron-padding;
        .col-sm-8,
        .col-sm-4 {
          width: 100%;
        }
        .jumbotron-content {
          left: $jumbotron-padding;
          right: $jumbotron-padding;
          h2 { font-size: $font-size-h3; }
        }
        .fund-link {
          position: relative;
          float: left;
          padding-left: 55px;
          margin-top: $size-nm-y;
          margin-bottom: 0;
          &:first-child { width: 60%; }
          &:last-child { width: 40%; }
        }
      }
    }
  }
  .col-md-4 {
    .jumbotron {
      &-signpost {
        a {
          @include icon-text-size(48, 20px);
          .genicon { margin-top: $size-sm-y; }
        }
      }
    }
  }

  p {
    text-align: left;
    font-weight: normal;
  }

}

@media screen and (max-width: ($screen-lg-desktop - 1)) {
  .jumbotron {
    &-header {
      @include stock-info-set(110px, 84, 42px, 36, true);
      .stock-info {
        p.stock-value { font-size: 26px; }
        p.stock-force { font-size: 13px; }
      }
    }
  }
}


//== phone & tablet
@media screen and (max-width: ($screen-desktop - 1)) {

  .jumbotron {

    padding: $jumbotron-padding;
    .container &,
    .container-fluid & {
      padding: $jumbotron-padding;
    }

    &-main,
    &-header {
      padding: ($jumbotron-padding + $size-sm-y) $jumbotron-padding !important;
      .banner-main {
        margin-top: -($jumbotron-padding + $size-sm-y);
        padding: ($jumbotron-padding + $size-sm-y) 0 !important;
      }
      .jumbotron-text { width: 100%; }
      h1, .h1 { font-size: $font-size-h3 !important; }
    }

    &-header {
      &-fund,
      &-product {
        position: relative;
        overflow: visible;
        margin-bottom: 0 !important;
      }
      &-fund {
        // $jumbotron-description-height: 300px;
        $jumbotron-value-height: 150px;
        padding: $jumbotron-padding $jumbotron-padding-sm !important;
        .jumbotron-content {
          height: ($jumbotron-height-header - ($jumbotron-padding * 2));
          overflow: visible;
        }
        /*
        .jumbotron-description {
          height: ($jumbotron-description-height - ($jumbotron-padding * 2));
          padding-right: (($jumbotron-padding / 3) * 2);
          &, & p { color: $jumbotron-description-color; }
          &-wrapper {
            height: $jumbotron-description-height;
            overflow: hidden;
            position: absolute;
            top: ($jumbotron-height - $jumbotron-padding);
            left: -($grid-gutter-width / 2);
            right: -($grid-gutter-width / 2);
            padding: $jumbotron-padding;
            padding-right: ($jumbotron-padding / 3);
            background-color: $jumbotron-description-bg;
          }
        }
        */
      }
      &-product {
        min-height: $jumbotron-height-header;
      }
    }

    &-funds {
      margin-bottom: $size-md-y;
      .jumbotron-content {
        h2 { font-size: $font-size-h3; }
      }
    }

  }
}

//== tablet
@media screen and (min-width: $screen-tablet) and (max-width: ($screen-desktop - 1)) {

  .jumbotron {

    &-header {
      &-fund,
      &-product {
        h1, .h1 {
          margin-bottom: $size-xs-y;
          margin-top: $size-xs-y !important;
        }
        p {
          &.fund-name-oficial,
          &.fund-name-previous {
            margin-bottom: $size-sm-y;
            line-height: $line-height-small;
            text-align: left;
          }
          &.fund-name-oficial { font-size: 22px; }
          &.fund-name-previous { font-size: $font-size-middle; }
        }
        .fund-information { position: inherit; }
      }
      .stock-info {
        margin-top: $size-lg-y;
        .fund-icon > .genicon {
          margin-top: $size-xs-y;
        }
      }
      &-fund {
        h1, .h1 { font-size: 22px !important; }
      }
      &-product {
        h1, .h1 { font-size: 32px !important; }
      }
    }

    &-main {
      padding-left: $jumbotron-padding-sm !important;
      padding-right: $jumbotron-padding-sm !important;
      h1.jumbotron-claim { // just for GEN
        font-size: $font-size-h2 !important;
        strong { padding: $size-sm-y $size-sm-x; }
      }
    }

  }

}

//== <= phone
@media screen and (max-width: $screen-xs-max) {

  .jumbotron {

    &-main,
    &-header {
      h1, .h1 { font-size: 22px !important; }
      p.fund-name-oficial { font-size: 22px; }
    }

    &-main {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &-main,
    &-main .banner-main {
      height: $jumbotron-height;
    }

    &-header {
      //$jumbotron-description-height: 300px;
      $jumbotron-value-height: 150px;
      padding: $jumbotron-padding 0 !important;
      &-fund,
      &-product {
        p {
          font-size: $font-size-middle;
          &.fund-name-oficial,
          &.fund-name-previous {
            margin-bottom: $size-xs-y;
            line-height: $line-height-tiny;
            text-align: left;
          }
        }
      }
      &-fund {
        margin-bottom: (/*$jumbotron-description-height + */$jumbotron-value-height + $size-sm-y) !important;
        h1, .h1 { font-size: 22px !important; }
        .fund-value {
          box-sizing: border-box;
          height: $jumbotron-value-height;
          overflow: hidden;
          position: absolute;
          top: ($jumbotron-height-header - $jumbotron-padding);
          left: 0;
          right: 0;
          padding: $jumbotron-padding-sm $jumbotron-padding;
          background-color: $jumbotron-bg;
          .fund-icon .genicon {
            top: $size-sm-y;
          }
        }
        /*
        .jumbotron-description-wrapper {
          top: ($jumbotron-height + $jumbotron-value-height - $jumbotron-padding);
          left: 0;
          right: 0;
        }
        */
      }
      &-product {
        h1, .h1 {
          margin-bottom: $size-sm-y;
          font-size: 26px !important;
        }
      }
      .jumbotron-motive img {
        width: auto;
        height: 50%;
        left: -25%;
      }
      p.product-icon {
        .genicon { @include icon-size(84); }
      }
    }

    &-main {
      padding-left: $jumbotron-padding-xs !important;
      padding-right: $jumbotron-padding-xs !important;
      .jumbotron-content {
        padding-left: 0;
        padding-right: 0;
      }
      h1.jumbotron-claim { // just for GEN
        font-size: $font-size-h3 !important;
        strong { padding: $size-tn-y $size-sm-x; }
      }
    }

    &-funds {
      height: 400px;
      padding: $jumbotron-padding;
      .jumbotron-content {
        left: $jumbotron-padding;
        right: $jumbotron-padding;
        h2 { margin: 0 0 $size-sm-y 0; }
      }
      .fund-link {
        position: relative;
        float: left;
        padding-left: 55px;
        margin-top: $size-nm-y;
        margin-bottom: 0;
        &:first-child { width: 60%; }
        &:last-child { width: 40%; }
      }
    }

    &-banner,
    &-signpost {
      margin: 0 auto;
    }

    &-content {
      box-sizing: border-box;
      width: 100%;
      left: 0 !important;
      right: 0 !important;
      padding: $size-nm-x $grid-gutter-width;
      @include box-sizing(border-box);
    }

  }

  .content-header-fund {
    h1, .h1 { margin: 0 0 $size-nm-y !important; }
    p {
      &.fund-name-previous { font-size: $font-size-middle; }
    }
  }

}


// ========== Media Queries ==========

//== print
@media print {

  .jumbotron {

    &-main,
    &-header,
    &-funds,
    &-signpost,
    &-banner {
      border: 1px solid $print-border-color !important;
      background: $print-highlight-color !important;
      img { display: none; }
    }

    &-funds {
      margin-bottom: $size-nm-y;
    }

    &-header {
      @include stock-info-set(120px, 96, 48px, 36, true);
      h1, .h1 { font-size: ($font-size-h1 - 8px) !important; }
      .stock-info p.stock-movement { font-size: 42px !important; }
    }

  }

}


/* ========== Jumbotron polygon ========== */

/* main banner - zlatakoruna */
.jumbotron-polygon {
  height: 100%;
  position: absolute;
  background-position: right top !important;
  background-size: 6720px 550px !important;
  background-repeat: no-repeat !important;
  &.gen-polygon {
    background: url('../img/gen_bg_shape.png');
    background-image: url('../img/gen_bg_shape.svg'), none;
  }
  &.cpi-polygon {
    background: url('../img/cpi_bg_shape.png');
    background-image: url('../img/cpi_bg_shape.svg'), none;
  }
}

/*== desktop WQHD+ */
@media screen and (min-width: 2560px) {
  .jumbotron-polygon {
    &.gen-polygon { width: 55% !important; }
    &.cpi-polygon { width: 65% !important; }
  }
}

/*== desktop HD */
@media screen and (min-width: 1920px) and (max-width: 2559px) {
  .jumbotron-polygon {
    &.gen-polygon { width: 60% !important; }
    &.cpi-polygon { width: 70% !important; }
  }
}

/*== desktop + */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .jumbotron-polygon {
    &.gen-polygon { width: 65% !important; }
    &.cpi-polygon { width: 75% !important; }
  }
}

/*== desktop */
@media screen and (min-width: 1024px) and (max-width: 1279px) /*(min-width: @screen-desktop) and (max-width: (@screen-lg-desktop - 1))*/ {
  .jumbotron-polygon {
    &.gen-polygon { width: 75% !important; }
    &.cpi-polygon { width: 85% !important; }
  }
}

/*== phone & tablet */
@media screen and (max-width: 1023px) /*(max-width: (@screen-desktop - 1))*/ {
  .jumbotron-polygon {
    &.gen-polygon { width: 90% !important; }
    &.cpi-polygon { width: 90% !important; }
  }
}

/*== tablet */
@media screen and (min-width: 768px) and (max-width: 1023px) /*(min-width: @screen-tablet) and (max-width: (@screen-desktop - 1))*/ {

}

.jumbotron-motive img {
  /*== phone */
  @media screen and (max-width: 540px) {
      &.hidden-xs { display: none !important; }
      &.visible-xs { display: block !important; }
  }
  /*== tablet */
  @media screen and (min-width: 541px) and (max-width: 1023px) /*(min-width: @screen-tablet) and (max-width: (@screen-desktop - 1))*/ {
    &.visible-xs {  display: none !important; }
    &.hidden-xs {  display: inherit !important; }
    &.hidden-sm { display: none !important; }
    &.visible-sm { display: block !important; }
  }
}


/*== phone */
@media screen and (max-width: 767px) /*(@screen-tablet - 1))*/ {
  .jumbotron-polygon {
    &.gen-polygon { width: 100% !important; background-image: none !important; background-color: $black !important; }
    &.cpi-polygon { width: 100% !important; background-image: none !important; background-color: $black !important; }
  }
}

/*== print */
@media print {

}


