// ANCHOR

@mixin underline {
  text-decoration: underline !important;
  &:hover { text-decoration: none !important; }
}
.underline { @include underline; }
@mixin underline-reverse {
  text-decoration: none !important;
  &:hover { text-decoration: underline !important; }
}
.underline-reverse {
  @include underline-reverse;
}
@mixin underline-not {
  text-decoration: none !important;
  &:hover { text-decoration: none !important; }
}
.underline-not { @include underline-not; }

// CLEAR

@mixin clear-pseudo-content($place: before){
  $selector-name: unquote(":#{$place}");
  &#{$selector-name} {
    display: inline;
    width: 0;
    height: 0;
    content: '';
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    font-size: 0px;
  }
}

// SIZING

@mixin size($width, $height){
  width: $width !important;
  height: $height !important;
}

@mixin square($size){
  @include size($size, $size);
}

@mixin min-height($size){
  min-height: $size;
  height: auto !important;
  height: $size;
}

@mixin square-center($size){
  display: block;
  padding: 0;
  @include square($size);
  line-height: $size - ceil($size / 10);
  text-align: center;
  vertical-align: middle;
  @include size($size, $size);
}

// BORDER

@mixin border-radius($radius: 0, $radius-top-left: inherit, $radius-top-right: inherit, $radius-bottom-left: inherit, $radius-bottom-right: inherit) {
  @if $radius != inherit {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
  }
  @if $radius-top-left != inherit {
    -webkit-border-top-left-radius: $radius-top-left;
        -moz-border-radius-topleft: $radius-top-left;
            border-top-left-radius: $radius-top-left;
  }
  @if $radius-top-right != inherit {
    -webkit-border-top-right-radius: $radius-top-right;
        -moz-border-radius-topright: $radius-top-right;
            border-top-right-radius: $radius-top-right;
  }
  @if $radius-bottom-left != inherit {
    -webkit-border-bottom-left-radius: $radius-bottom-left;
        -moz-border-radius-bottomleft: $radius-bottom-left;
            border-bottom-left-radius: $radius-bottom-left;
  }
  @if $radius-bottom-right != inherit {
    -webkit-border-bottom-right-radius: $radius-bottom-right;
        -moz-border-radius-bottomright: $radius-bottom-right;
            border-bottom-right-radius: $radius-bottom-right;
  }
}

// GENERATE SELECTORS

@mixin generate-selector($property, $shortcut, $step, $count){
  $counter: $count;
  @while $counter > 0 {
    $counter: $counter - 1;
    $property-value: $counter * $step;
    $selector-name: unquote(".#{$shortcut}#{$property-value}");
    #{$selector-name} { #{$property}: unquote("#{$property-value}px") !important; }
  }
}

@mixin generate-selector-array($property, $shortcut, $step, $count){
  $counter: $count;
  @while $counter > 0 {
    $counter: $counter - 1;
    $property-value: $counter * $step;
    $selector-name: unquote(".#{$shortcut}#{$property-value}");
    #{$selector-name} { #{$property}: unquote("#{$property-value}px") !important; }
  }
}

// BUTTONS

@mixin button-variant($filled, $color, $background, $active-color, $active-bg, $disabled-color, $disabled-bg){
  @if $filled {
    color: $color !important;
    background-color: $background;
    border-color: $background;
  } @else {
    color: $color !important;
    background-color: transparent;
    border-color: $color;
  }
  &:focus,
  &.focus,
  &:hover,
  &:active,
  &.active {
    @if $filled {
      color: $active-color !important;
      background-color: $active-bg;
      border-color: $active-bg;
    } @else {
      color: $active-color !important;
      background-color: transparent;
      border-color: $active-color;
    }
  }
  &.has-collapse:not(.collapsed) {
    color: $white !important;
    background-color: $background;
    border-color: $background;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    @include opacity(1);
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &.collapsed {
      @if $filled {
        color: $disabled-color !important;
        background-color: $disabled-bg;
        border-color: $disabled-bg;
      } @else {
        color: $disabled-color !important;
        background-color: transparent;
        border-color: $disabled-bg;
      }
    }
  }
  &:after,
  &.btn-lg:after {
    @include genicon-styles;
    content: genicon-char(forward);
    vertical-align: top;
    margin-left: floor(($grid-gutter-width / 3));
  }
  &.btn-sm:after,
  &.btn-noarrow:after {
    margin-left: 0;
    content: $genicon-empty-content;
  }
}

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius, $border-width, $genicon-size, $input-height){
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-width: $border-width;
  border-radius: $border-radius;
  .genicon {
    &:before { font-size: $genicon-size; }
  }
  &.btn-lines {
    padding-right: $padding-horizontal + floor($font-size-large / 2) + floor(($grid-gutter-width / 3));
    &.btn-sm { padding-right: $padding-horizontal; }
    &:after { top: floor(($input-height - $padding-vertical) / 2); }
  }
  &.btn-noarrow { padding-right: $padding-horizontal; }
  small { font-size: (($font-size / 3) * 2) !important; }
}

// PANELS

@mixin panel-variant($panel-bg, $panel-border, $panel-link, $panel-text, $panel-heading){
  $border: $panel-border;
  $heading-text: $panel-heading;
  $heading-bg-color: $panel-bg;
  $heading-border: $panel-border;

  &,
  .panel-heading,
  .panel-body,
  .panel-footer {
    background-color: $panel-bg;
    color: $panel-text;
    a, a:hover {
      color: $panel-link;
    }
  }
  p, label { color: $panel-text; }
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    color: $panel-heading;
  }
}

// JUMBOTRONS

@mixin stock-info-set($main-margin-width: 150px, $main-icon-size: 120, $minor-margin-width: 56px, $minor-icon-size: 48, $line-break: false){
  .stock-info {
    position: relative;
    padding-left: $main-margin-width;
    .fund-icon > .genicon {
      @include icon-size($main-icon-size);
      position: absolute;
      top: $size-xs-y;
      left: 0;
    }
    p {
      margin-bottom: $size-xs-y;
      line-height: $line-height-equal;
      &.fund-icon {
        margin-top: 0;
        margin-bottom: 0;
      }
      &.stock-movement {
        font-weight: bold;
        font-size: $minor-margin-width;
        > .genicon { @include icon-size($minor-icon-size); }
      }
      &.stock-value {
        font-size: $font-size-h2;
        font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */
      }
      &.stock-force {
        line-height: $line-height-base;
        font-size: $font-size-base;
        text-align: left;
        br { &, &:after { content: ' '; } }
      }
    }
  }
}


// LABELS

@mixin label-variant($color){
  border: 1px solid $color;
  &[href] {
    &:hover,
    &:focus {
      border: 1px solid darken($color, 10%);
    }
  }
}


// ICONS

@mixin icon-size($size){
  display: inline-block;
  width: unquote("#{$size}px !important");
  height: unquote("#{$size}px !important");
  line-height: unquote("#{$size}px !important");
  font-size: unquote("#{$size}px !important");
}

@mixin icon-text-size($icon-size, $text-size){
  .genicon {
    @include icon-size($icon-size);
  }
  font-size: $text-size;
}

@mixin generate-icons($list){
  @each $size in $list {
    .icon-size-#{$size},
    .icon-size-#{$size}:before {
      @include icon-size($size);
    }
  }
}

// FORMS

@mixin form-control-focus($color: $input-focus-border){
  &:focus {
    border-color: $color;
    outline: 0;
    @include box-shadow(none);
  }
}

// COLORS

@mixin generate-color-swatch($swatch, $colors) {
  $counter: $colors;
  @while $counter > 0 {
    $counter: $counter - 1;
    $swatch-value: "sw#{$swatch}-color#{$counter}";
    $swatch-name: unquote(".#{$swatch-value}");
    .bg-color { &#{$swatch-name} { @include bg-variant($swatch-value); } }
    .text-color { &#{$swatch-name} { @include text-emphasis-variant($swatch-value); } }
  }
}

// OPACITY

@mixin opacity-important($opacity){
  opacity: $opacity !important;
  // IE8 filter
  $opacity-ie: ($opacity * 100) !important;
  filter: unquote("alpha(opacity=#{$opacity-ie})") !important;
}

// TOOLTIPS & POPOVERS

@mixin set-tooltip-color($type, $selector, $bg-color, $color: $white){
  $type-name: unquote(".#{$type}");
  $selector-name: unquote(".#{$type}-#{$selector}");
  @if not($selector == '') {
    @if $type == tooltip {
      #{$selector-name}#{$type-name} #{$type-name}-inner, #{$selector-name} + #{$type-name} #{$type-name}-inner { background-color: $bg-color; color: $color; }
    }
    @else if $type == popover {
      #{$selector-name}#{$type-name} #{$type-name}-title, #{$selector-name} + #{$type-name} #{$type-name}-title { background-color: $bg-color; color: $color; }
    }
    #{$selector-name}#{$type-name}.top #{$type-name}-arrow, #{$selector-name} + #{$type-name}.top #{$type-name}-arrow { border-top-color: $bg-color; }
    #{$selector-name}#{$type-name}.top-left #{$type-name}-arrow, #{$selector-name} + #{$type-name}.top-left #{$type-name}-arrow { border-top-color: $bg-color; }
    #{$selector-name}#{$type-name}.top-right #{$type-name}-arrow, #{$selector-name} + #{$type-name}.top-right #{$type-name}-arrow { border-top-color: $bg-color; }
    #{$selector-name}#{$type-name}.right #{$type-name}-arrow, #{$selector-name} + #{$type-name}.right #{$type-name}-arrow { border-right-color: $bg-color; }
    #{$selector-name}#{$type-name}.left #{$type-name}-arrow, #{$selector-name} + #{$type-name}.left #{$type-name}-arrow { border-left-color: $bg-color; }
    #{$selector-name}#{$type-name}.bottom #{$type-name}-arrow, #{$selector-name} + #{$type-name}.bottom #{$type-name}-arrow { border-bottom-color: $bg-color; }
    #{$selector-name}#{$type-name}.bottom-left #{$type-name}-arrow, #{$selector-name} + #{$type-name}.bottom-left #{$type-name}-arrow { border-bottom-color: $bg-color; }
    #{$selector-name}#{$type-name}.bottom-right #{$type-name}-arrow, #{$selector-name} + #{$type-name}.bottom-right #{$type-name}-arrow { border-bottom-color: $bg-color; }
  } @else if $selector == '' {
    @if $type == tooltip { #{$type-name} #{$type-name}-inner { background-color: $bg-color; color: $color; } }
    @if $type = popover { #{$type-name} #{$type-name}-title { background-color: $bg-color;  color: $color; } }
    #{$type-name}.top #{$type-name}-arrow { border-top-color: $bg-color; }
    #{$type-name}.top-left #{$type-name}-arrow { border-top-color: $bg-color; }
    #{$type-name}.top-right #{$type-name}-arrow { border-top-color: $bg-color; }
    #{$type-name}.right #{$type-name}-arrow { border-right-color: $bg-color; }
    #{$type-name}.left #{$type-name}-arrow { border-left-color: $bg-color; }
    #{$type-name}.bottom #{$type-name}-arrow { border-bottom-color: $bg-color; }
    #{$type-name}.bottom-left #{$type-name}-arrow { border-bottom-color: $bg-color; }
    #{$type-name}.bottom-right #{$type-name}-arrow { border-bottom-color: $bg-color; }
  }
}

// LIST

@mixin reset-list($float: none){
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    float: $float;
    @include clear-pseudo-content(before);
  }
}

// PAGINATION

@mixin pagination-set-colors($color, $background-color, $border-color){
  color: $color !important;
  background-color: $background-color !important;
  border-color: $border-color !important;
}

@mixin pagination-colors($variation, $version) {
  @if $version == default {
    $color: "pagination-#{$variation}-color";
    $background-color: "pagination-#{$variation}-bg";
    $border-color: "pagination-#{$variation}-border";
    @include pagination-set-colors($color, $background-color, $border-color);
  } @else {
    $color: "pagination-#{$variation}-#{$version}-color";
    $background-color: "pagination-#{$variation}-#{$version}-bg";
    $border-color: "pagination-#{$variation}-#{$version}-border";
    @include pagination-set-colors($color, $background-color, $border-color);
  }
}

@mixin pagination-variation($variation){

  &.pagination-#{$variation} {
    > li {
      > a,
      > span {
        @include pagination-colors($variation, default);
      }
    }
    > li > a,
    > li > span {
      &:hover,
      &:focus {
        @include pagination-colors($variation, hover);
      }
    }
    > .active > a,
    > .active > span,
    > .current > a,
    > .current > span {
      &,
      &:hover,
      &:focus {
        @include pagination-colors($variation, active);
      }
    }
    > .disabled {
      > span,
      > span:hover,
      > span:focus,
      > a,
      > a:hover,
      > a:focus {
        @include pagination-colors($variation, disabled);
      }
    }
  }
}

@mixin pagination-size($s-input-height, $s-font-size, $s-icon-size){
  @include square-center($s-input-height);
  font-size: $s-font-size;
  .genicon {
    @include icon-size($s-icon-size);
    $margin-top-value: $s-icon-size - $pagination-icon-size-sm;
    margin-top: unquote("#{$margin-top-value}px");
  }
}

// IMAGE

@mixin img-center {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  z-index: 100;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
  }
}
.img-center { @include img-center; }
@mixin img-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
}
.img-wrapper { @include img-wrapper; }
.img-tocenter {
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

// FILTER

@mixin filter {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  @include opacity(.5);
  z-index: 110;
}
.filter { @include filter; }