//
// Scaffolding
// --------------------------------------------------


// Links

a {
  &, &.link-type1 {
    text-decoration: $link-decoration;
    &:hover,&.hover {
      text-decoration: $link-hover-decoration;
    }
    &:active,&.active {
      text-decoration: $link-hover-decoration;
      color: $link-active-color;
      &:hover,&.hover {
        color: inherit;
      }
    }
  }
  &.link-type2 {
    color: $link-type2-color;
    &:hover,&.hover {
      color: $link-type2-hover-color;
    }
  }
  &.link-icon {
    .genicon {
      vertical-align: middle;
      margin-right: $size-tn-x;
    }
    &, &:hover {
      text-decoration: none;
    }
    .link-content {
      text-decoration: underline;
      &:hover { text-decoration: none; }
    }
  }
  &.link-not {
    @include underline-not;
    color: inherit;
  }
  /* in mixins
  .underline
  .underline-reverse
  .underline-not
  */
}

.close {
  opacity: .4;
  .genicon:before {
    @include icon-size(16);
  }
  &:hover { opacity: .8; }
}

// Dividers

hr {
  display: block;
  height: 2px;
  margin: $size-lg-y 0;
  border: 0 none;
  @include border-bottom-dotted;
  &.simple {
    height: 1px;
    margin: $size-md-y 0;
    border: 0 none;
    border-bottom: 1px solid $color-white;
  }
}


// ========== Media Queries ==========

//== print
@media print {

  a {
    &:hover,&.hover,
    &:active,&.active {
      color: $print-text-color !important;
    }
  }

  hr { border-color: $print-highlight-color; }

}
