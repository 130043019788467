//
// Alerts
// --------------------------------------------------

.alert {
  padding: $alert-padding;
  margin-bottom: $line-height-computed;
  border: 1px solid transparent;
  border-radius: $alert-border-radius;
  > p,
  > ul {
    color: inherit;
  }
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissable, // The misspelled .alert-dismissable was deprecated in 3.2.0.
.alert-dismissible {
  padding-right: ($alert-padding + 20);

  // Adjust close link position
  .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
  @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}

.alert-info {
  @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}

.alert-warning {
  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

.alert-danger {
  @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}


@if $alert-success-btn-white {
  .alert-success .btn { @extend .btn-white; }
}
@if $alert-warning-btn-white {
  .alert-warning .btn { @extend .btn-white; }
}
@if $alert-danger-btn-white {
  .alert-danger .btn { @extend .btn-white; }
}


// ========== Media Queries ==========

//== print
@media print {

}