//
// Pages
// --------------------------------------------------

//== Directory

.nav-page {
  margin: $size-md-y 0 $size-lg-y 0;
  .dir-name {
    margin: 0;
    line-height: $line-height-large;
  }
  .clearfix { margin-top: $size-md-y; }
  .nav-tabs {
    margin: 0;
    border: 0 none;
  }
  @media screen and (max-width: $screen-sm-max) {
  }
  @media screen and (max-width: $screen-xs-max) {
    margin: $size-nm-y 0;
    .pull-left { float: none !important; }
    .pull-right {
      display: inline-block;
      margin-top: $size-md-y;
      float: none !important;
    }
    .nav-tabs {
      display: block;
      li { width: 50%; }
    }
    &.m-y-md {
      margin-bottom: $size-nm-y !important;
    }
  }
  @media screen and (max-width: ($screen-tn - 1)) {
    .nav-tabs {
      li { width: 100%; }
    }
  }
}

.directory {
  margin-bottom: $size-lg-y;
  &-nav {
    border-bottom: 1px solid $gray-lighter;
    &.row {
      margin-left: 0;
      margin-right: 0;
      .col-xs-6 { padding: 0; }
    }
  }
  a {
    &.folder,
    &.file,
    &.back,
    &.download {
      display: block;
      box-sizing: border-box;
      width: 100%;
      min-height: 75px;
      position: relative;
      padding: $size-sm-x $size-hg-y $size-sm-x $size-nm-y;
      font-size: $font-size-middle;
      border-bottom: 1px solid $gray-lighter;
      @include underline-not;
      color: $brand-info;
      &:before {
        @include genicon-styles;
        content: genicon-char(list-bullet);
        @include icon-size(12);
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -8px;
      }
      &:after {
        @include genicon-styles;
        content: genicon-char(download);
        @include icon-size(40);
        position: absolute;
        right: $size-sm-x;
        top: 50%;
        margin-top: -20px;
        color: $brand-primary;
      }
      .folder,
      .file,
      .back,
      .download {
        &-name { color: $brand-danger; }
        &-icon {
          @include icon-size(36);
          float: left;
          margin-top: 3px;
          margin-right: $size-sm-y;
        }
        &-date {
          font-size: $font-size-base;
          color: $gray;
        }
      }
      &:hover {
        background-color: $gray-lighter;
        &:before { left: $size-tn-y; }
        .folder,
        .file,
        .back,
        .download {
          &-icon, &-name { color: $brand-primary; }
        }
      }
      /*
      &:nth-child(odd) { float: left; }
      &:nth-child(even) { float: right; }
      */
      &:last-child { border-bottom: 0 none; }
      @media screen and (min-width: $screen-md-min) {
        &:nth-last-child(2) { border-bottom: 0 none; }
      }
      @media screen and (max-width: $screen-sm-max) {
        width: 100%;
        float: none;
      }
    }
    &.file {
      &:after {
        @include icon-size(40);
        @include genicon-styles;
        content: genicon-char(download);
      }
    }
    &.folder {
      &:after {
        @include icon-size(32);
        @include genicon-styles;
        content: genicon-char(forward);
      }
    }
    &.back,
    &.download {
      min-height: 75px;
      padding-right: $size-nm-y;
      line-height: 40px;
      @include clear-pseudo-content(before);
      @include clear-pseudo-content(after);
      .back {
        &-name { color: $gray-dark; }
        &-icon {
          @include icon-size(30);
          color: $gray-dark;
        }
      }
    }
    &.download {
      text-align: right;
      .download {
        &-icon {
          float: right;
          margin-left: $size-sm-y;
          margin-right: 0;
        }
      }
    }
  }
  span.empty {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 75px;
    position: relative;
    padding: $size-sm-x $size-hg-y $size-sm-x $size-nm-y;
    &:nth-child(odd) { float: left; }
    &:nth-child(even) { float: right; }
    @media screen and (max-width: ($screen-tablet - 1)) {
      display: none;
    }
  }
}