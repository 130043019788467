//
// Typography
// --------------------------------------------------

body {
  font-family: $font-family-base;
}
strong {
  font-family: $font-family-bold; font-weight: normal; font-weight: 700;
}

// Headings
// -------------------------

h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; }
h4, .h4 { font-size: $font-size-h4; }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }

h1, .h1,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */
}
h2, .h2,
h3, .h3 {
  font-weight: normal;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  color: $h1-color;
}
h4, .h4,
h6, .h6 {
  color: $h4-color;
}
h5, .h5 {
  color: $h5-color;
}

h5, .h5, h6, .h6 {margin-top: $size-sm-y; margin-bottom: $size-sm-y; }
h4, .h4 {margin-top: $size-nm-y; margin-bottom: $size-nm-y; }
h3, .h3 {margin-top: $size-md-y; margin-bottom: $size-md-y; }
h2, .h2 {margin-top: $size-lg-y; margin-bottom: $size-lg-y; }
h1, .h1 {margin-top: $size-hg-y; margin-bottom: $size-hg-y; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  strong { font-weight: bold !important; }
}

.central-heading,
.left-heading {
  position: relative;
  margin: 80px 0 $size-hg-y 0;
  small {
    display: block;
    margin: $size-sm-y 0;
    line-height: 1.4em;
    font-size: 18px;
    color: $sw1-color5;
  }
  &:after {
    display: block;
    content: ' ';
    width: 200px;
    height: 2px;
    position: absolute;
    bottom: -$size-nm-y;
    background-color: $brand-primary;
  }
  &.noborder {
    &:after { display: none; }
  }
}
h1, h2, h3, h4, h5, h6 {
  &.central-heading {
    text-align: center;
    &:after {
      left: 50%;
      margin-left: -100px;
    }
    @media screen and (max-width: ($screen-tablet - 1)) {
      margin-left: $size-nm-x;
      margin-right: $size-nm-x;
      margin-bottom: $size-lg-x;
    }
  }
  &.left-heading {
    text-align: left;
    &:after { left: 0; }
  }
}

// Body text
// -------------------------

a:link,
a:visited,
a:hover,
a:active {
  color: $brand-primary;
}

p {
  line-height: $line-height-computed;
  font-size: $font-size-base;
  color: $text-color;
  text-align: $paragraph-align;
  &.p-lg,
  &.lead {
    font-family: $font-family-base;
    font-weight: bold;
    margin-bottom: $size-lg-y;
    line-height: $line-height-common !important;
    font-size: $font-size-large !important;
  }
  &.note {
    color: $note-color;
    font-size: $font-size-small;
    font-style: italic;
  }
  @media screen and (max-width: $screen-tn-max) {
    &.p-lg { text-align: left; }
  }
}

// Blocquote
// -------------------------

blockquote {
  font-style: italic;
  &.bq-main {
    margin-left: $size-hg-x;
    margin-right: $size-hg-x;
    font-weight: bold;
    color: $brand-primary;
  }
  &.quotes {
    position: relative;
    padding-left: 60px;
    padding-right: 55px;
    &:before,
    &:after {
      position: absolute;
      font-size: 64px;
    }
    &:before {
      content: "\201E";
      bottom: -10px;
      left: 15px;
    }
    &:after {
      content: "\201D";
      top: -10px;
      right: 15px;
    }
  }
  @media screen and (max-width: $screen-sm-max) {
    &.bq-main {
      margin-left: 0;
      margin-right: 0;
    }
    &.quotes {
      padding-left: 50px;
      padding-right: 45px;
      &:before, &:after {
        font-size: 48px;
      }
    }
  }
}

// Page header
// -------------------------

.page-header {
  padding-bottom: (($line-height-computed / 2) - 1);
  margin: ($line-height-computed * 2) 0 $line-height-computed;
  border-bottom: 1px solid $page-header-border-color;
}


// Lists
// -------------------------

ul, ol {
  &:not(.kraje) {
    //margin-top: $size-sm-y;
    //margin-bottom: $size-sm-y;
    color: $text-color;
    li {
      position: relative;
      margin-bottom: $size-sm-y;
      line-height: $line-height-common;
      &:before {
        position: absolute;
        top: 2px;
        left: -24px;
      }
      &.active > a { @include underline-not; }
    }
    &.line-spacing-sm li { margin-bottom: $size-sm-y; }
    &.line-spacing-md li { margin-bottom: $size-nm-y; }
    &.line-spacing-lg li { margin-bottom: $size-md-y; }
  }
}
ul ul,
ul ol,
ol ol,
ol ul {
  &:not(.kraje) {
    margin-top: $size-sm-y;
    margin-bottom: $size-sm-y;
    padding-left: $size-nm-x;
  }
}
ul {
  &:not(.kraje) {
    li {
      list-style-type: none;
      &:before {
        @include genicon-styles;
        @include icon-size(16);
        color: $list-icon-bold-color;
        left: -28px;
      };
    }
    &.list-header li:before {
      top: -2px;
      left: -36px;
      @include icon-size(24);
    }
  }
}
ol {
  > li {
    list-style-type: none;
    counter-increment: item;
    &:before {
      top: 0px;
      content: counter(item) ". ";
      color: $list-icon-bold-color;
    }
  }
  &.list-header li:before {
    top: -4px;
    left: -32px;
    font-size: $font-size-large;
    font-family: $font-family-bold; font-weight: normal;
  }
}
ul, ol {
  &.list-header h4 {
    margin: $size-sm-x 0;
    font-size: $font-size-large;
    font-weight: normal;
    font-family: $font-family-bold;
    line-height: $line-height-base;
    color: $list-heading-color;
  }
}

.categories {
  ul, ol {
    &.list-header {
      > li {
        /*
        height: 120px;
        overflow: hidden;
        */
        height: auto;
        overflow: visible;
        margin-bottom: $size-md-y;
      }
    }
  }
  &-minimal {
    ul, ol {
      &.list-header { height: 60px; }
    }
  }
}

// List links

ul, ol {
  &.list-links {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: ($size-sm-x + 5);
    li {
      margin-bottom: $size-sm-y;
      &:last-child { margin-bottom: 0; }
    }
  }
}

ul.list-links li:before {
  top: 6px;
  left: -($size-sm-x + 5);
  @include genicon-styles;
  @include icon-size(12);
  color: $list-icon-gray-color;
}
ol.list-links li:before {
  left: -($size-sm-x + 5);
  color: $list-icon-gray-color;
}

ul li:before,
ul.bullet-check li:before {
  content: genicon-char(check);
}
ul.list-links li:before,
ul.bullet-bracket li:before {
  content: genicon-char(angle-bracket-right);
}

// List map

ul.list-map {
  padding-left: $size-sm-x + 4;
  li {
    margin-bottom: $size-lg-y;
    &:before {
      @include genicon-styles;
      @include icon-size(16);
      top: $size-xs-y;
      left: -($size-sm-x + 4);
      color: $list-icon-bold-color;
    }
    a { font-size: $font-size-huge - 2; }
    ul {
      padding-left: 0;
      li {
        margin-left: 0;
        margin-bottom: $size-tn-y;
        &:before {
          @include genicon-styles;
          @include icon-size(12);
          top: ($size-tn-y - 1);
          left: 0;
        }
        a {
          margin-left: $size-sm-x;
          font-size: $font-size-base;
        }
        ul {
          background-image: linear-gradient(to bottom, $gray-light 33%, rgba(255,255,255,0) 0%);
          background-position: 5px;
          background-size: 2px 6px;
          background-repeat: repeat-y;
          padding-left: $size-nm-x;
          li li {
            &:before {
              @include genicon-styles;
              content: genicon-char(list-bullet);
              @include icon-size(12);
              color: $list-icon-gray-color;
            }
          }
        }
      }
    }
  }
}

// List simple

ul, ol {
  &.list-simple {
    padding-left: 0;
    li { @include clear-pseudo-content; }
  }
}

// List small

ul, ol {
  &.list-small {
    padding-left: 20px;
    li {
      margin-bottom: 3px;
      &:before {
        left: -20px;
        color: $list-icon-gray-color;
      }
    }
  }
}

ul.list-small li:before {
  top: 6px;
  @include genicon-styles;
  content: genicon-char(list-bullet);
  @include icon-size(10);
}

// List inline

ul, ol {
  &.list-inline {
    @include reset-list;
    li {
      display: inline-block;
      padding: 0 0 0 $size-xs-x;
      &.active > a {
        cursor: default;
      }
    }
    &.list-dividers {
      li:nth-child(n+2) {
        &:before {
          content: '|' !important;
          width: auto !important;
          height: $input-height-base !important;
          margin: 0 $size-xs-x 0 0 !important;
          font-family: $font-family-base !important;
          font-size: $font-size-base;
        }
        &.divider {
          &-empty:before {
            content: ' ' !important;
          }
          &-no:before {
            content: '' !important;
            margin: 0 !important;
          }
        }
      }
    }
  }
}

// Row list

ul, ol {
  &.row {
    padding-left: 0;
    li {
      padding-left: $size-nm-x + 4;
      margin-bottom: $size-lg-y;
      &:before {
        @include genicon-styles;
        @include icon-size(16);
        top: $size-tn-y + 1;
        left: $size-sm-x;
      }
      h4 {
        margin-top: 0;
        font-weight: normal;
        font-size: $font-size-large + 2;
      }
    }
  }
}


// List blocks

ul, ol {
  &.list-panels {
    @include reset-list();
    margin-bottom: $size-xs-y;
    li {
      @include border-radius($border-radius-base);
      display: block;
      position: relative;
      padding: $size-sm-y $size-sm-x;
      line-height: 32px;
      text-decoration: none;
      font-size: $font-size-large;
      cursor: pointer;
      background-color: $accordion-heading-bg;
      color: $accordion-heading-color;
      &:hover,
      &:focus,
      &.active {
        color: $accordion-heading-active-color;
        background-color: $accordion-heading-active-bg;
      }
    }
  }
}

// Define lists

dl, dt, dd {
  color: $text-color;
}

dl.list-oneline {
  dt {
    display: inline-block;
    min-height: $line-height-base;
    float: left;
    clear: left;
  }
  dd {
    min-height: $line-height-base;
    clear: right;
  }
}
dl.list-simple {
  dt { font-weight: normal; }
}
dl.list-highlight {
  dt { font-family: $font-family-bold; font-weight: normal; /* font-weight: bold; */ }
}

// Lists in panel

.panel-list {
  ul.list-links {
    margin: 0;
    li {
      font-size: $font-size-base + 1;
      &:before { top: $size-tn-y; }
    }
  }
  &.panel-primary {
    ul, ol {
      margin: 0;
      &.list-links {
        li {
          &:before { color: $list-primary-bold-color; }
          a { color: $panel-primary-link; }
        }
      }
      &.list-header li {
        &, h4, a { color: $panel-primary-text; }
        &:first-child h4 { margin-top: 0; }
        &:last-child h4 { margin-bottom: 0; }
        &:before { color: $list-primary-bold-color; }
      }
    }
    dl.list-highlight dt { color: $list-primary-bold-color; }
  }
  &.panel-info {
    ul.list-links {
      li {
        &:before { color: $list-icon-gray-color; }
        a { color: $link-color; }
      }
    }
  }
}

// list group

ul, ol {
  &.list-group {
    margin: 0;
  }
}


// ========== Media Queries ==========

//== phone
@media screen and (max-width: ($screen-tablet - 1)) {

  h1, .h1 {
    font-size: $font-size-h2 !important;
    margin-top: $size-lg-y;
    margin-bottom: $size-lg-y;
  }
  h2, .h2 {
    font-size: $font-size-h3 !important;
    margin-top: $size-md-y;
    margin-bottom: $size-md-y;
  }
  h3, .h3 {
    font-size: ($font-size-h3 - 4) !important;
    margin-top: $size-nm-y;
    margin-bottom: $size-nm-y;
  }

}

//== print
@media print {

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    color: $print-text-color !important;
  }

  ul, ol {
    &.list-panels li {
      border: 1px solid $print-border-color;
      &.active { border: 1px solid $print-text-color; }
    }
  }

}
